import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup'
import { Formik, Form } from 'formik';
import MerchantOnboarding from '../../../../components/layouts/merchant/MerchantOnboarding'
import AnimateRoute from '../../../../shared/components/animated-route/AnimateRoute'
import { AlertBox } from '../../../../components/molecules/alertbox/AlertBox';
import FormControl from '../../../../components/forms/FormControl';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useMerchantAuthContext } from '../../../../hooks/useMerchantAuthContext';

function MerchantResetPassword() {

  const baseURL = process.env.REACT_APP_BASEURL;

  const { user_merchant } = useMerchantAuthContext();

  // Check if merchant 
  useEffect(() => {
    if (user_merchant) {
      navigate('/merchant/');
    }
  }, [user_merchant]);


  const navigate = useNavigate();

  const [error, setError] = useState(null);

  // Reset Password for merchant
  const { isSuccess, mutate: resetPassword, isLoading } = useMutation({
    mutationFn: (formDetails) => {
      return axios.post(`${baseURL}/merchant/auth/reset`, formDetails
        , {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((res) => res.data);
    },
    onSuccess: () => {
      navigate('/merchant');
    },
    onError: () => {
      setError("Something went wrong. Try again.");
    }
  });

  const initialValues = {
    resetCode: '',
    password: '',
    confirmPassword: ''
  }

  const validationSchema = Yup.object({
    resetCode: Yup.string().required("Required"),
    password: Yup.string().min(6, "Minimum of 6 charaters required").required('Required'),
    confirmPassword: Yup.string().min(6, "Minimum of 6 charaters required").required('Required')
  });

  const onSubmit = (values) => {

    const formData = new FormData();
    formData.append("resetCode", values.resetCode);
    formData.append("password", values.password);
    formData.append("password_confirmation", values.confirmPassword);

    resetPassword(formData);

    if (error) {
      setError("");
    }
  }


  return (
    <AnimateRoute>
      <Helmet>
        <title>Hostel.ng | Merchant Reset Password</title>
        <meta name="description" content="Reset your hostel.ng account password." />
        <link rel="canonical" href="/merchant/reset-password" />
      </Helmet>

      <MerchantOnboarding
        heading="Set a new password"
        image="authbg-4"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ dirty, isValid }) => (
            <Form>
              <FormControl
                control="input"
                name="resetCode"
                type="text"
                label="Password reset code"
              />

              <FormControl
                control="input"
                name="password"
                type="password"
                label="Enter new password"
              />

              <FormControl
                control="input"
                name="confirmPassword"
                type="password"
                label="Confirm new password"
              />

              <div className="action-container">

                <p className='text'></p>

                {!isLoading && <button className='zbtn zbtn-primary-def' disabled={!(dirty && isValid)} type='submit'>
                  Reset password
                </button>}

                {isLoading && <button className='zbtn zbtn-primary-def' disabled={isLoading} type='submit'>
                  Resetting password ...
                </button>}

              </div>

              {error && (<AlertBox type='danger' message="An error occured. Kindly check your reset code." />)}

            </Form>
          )}
        </Formik>
      </MerchantOnboarding>
    </AnimateRoute>

  )
}

export default MerchantResetPassword