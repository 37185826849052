import React, { useState } from 'react'
import MerchantLayout from '../../../components/layouts/merchant/MerchantLayout'
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute'
import { Helmet } from 'react-helmet-async'
import { useMerchantDetailsContext } from '../../../hooks/useMerchantDetailsContext';
import clsx from 'clsx';
import MerchantPersonalDetails from './merchant-personal-details/MerchantPersonalDetails';
import MerchantSecurity from './merchant-security/MerchantSecurity';
import MerchantEarnings from './merchant-earnings/MerchantEarnings';

function MerchantProfile() {

  const [index, setIndex] = useState(1);
  const [updateMe, setUpdateMe] = useState(1);
  const { merchant_details } = useMerchantDetailsContext();

  const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (

    <AnimateRoute>

      <Helmet>
        <title>Merchant Profile -  Hostel.ng.</title>
        <meta name="description" content="Find the best affordable rooms on Hostel.ng." />
        <link rel="canonical" href="/merchant/profile" />
      </Helmet>

      <div className="profile">
        <MerchantLayout>
          <div className="main__cover">
            <div className='tabs'>
              <ul className='tabs__nav'>
                <li className={clsx('tabs__nav-link', index === 1 && 'active')} onClick={() => setIndex(1)}>Personal Details</li>
                <li className={clsx('tabs__nav-link', index === 2 && 'active')} onClick={() => setIndex(2)}>Security</li>
                <li className={clsx('tabs__nav-link', index === 3 && 'active')} onClick={() => setIndex(3)}>Earnings</li>
              </ul>

              <div className="tabs__contentbox">
                <div className={clsx('tabs__content', index === 1 && 'show')} >
                  <MerchantPersonalDetails updateMe={updateMe} setUpdateMe={setUpdateMe}  />
                </div>

                <div className={clsx('tabs__content', index === 2 && 'show')} >
                  <MerchantSecurity />
                </div>

                <div className={clsx('tabs__content', index === 3 && 'show')} >
                  <MerchantEarnings />
                </div>
              </div>
            </div>
          </div>
        </MerchantLayout>
      </div>
    </AnimateRoute>


  )
}

export default MerchantProfile