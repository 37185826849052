import React from 'react'
import MainLayout from '../../components/layouts/users/MainLayout'
import AnimateRoute from '../../shared/components/animated-route/AnimateRoute'
import { FiArrowLeft } from 'react-icons/fi';
import Image404 from '../../assets/images/img/404image.png';
import { useNavigate } from 'react-router-dom';


function PageNotFound() {

  const navigate = useNavigate();
  return (
    <AnimateRoute>
      <MainLayout>
        <div className="container">
        <div className='notfound'>
          <div className="notfound__textbox">
            <p className='text404'>404 error</p>
            <h3 className='title'>Oops! Page not found. </h3>
            <p className='message'>Sorry the page you are looking for does not exist or has been moved. Here are some helpful links:</p>
            <div className='actionbox'>
              <button className='zbtn zbtn-primary-outline-md' onClick={() => navigate(-1)}><FiArrowLeft /> Go back</button>
              <button className='zbtn zbtn-primary-md' onClick={() => navigate('/rooms')}>Book a room</button>
            </div>
          </div>
          <div className="notfound__imagebox">
            <img src={Image404} alt="page not found" className='image' />
          </div>
        </div>
        </div>
      </MainLayout>
    </AnimateRoute>

  )
}

export default PageNotFound