import React, { useEffect, useState } from 'react';
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import clsx from 'clsx';

import dropIcon from '../../assets/images/svg/dropdown-icon.svg'
import { FiSearch } from 'react-icons/fi'

function SelectWithCheckbox({name, options, label, setFieldValue, ...rest}) {

    const [isActive, setIsActive] = useState(false);


    return (
        <div className="select__box">

            <Field name={name} {...rest}>
                {
                    ({field, meta}) => {
                        return (
                            <>
                                <div className="select__field" onClick={() => setIsActive(!isActive)}>
                                    {label}
                                </div>

                                <span className={clsx('select__field__dropdown', isActive === true && 'opened')}>
                                    <img src={dropIcon} alt="Dropdown Icon" onClick={() => setIsActive(!isActive)} />
                                </span>

                                {isActive && <div className="select__optionbox">
                                       
                                    <div className="select__optionbox-roller">
                                    {
                                        options.map((option, index) => {
                                            return (
                                                <React.Fragment key={option.value}>
                                                    <label htmlFor={option.value} className="check__box--label">
                                                        <input
                                                        type="checkbox"
                                                        id={option.value}
                                                        {...field}
                                                        value={option.value}
                                                        className="check__box--input"
                                                        checked={field.value.includes(option.value)}
                                                        /> 
                                                        
                                                        <span className="check__box--checker">{option.label}</span>
                                                    </label>

                                                </React.Fragment>
                                            )
                                        })
                                    }
                                    </div>
                                </div>}
                            </>
                        )
                    }
                }
            </Field>
        </div>
    )
}

export default SelectWithCheckbox