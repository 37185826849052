import React, { useRef, useState } from 'react';
import Naira from '../../../assets/images/svg/naira-icon.svg';
import {
    // FiEdit, 
    FiMapPin,
    FiMoreHorizontal,
    FiPauseCircle,
    FiPlayCircle
} from 'react-icons/fi';
import { HiBadgeCheck } from "react-icons/hi";
import { NumericFormat } from 'react-number-format';
import { useAuthContext } from '../../../hooks/useAuthContext';
import Toast from '../../../components/molecules/toast/Toast';
import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

export default function RentedRoom({ roomId, roomImage, roomTitle, status,
    roomLocation, roomPrice, staysCount, hostel_state, payDuration }) {

    const resourceURL = process.env.REACT_APP_RESOURCE_URL;
    const baseURL = process.env.REACT_APP_BASEURL;
    const { user } = useAuthContext();
    const pauseToastRef = useRef(null);
    const resumeToastRef = useRef(null);
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    // Pause a room
    const { isSuccess, mutate: pauseRoom } = useMutation({
        mutationFn: () => {
            return axios.post(`${baseURL}/room/${roomId}/edit_status`, {
                "status": "pause"
            }, {
                headers: {
                    'Authorization': `Bearer ${user}`
                }
            }).then((res) => res.data)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["MyRooms"] })
            pauseToastRef.current.show()
        }
    })

    // Resume a room
    const { mutate: activateRoom } = useMutation({
        mutationFn: () => {
            return axios.post(`${baseURL}/room/${roomId}/edit_status`, {
                "status": "available"
            }, {
                headers: {
                    'Authorization': `Bearer ${user}`
                }
            }).then((res) => res.data)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["MyRooms"] })
            resumeToastRef.current.show()
        }
    })

    return (
        <div className="listing-box rented">
            <div className="listing-image">
                <img src={`${resourceURL}${roomImage}`} alt="" />
                <div className="overlay"></div>

                {hostel_state === 'approved' && status === "unavailable" && <div className="status"> In use</div>}
                {hostel_state === 'approved' && status === "available" && <div className="status available">Available</div>}
                {hostel_state === 'approved' && status === "pause" &&
                    <div className="status paused">Paused <FiPauseCircle size="1.3rem" className='icon' /></div>
                }

                {hostel_state === 'approved' ? <div className='approval'>
                    <HiBadgeCheck className='verified' size="2rem" />
                </div> : <div className='approval pending'>
                    <span className=''>Pending approval</span>
                </div>}

                {/* controls */}

                {hostel_state === "approved" && status !== "unavailable" && <div className="controlbox">
                    <div className="controls">

                        {status === "pause" ? <div className="play__pause" onClick={() => activateRoom()}>
                            <FiPlayCircle size="1.5rem" className="icon" />
                            <span>Resume</span>
                        </div> : <div className="play__pause" onClick={() => pauseRoom()}>
                            <FiPauseCircle size="1.5rem" className='icon' />
                            <span>Pause</span>
                        </div>}

                        {/* <div className="edit">
                            <FiEdit size="1.5rem" className='icon'/> 
                            <span>Edit</span>
                        </div> */}
                    </div>

                    <div className='more'>
                        <FiMoreHorizontal size="2rem" className='icon' />
                    </div>
                </div>}
            </div>

            <div className="listing-content">
                <h1 className="listing-content__title">{roomTitle}</h1>

                <p className="listing-content__location">
                    <FiMapPin className="icon" size="1.5rem" />
                    <span>{roomLocation}</span>
                </p>

                <div className="listing-content__priceaction">

                    <p className="price">
                        <img src={Naira} alt="Naira icon" />
                        <span className="figure"> <NumericFormat value={roomPrice} displayType={'text'} thousandSeparator={true} /> </span>
                        <span className="duration">/{payDuration === "Daily" ? "day" : "month"}</span>
                    </p>

                    <div className="staybox">
                        <div className="staycount">{staysCount}</div>
                        {staysCount > 1 ? <span className='stays'>Stays</span> : <span className='stays'>Stay</span>}
                    </div>
                </div>

                {/* <div className="listing-content__view-bookings">
                    {hostel_state === 'approved' ? <button onClick={() => navigate(`/host-room-details/${roomId}`)}>View all bookings</button> :
                        <button onClick={() => navigate(`/host-room-details/${roomId}`)}>View room details</button>}
                </div> */}
            </div>

            <Toast ref={pauseToastRef} type="success" message="Room paused successfully." />
            <Toast ref={resumeToastRef} type="success" message="Room activated successfully." />
        </div>
    )
}
