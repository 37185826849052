import axios from 'axios';
import { Form, Formik } from 'formik'
import React, { useRef, useState, useEffect } from 'react'
import * as Yup from 'yup'
import FormControl from '../../../components/forms/FormControl'
import { AlertBox } from '../../../components/molecules/alertbox/AlertBox';
import { useAuthContext } from '../../../hooks/useAuthContext';
import Toast from '../../../components/molecules/toast/Toast'
import { useUserDetails } from '../../../hooks/useUserDetails';
import ContentLoader from 'react-content-loader';

export default function ContactForm() {

    const baseURL = process.env.REACT_APP_BASEURL;
    const { user } = useAuthContext();

    const toastRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingUser, setIsLoadingUser] = useState(false)
    const [error, setError] = useState(null)
    const [userInfo, setUserInfo] = useState(null)

    useEffect(() => {
        getUserInfo();
    }, [])

    // Get user details
    const getUserInfo = () => {
        setIsLoadingUser(true);
        setError(null);
        axios.get(`${baseURL}/user/me`, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((response) => {
            setUserInfo(response.data)
        }).catch((error) => {
            // setError([...error, error.message])
            setIsLoadingUser(false)
        })
    }

    const initialValues = {
        email: userInfo ? userInfo.email : '',
        subject: '',
        message: ''
    }

    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email format").required('Required'),
        subject: Yup.string().required('Required'),
        message: Yup.string().required('Required')
    })

    const onSubmit = (values, onSubmitProps) => {

        const messageBody = {
            "name": userInfo ? userInfo.lName + " " + userInfo.fName : '',
            "email": values.email,
            "subject": values.subject,
            "message": values.message
        }

        // Send user help message
        setIsLoading(true);
        setError(null);
        axios.post(`${baseURL}/user/help`, messageBody, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((response) => {

            // Initiate a toast 
            if (response) {
                toastRef.current.show();
            }
            setIsLoading(false);
            onSubmitProps.resetForm();

        }).catch((error) => {
            if (error.response.status === 422) {
                setError("Oops! something went wrong. Please try again.")
            }
            setIsLoading(false);
        })
    }

    return (
        <>
            {userInfo ? <div className="formcon">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {formik => (
                        <Form>
                            <FormControl
                                control="input"
                                name="email"
                                type="email"
                                label="Email Address"
                                disabled={true}
                            /> 

                            <FormControl
                                control="input"
                                name="subject"
                                type="text"
                                label="Enter subject"
                            />

                            <FormControl
                                control="textarea"
                                name="message"
                                type="text"
                                label="Message"
                            />

                            {!isLoading && <button type='submit'
                                className='zbtn zbtn-primary-def'
                                disabled={!(formik.dirty && formik.isValid)}
                            >
                                Send message
                            </button>}

                            {isLoading && <button type='submit'
                                className='zbtn zbtn-primary-def'
                                disabled={isLoading}
                            >
                                Sending ...
                            </button>}

                            {error && (<AlertBox type='danger' message={error} />)}
                        </Form>
                    )}

                </Formik>

                <Toast ref={toastRef} type="success" message="Message sent successfully." />
            </div> : <div className="formcon">
                <ContentLoader
                    speed={2}
                    width={412}
                    height={400}
                    viewBox="0 0 412 400"
                    backgroundColor="#F1F2F3"
                    foregroundColor="#F8F8F9"
                    style={{ width: "100%" }}

                >
                    <rect x="0" y="0" rx="6" ry="6" width="412" height="55" />
                    <rect x="0" y="90" rx="6" ry="6" width="412" height="55" />
                    <rect x="0" y="180" rx="6" ry="6" width="412" height="100" />
                    <rect x="0" y="320" rx="6" ry="6" width="148" height="45" />
                </ContentLoader>
            </div>}
        </>

    )
}
