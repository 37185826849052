import React from 'react'
import { FiX } from 'react-icons/fi'
import { motion, AnimatePresence } from 'framer-motion'

export default function Modal({ children, heading, showModal, setShowModal, wrapClose, size}) {

  // handle wrapper
  const handleWrapper = event => {
    event.stopPropagation();
  }

  return (
    <AnimatePresence>
      <motion.div
        className='modal__wrapper'
        key="modal"
        onClick={() => wrapClose ? setShowModal(!showModal) : null}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        exit={{ opacity: 0}}
      >

        <motion.div 
          className={size === "large" ? "modal__container large" : "modal__container small"}
          onClick={handleWrapper}
          initial={{ opacity: 0, y: 30}}
          animate={{ opacity: 1, y: 0}}
          transition={{ delay: 0.2, duration: 0.2 }}
          exit={{ opacity: 0, y: 0 }}
        >
          <div className="modal__content" >

            <div className="close" onClick={() => setShowModal(!showModal)}>
              <FiX className='icon' size="1.7rem" />
            </div>

            {heading && <div className="modal__header">
              <div className="modal__header--title">{heading}</div>
            </div>}

            <div className="modal__body">
              {children}
            </div>

          </div>

        </motion.div>

      </motion.div>
    </AnimatePresence>
  )
}
