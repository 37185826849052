import React from 'react';
import Onboarding from '../../../components/layouts/Onboarding';
import EmailIcon from '../../../assets/images/svg/email-success.svg';
import useAnalytics from '../../../hooks/useAnalytics';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';

export default function VerifyAccount() {

  // Use Google Analytics
  useAnalytics()

  return (
    <AnimateRoute>

      <Helmet>
        <title>Hostel.ng | Verify Account</title>
        <meta name="description" content="Verify your hostel.ng account." />
        <link rel="canonical" href="/verify-account" />
      </Helmet>

      <Onboarding
        heading="Verify your account"
        image="authbg-2"
      >
        <div className='statusbox'>
          <img src={EmailIcon} alt="" className='email-sent' />

          {/* Verification link sent */}
          <div>
            <p className='statusbox__userinfo'>
              {/* Hi <span className='username'>Folaranmi</span>,  */}
              A verification link has just been sent to your email address.
              {/* <span className='email'> (jamessoon@yahoo.com)</span>.  */}
              &nbsp; <br /> Click the link to verify your account.
            </p>

            {/* <p className='resend'>Didn't get the mail? <span className="action">Click to resend</span></p> */}
          </div>

        </div>
      </Onboarding>
    </AnimateRoute>
  )
}
