import { useContext } from "react"
import { UserDetailsContext } from "../context/UserDetailsContext"


export const useUserDetailsContext = () => {
    const context = useContext(UserDetailsContext);

    if(!context){
        throw Error("UserDetails need to be in context")
    }

    return context
}