import React, { useState } from 'react'
import {Field, ErrorMessage} from 'formik'
import TextError from './TextError'
import clsx from 'clsx'

import eyeicon from '../../assets/images/svg/eye-icon.svg';

export default function InputField(props) {

    const {disabled, label, name, type, disableFloatingLabel,value, ...rest} = props

    const [passChange, setPassChange] = useState(type)

    const togglePassword = () =>{
        if(passChange === "password"){
            setPassChange('text')
        }else if(passChange === "text"){
            setPassChange('password')
        }
    }

    // Format amount to Nigeria currency
    // const nigerianCurrencyFormat = new Intl.NumberFormat('en-NG', {
    //     currency: 'NGN',
    //     style: 'currency',
    // });

    // console.log(nigerianCurrencyFormat.format(value))

    return (

        <div className="input__box">
            <Field name={name} {...rest}>
                {
                    ({field, meta}) => {
                        return (
                            <div>
                                <input 
                                    type={passChange} 
                                    id={name} 
                                    placeholder={label}
                                    className={clsx('input__field', meta.touched && meta.error && 'error')}
                                    autoComplete="off"
                                    {...field}
                                    disabled={disabled}
                                />
                                {!disableFloatingLabel && <label htmlFor={name} className={clsx('input__label', meta.touched && meta.error && 'error')}>{label}</label>}
                                {type === 'password' && 
                                    (<span className="input__field__eye" onClick={togglePassword}>
                                        <img src={eyeicon} alt="eyeicon" />
                                    </span>)
                                }
                            </div>
                        )
                    }
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>

    )
}
