import React from 'react'
import ContentLoader from "react-content-loader"

const Facilities = ({ facilities }) => {

    const capitalize = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const images = {

    };

    return (
        <>
            {facilities ? facilities.map((facility, index) => {

                return (
                    <span key={index} className='item'>
                        <img src={require(`../../../assets/images/svg/amenities/${facility.toLowerCase()}.svg`)} className="icon" alt='' />
                        {capitalize(facility)}
                    </span>
                )
            }


            ): <ContentLoader
            speed={2}
            width={200}
            height={40}
            viewBox="0 0 200 40"
            backgroundColor="#F1F2F3"
            foregroundColor="#F8F8F9"
          >
            <rect x="0" y="0" rx="2" ry="2" width="200" height="40" />
          </ContentLoader>}
        </>
    )

}

export default Facilities