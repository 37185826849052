import React, { useEffect, useReducer, useState } from 'react'
import { FiUser } from 'react-icons/fi';
import axios from 'axios';
import { useRef } from 'react';
import Toast from '../../../../components/molecules/toast/Toast';
import ContentLoader from 'react-content-loader';
import { useMerchantAuthContext } from '../../../../hooks/useMerchantAuthContext';
import { useMerchantDetailsContext } from '../../../../hooks/useMerchantDetailsContext';
import MerchantPersonalDetailsForm from './MerchantPersonalDetailsForm';

function MerchantPersonalDetails({updateMe, setUpdateMe}) {

    const baseURL = process.env.REACT_APP_BASEURL;
    const resourceURL = process.env.REACT_APP_RESOURCE_URL;
    const { user_merchant } = useMerchantAuthContext();
    const { merchant_details } = useMerchantDetailsContext();

    const toastRef = useRef(null);

    const [userAvatar, setUserAvatar] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState('');
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0);

    const handleChangeAvatar = (e) => {
        const image = e.target.files[0];

        const formData = new FormData();

        formData.append("avatar", image);

        const uploadImage = async () => {
            await axios.post(`${baseURL}/user/avatar`, formData, {
                headers: {
                    'Authorization': `Bearer ${user_merchant}`
                }
            }).then((res) => {
                // Initiate a toast 
                if (res) {
                    toastRef.current.show();
                    forceUpdate();
                    setUpdateMe(updateMe + 1);
                }
                setRefresh({});


            }).catch((error) => {

            })
        }

        uploadImage();
    }


    return (
        <div className='personal-details'>

            {/* Profile image section */}
            <div className="avatarbox">
                {merchant_details ? <div className="profile-picture">
                    {merchant_details?.logo === "default.png" ?
                        (<FiUser className="icon" size="4rem" />) :
                        (<img src={`${resourceURL}/${merchant_details?.logo}`} className='image' />)
                    }
                </div> :
                    <div className="profile-picture">
                        <ContentLoader
                            speed={2}
                            width={100}
                            height={100}
                            viewBox="0 0 100 100"
                            backgroundColor="#F1F2F3"
                            foregroundColor="#F8F8F9"
                            style={{ width: "100%" }}
                        >
                            <circle cx="5" cy="6" r="100" />
                        </ContentLoader>
                    </div>

                }

                <div className="profile-content">
                    <input
                        type="file"
                        className='profile-input'
                        name='profile-image'
                        id='profile-image'
                        accept='image/png, image/jpeg, image/jpg'
                        onChange={handleChangeAvatar}
                        disabled
                    />
                    {merchant_details?.avatar === "default.png" ?
                        (<label htmlFor="profile-image" className='upload-badge'>Add profile picture</label>) :
                        (<label htmlFor="profile-image" className='upload-badge'>Change display</label>)
                    }
                    <span className='text'>Maximum size allowed is 2MB of PNG, JPEG ,JPG.</span>
                </div>
            </div>

            {/* Personal details section */}
            <MerchantPersonalDetailsForm />

            <Toast ref={toastRef} type="success" message="Profile image updated." />
        </div>
    )
}

export default MerchantPersonalDetails
