import React, { useEffect, useRef, useState } from 'react'
import ContentLoader from 'react-content-loader';
import { FiChevronDown, FiLogOut, FiMenu, FiUser } from 'react-icons/fi';
import { useMerchantDetailsContext } from '../../../hooks/useMerchantDetailsContext';
import { useMerchantLogOut } from '../../../hooks/useMerchantLogOut';
import { Link } from 'react-router-dom';

function MerchantHeader({ toggleMenu, setToggleMenu }) {

  const baseURL = process.env.REACT_APP_BASEURL;
  const resourceURL = process.env.REACT_APP_RESOURCE_URL;

  const [openMenu, setOpenMenu] = useState(false);

  const { merchant_details } = useMerchantDetailsContext();
  const { logOut } = useMerchantLogOut();

  const dropDownRef = useRef(null);


  // Close dropdown menu on click outside
  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setOpenMenu(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [])


  return (
    <div className='merchant__header'>

      <div className="menu" onClick={() => setToggleMenu(!toggleMenu)}>
        <FiMenu className="" size="2rem" />
      </div>

      <div className=""></div>

      <div className="dropdown__box" ref={dropDownRef}>
        <div className="auth__wrapper">
          {/* <span className='welcome'>Welcome, {merchant_details?.company}</span> */}
          <div className="avatarbox" onClick={() => setOpenMenu(!openMenu)}>
            {merchant_details?.logo ? <div className="avatar">
              {merchant_details?.logo && <img src={`${resourceURL}/${merchant_details.logo}`} alt="avatar" className='user__profile' />}
            </div> : <div className="avatar">
              <ContentLoader
                speed={2}
                width={42}
                height={42}
                viewBox="0 0 42 42"
                backgroundColor="#F1F2F3"
                foregroundColor="#F8F8F9"
                style={{ width: "100%" }}
              >
                <circle cx="5" cy="6" r="100" />
              </ContentLoader>
            </div>}

            <FiChevronDown size="1.8rem" className='icon toggle' />
          </div>
        </div>

        {openMenu &&
          <div className="menu__dropdown">
            <ul>
              <li onClick={() => setOpenMenu(false)}>
                <div onClick={logOut}>
                  <span className='anchor'><FiLogOut className='icon' /> Logout</span>
                </div>
              </li>
            </ul>
          </div>
        }
      </div>
    </div>
  )
}

export default MerchantHeader