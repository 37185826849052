import { useContext } from "react"
import { MerchantAuthContext } from "../context/MerchantAuthContext"


export const useMerchantAuthContext = () => {
    const context = useContext(MerchantAuthContext);

    if(!context){
        throw Error("Merchant Auth Context is not in context");
    }

    return context;
}