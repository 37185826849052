import React, { useEffect, useRef, useState } from 'react'
import MerchantOnboarding from '../../../../components/layouts/merchant/MerchantOnboarding';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import AnimateRoute from '../../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';
import FormControl from '../../../../components/forms/FormControl';
import EmailIcon from '../../../../assets/images/svg/email-success.svg'
import { AlertBox } from '../../../../components/molecules/alertbox/AlertBox';
import { useMutation } from '@tanstack/react-query';
import Toast from '../../../../components/molecules/toast/Toast';
import axios from 'axios';
import { useMerchantAuthContext } from '../../../../hooks/useMerchantAuthContext';
import { useNavigate } from 'react-router-dom';

function MerchantForgetPassword() {

    const baseURL = process.env.REACT_APP_BASEURL;
    const navigate = useNavigate();

    const { user_merchant } = useMerchantAuthContext();

    // Check if merchant 
    useEffect(() => {
        if (user_merchant) {
            navigate('/merchant/');
        }
    }, [user_merchant]);

    const [getEmail, setGetEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState(false);

    const forgotPasswordRef = useRef(null);
    const resendPasswordRef = useRef(null);

    // Forgot Password for merchant
    const { isSuccess, mutate: forgotPassword, isLoading } = useMutation({
        mutationFn: (email) => {
            return axios.post(`${baseURL}/merchant/auth/forgot`, {
                "email": email
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((res) => res.data)
        },
        onSuccess: () => {
            forgotPasswordRef.current.show();
            setEmailSent(true);
        },
        onError: () => {
            setError("Invalid email address. Check and try again.");
        }
    });



    const initialValues = {
        email: ''
    }

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email format').required('Email adddress required.')
    })

    const onSubmit = values => {
        forgotPassword(values.email);
        setGetEmail(values.email);
        if (error) {
            setError("");
        }
    }

    const resendForgotPassword = () => {
        if (getEmail) {
            forgotPassword(getEmail);
        }
    }


    return (

        <AnimateRoute>

            <Helmet>
                <title>Hostel.ng | Merchant Forgot Password</title>
                <meta name="description" content="Reset your hostel.ng account password." />
                <link rel="canonical" href="/merchant/forgot-password" />
            </Helmet>

            <MerchantOnboarding
                heading="Forgot Password?"
                subHeading=""
                image="authbg-4"
            >
                {!emailSent && <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ dirty, isValid }) => (
                        <Form>

                            <FormControl
                                control="input"
                                name="email"
                                type="email"
                                label="Email address"
                            />

                            <div className="action-container">

                                {!isLoading && <button type='submit' className='zbtn zbtn-primary-def' disabled={!(dirty && isValid)}>Send reset password</button>}
                                {isLoading && <button type='submit' className='zbtn zbtn-primary-def' disabled={isLoading}>Sending link ...</button>}

                            </div>

                            {error && (<AlertBox type='danger' message={error} />)}
                        </Form>
                    )}
                </Formik>}


                {emailSent && <div className='statusbox'>
                    <img src={EmailIcon} alt="" className='email-sent' />

                    <div>
                        <p className='statusbox__userinfo'>
                            {/* Hi <span className='username'>Folaranmi</span>,  */}
                            A reset password link has just been sent to your email address.
                            Click the link in your inbox to reset your password.
                        </p>
                        <p className='resend'>Didn't get the mail? <span className="action" onClick={resendForgotPassword}>Click to resend</span></p>
                    </div>


                </div>}

                <Toast ref={forgotPasswordRef} type="success" message="Account reset instructions sent." />
                <Toast ref={resendPasswordRef} type="success" message="Account reset instructions sent." />

            </MerchantOnboarding>

        </AnimateRoute>


    )
}

export default MerchantForgetPassword