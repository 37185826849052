import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import MainLayout from '../../components/layouts/users/MainLayout'
import Accordionn from '../../components/molecules/accordion/Accordionn'
import useAnalytics from '../../hooks/useAnalytics'
import AnimateRoute from '../../shared/components/animated-route/AnimateRoute'

export default function Faqs() {

  // Use Google Analytics
  useAnalytics();

  return (
    <AnimateRoute>
      
      <Helmet>
        <title>Frequently Asked Questions - Hostel.ng.</title>
        <meta name="description" content="Find the best affordable rooms on Hostel.ng." />
        <link rel="canonical" href="/faqs" />
      </Helmet>

      <div className='faq'>
        <MainLayout>
          <div className="faq__header">
            <h4 className='heading'>FAQ's. Your questions answered.</h4>
          </div>
          <div className="container">
            <div className="faq__wrapper">
              <Accordionn
                title="How does Hostel.ng work?"
                content="It's quite simple, we help you find and get 
                        sustainable & affordable rooms for your short 
                        stay rental needs using existing hostels around you, 
                        helping you save money you originally would have 
                        spent on hotel rooms."
              />

              <Accordionn
                title="How do I find rooms?"
                content="You can find a variety of short stay rooms on our 
                        platform - simply use the filters or search box by 
                        typing in your desired location, price range and check-in date.
                      "
              />

              <Accordionn
                title="How do I know if a room is available?"
                content="24 Hours after you make a reservation, your 
                        Host would accept your request and we would send your 
                        host contact details. After that, we 
                        encourage you to contact them directly to ask 
                        questions and arrange your check-in!"
              />

              <Accordionn
                title="How can I reserve a room?"
                content="To reserve a room, kindly click on “Lease a room” on the home page, go through 
                available rooms listed,  then proceed to pay and reserve a room of your choice."
              />

              <Accordionn
                title="How do I know my reservation request is accepted?"
                content="You would receive an email within 24 hours of reservation with your Host contact details."
              />

              <Accordionn
                title="What happens when the Host doesn't respond to my reservation?"
                content={(
                  <>
                    <p>
                      We share your Host's contact details immediately after your
                      reservation is confirmed by the host.
                    </p>
                    <p>
                      As soon as you reserve a room, your Host receives your request and gets to accept or decline your reservation.
                      If accepted, we would immediately send your
                      Host contact details.After that, we encourage you to contact
                      them directly to ask questions and arrange your check-in!
                    </p>
                    <p>
                      If your host declines your reservation or doesn't
                      respond within 24hrs after your reservation was made,
                      the reservation would automatically be canceled and
                      your money will be fully refunded to you.
                    </p>
                  </>
                )}
              />

              <Accordionn
                title="Does Hostel.ng own the rooms listed?"
                content="No, we do not own the rooms listed on our platform. All of the 
                rooms we have are owned by carefully vetted Hosts."
              />

              <Accordionn
                title="Can I visit a place before paying for it?"
                content={(
                  <>
                    <p>
                      Hostel.ng wants to enable everyone, especially undergraduate
                      students, to easily get the perfect hostel accommodation.
                      This is why we go through the painful process of verifying listed rooms,
                      even as far as physically visiting the rooms. Think about it; you can
                      look at dozens of different rooms on our platform in
                      the same amount of time it would have taken you to physically inspect a room!
                    </p>

                    <p>
                      To make up for the fact that you don't need to personally visit before the
                      reservation, we do our best to ensure every listing on
                      our platform only includes accurate information about
                      every place. To do so, we invest in offering Hosts professional photography services.
                    </p>
                    <p>
                      Even with all these quality checks, we want to make sure you are
                      fully satisfied and confident with your choice. After you make a
                      reservation, we'll hold your first payment and only send it to your Host
                      after you have checked out. If you get to your place and you realize it's nothing
                      like it was described on the listing on our platform, you have 24 hours
                      to get in touch with us to let us know about the issue. We'll promptly
                      look into it and make sure we help you solve the issue. You can
                      reach out to our customer support with <a href="mailto: hello@hostel.ng">hello@hostel.ng</a>.

                    </p>
                  </>
                )}
              />

              <Accordionn
                title="How does Hostel.ng make money?"
                content="We charge 20% of the entire cost of the room before reservation."
              />

              <Accordionn
                title="As a Host, do I pay to list my room?"
                content="No, room listing is free, but you must be registered as a user before you can list your room."
              />

              <Accordionn
                title="How do I list my room?"
                content="Click on “Become a host” on the homepage, then you fill the form and 
                upload necessary details. After submission, our team 
                verifies listing applications, once your application is verified and 
                confirmed, your room would automatically be listed on Hostel.ng."
              />

              <Accordionn
                title="How do I get paid when my room has been reserved?"
                content="Your money is paid directly into your in-app wallet 
                after your guest has successfully checked out. You can then 
                request payment to your bank account from the earnings tab on 
                your profile page. Note that payments are made within 24 
                hours after a request has been made."
              />

              <Accordionn
                title="How do I contact Hostel.ng?"
                content={(
                  <>
                    <p>
                      You can contact us via these channels;
                      <li>Email: <a href="mailto: hello@hostel.ng">hello@hostel.ng</a></li>
                      <li>Telephone: <a href="tel: 08087838534">08087838534</a></li>
                      <li>Twitter: <a href="https://twitter.com/HostelNg" rel='noreferrer' target="_blank">@HostelNg</a></li>
                      <li>Instagram: <a href="https://instagram.com/Hostel.ng" rel='noreferrer' target="_blank">@Hostel.ng</a></li>
                    </p>
                  </>
                )}
              />

            </div>

            <p className='text-grey-300 text-center'>Can't find what you're looking for? Visit our <Link to="/help-center" className='text-primary'>Help Center</Link>.</p>
          </div>

        </MainLayout>
      </div>

    </AnimateRoute>
  )
}
