import axios from 'axios'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import FormControl from '../../../components/forms/FormControl';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AlertBox } from '../../../components/molecules/alertbox/AlertBox';
import Success from "../../../assets/images/svg/alert_success_icon.svg"


function QuestionsForm() {

    const baseURL = process.env.REACT_APP_BASEURL;

    // const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const genderOptions = [
        { key: 'Male', value: 'Male' },
        { key: 'Female', value: 'Female' }
    ];

    const maritalStatusValues = [
        { label: 'Married', value: 'married' },
        { label: 'Single', value: 'single' },
        { label: 'Divorced', value: 'divorced' },
    ];

    const occupationValues = [
        { label: 'Unemployed', value: 'unemployed' },
        { label: 'Student', value: 'student' },
        { label: 'NYSC youth corper', value: 'NYSC youth corper' },
        { label: 'Businessman/woman', value: 'businessman or woman' },
        { label: 'Civil service', value: 'civil service' },
        { label: 'Paid employment', value: 'paid employment' },
    ];

    const stateValues = [
        { label: "Abia", value: "Abia" },
        { label: "Adamawa", value: "Adamawa" },
        { label: "Akwa Ibom", value: "Akwa Ibom" },
        { label: "Anambra", value: "Anambra" },
        { label: "Bauchi", value: "Bauchi" },
        { label: "Bayelsa", value: "Bayelsa" },
        { label: "Benue", value: "Benue" },
        { label: "Borno", value: "Borno" },
        { label: "Cross River", value: "Cross River" },
        { label: "Delta", value: "Delta" },
        { label: "Ebonyi", value: "Ebonyi" },
        { label: "Edo", value: "Edo" },
        { label: "Ekiti", value: "Ekiti" },
        { label: "Enugu", value: "Enugu" },
        { label: "FCT - Abuja", value: "FCT - Abuja" },
        { label: "Gombe", value: "Gombe" },
        { label: "Imo", value: "Imo" },
        { label: "Jigawa", value: "Jigawa" },
        { label: "Kaduna", value: "Kaduna" },
        { label: "Kano", value: "Kano" },
        { label: "Katsina", value: "Katsina" },
        { label: "Kebbi", value: "Kebbi" },
        { label: "Kogi", value: "Kogi" },
        { label: "Kwara", value: "Kwara" },
        { label: "Lagos", value: "Lagos" },
        { label: "Nasarawa", value: "Nasarawa" },
        { label: "Niger", value: "Niger" },
        { label: "Ogun", value: "Ogun" },
        { label: "Ondo", value: "Ondo" },
        { label: "Osun", value: "Osun" },
        { label: "Oyo", value: "Oyo" },
        { label: "Plateau", value: "Plateau" },
        { label: "Rivers", value: "Rivers" },
        { label: "Sokoto", value: "Sokoto" },
        { label: "Taraba", value: "Taraba" },
        { label: "Yobe", value: "Yobe" },
        { label: "Zamfara", value: "Zamfara" },
    ];

    const travelFrequencyValues = [
        { label: 'Once a week', value: 'Once a week' },
        { label: 'Once a month', value: 'Once a month' },
        { label: 'Once in 3 months', value: 'Once in 3 months' },
        { label: 'Once in 6 months', value: 'Once in 6 months' },
        { label: 'Once a year', value: 'Once a year' },

    ];

    const accommodationExpensesValues = [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
    ];

    const accommodationPricingValues = [
        { label: 'Nothing', value: 'Nothing' },
        { label: '0-10,000', value: '0-10,000' },
        { label: '10,000-20,000', value: '10,000-20,000' },
        { label: '20,000-30,000', value: '20,000-30,000' },
        { label: '30,000-40,000', value: '30,000-40,000' },
        { label: '40,000 and above', value: '40,000 and above' },
    ];

    const accommodationPaymentMethodValues = [
        { label: 'Daily', value: 'Daily' },
        { label: 'Weekly', value: 'Weekly' },
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Yearly', value: 'Yearly' },
        { label: 'I don\'t pay anything ', value: 'I don\'t pay anything ' },
    ];

    const testArray = ["one", "two", "three"];

    // convert arrays to array of objects
    const arrayToObject = (items) => {
        return items.map((str) => ({ label: str, value: str }));
    }

    // Get all input fields from endpoint

    const { data: leadFields, isLoading: LFLoading } = useQuery(['GetAllFormField'], async () => {
        return await axios.get(`${baseURL}/leads/create`, {
            headers: { 'Accept': 'application/json' }
        }).then((res) => res.data);
    });

    // Save questions
    const { mutate: saveQuestions, isError, isLoading } = useMutation({
        mutationFn: (formData) => {
            return axios.post(`${baseURL}/leads/store`, formData, {
                headers: { 'Accept': 'application/json' }
            }).then((res) => res.data)
        },
        onSuccess: () => {
            // pauseToastRef.current.show()
            setShowSuccess(true);
        },
        onError: (error) => {
            if (error.response.status === 400) {
                setErrorMessage("Please enter a valid agent code.");
            }
        }
    });


    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        gender: '',
        age: '',
        maritalStatus: '',
        occupation: '',
        state: '',
        city: '',
        cityGoing: '',
        travelFrequency: '',
        accommodationExpenses: '',
        accommodationPricing: '',
        accommodationPaymentMethod: '',
        agentCode: '',
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required('First name required.'),
        lastName: Yup.string().required('Last name required.'),
        email: Yup.string().email('Invalid email format').required('You need to enter your email address.'),
        phoneNumber: Yup.string().max(11, "Phone number can't be more than 11 characters").required('please provide your phone number.'),
        gender: Yup.string().required('Please let us know your gender.'),
        age: Yup.number().required('Please tell us your age.'),
        maritalStatus: Yup.string().required('required'),
        occupation: Yup.string().required('required'),
        state: Yup.string().required('required'),
        city: Yup.string().required('required'),
        cityGoing: Yup.string().required('required'),
        travelFrequency: Yup.string().required('required'),
        accommodationExpenses: Yup.string().required('required'),
        accommodationPricing: Yup.string().required('required'),
        accommodationPaymentMethod: Yup.string().required('required'),
        agentCode: Yup.string().required('Please enter agent code'),
    });


    const onSubmit = (values) => {

        const fullname = values.firstName + " " + values.lastName;

        const formData = new FormData();
        formData.append("name", fullname);
        formData.append("email", values?.email);
        formData.append("mobile", values?.phoneNumber);
        formData.append("gender", values?.gender);
        formData.append("age", values?.age);
        formData.append("marital_status", values?.maritalStatus);
        formData.append("occupation", values?.occupation);
        formData.append("home_state", values?.state);
        formData.append("home_city", values?.city);
        formData.append("destination_city", values?.cityGoing);
        formData.append("travel_duration", values?.travelFrequency);
        formData.append("spent_on_accommodation", values?.accommodationExpenses);
        formData.append("living_cost", values?.accommodationPricing);
        formData.append("accommodation_payment_mode", values?.accommodationPaymentMethod);
        formData.append("agent_code", values?.agentCode);

        saveQuestions(formData);
    }


    return (
        <div>
            {!showSuccess&&  <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ dirty, isValid, setFieldValue }) => (
                    <Form>

                        {/* Full name section */}
                        <div className="row agents__space">

                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>Full name  </p>
                                <span className='subheading'>How do you want to be addressed?</span>
                            </div>

                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <FormControl
                                            control="input"
                                            name="firstName"
                                            type="text"
                                            label="First name"
                                        // value={fName}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FormControl
                                            control="input"
                                            name="lastName"
                                            type="text"
                                            label="Last name"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* Email address row */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>Email address</p>
                                <span className='subheading'>What's your email address?</span>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <FormControl
                                    control="input"
                                    name="email"
                                    type="email"
                                    label="Email address"
                                />
                            </div>

                        </div>

                        {/* Phone number row */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>Phone number</p>
                                <span className='subheading'>What's your phone number?</span>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <FormControl
                                    control="input"
                                    name="phoneNumber"
                                    type="number"
                                    label="Phone number"
                                />
                            </div>
                        </div>

                        {/* Gender section */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>Gender</p>
                                <span className='subheading'>How you would like to be identified?</span>
                            </div>
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <FormControl
                                    control="radio"
                                    name="gender"
                                    options={genderOptions}
                                />
                            </div>
                        </div>

                        {/* Age row */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>Age</p>
                                <span className='subheading'>How old are you?</span>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <FormControl
                                    control="input"
                                    name="age"
                                    type="number"
                                    label="Age"
                                />
                            </div>
                        </div>

                        {/* Marital status row */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>Marital status</p>
                                <span className='subheading'>What's your marital status?</span>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <FormControl
                                    control="select"
                                    name="maritalStatus"
                                    type="select"
                                    label="Marital status"
                                    options={leadFields ? arrayToObject(leadFields?.marital_status) : null}
                                    setFieldValue={setFieldValue}
                                />
                            </div>
                        </div>

                        {/* Occupation row */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>Occupation</p>
                                <span className='subheading'>What's your marital status?</span>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <FormControl
                                    control="select"
                                    name="occupation"
                                    type="select"
                                    label="Occupation"
                                    options={leadFields ? arrayToObject(leadFields?.occupation) : null}
                                    setFieldValue={setFieldValue}
                                />
                            </div>
                        </div>

                        {/* State row */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>State</p>
                                <span className='subheading'>Which state are you from?</span>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <FormControl
                                    control="select"
                                    name="state"
                                    type="select"
                                    label="State of origin"
                                    options={leadFields ? arrayToObject(leadFields?.states) : null}
                                    setFieldValue={setFieldValue}
                                    search
                                />
                            </div>
                        </div>

                        {/* City row */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>City</p>
                                <span className='subheading'>Which city do you live in?</span>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <FormControl
                                    control="select"
                                    name="city"
                                    type="select"
                                    label="Residence City"
                                    options={leadFields ? arrayToObject(leadFields?.cities) : null}
                                    setFieldValue={setFieldValue}
                                    search
                                />
                            </div>
                        </div>

                        {/* City going to row */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>Which city are you going to?</p>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <FormControl
                                    control="select"
                                    name="cityGoing"
                                    type="select"
                                    label="Destination city"
                                    options={leadFields ? arrayToObject(leadFields?.cities) : null}
                                    setFieldValue={setFieldValue}
                                    search
                                />
                            </div>
                        </div>

                        {/* Travel frequency row */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>How frequently do you travel?</p>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <FormControl
                                    control="select"
                                    name="travelFrequency"
                                    type="select"
                                    label="Travel frequency"
                                    options={leadFields ? arrayToObject(leadFields?.travel_duration) : null}
                                    setFieldValue={setFieldValue}
                                />
                            </div>
                        </div>

                        {/* Accommodation expenses row */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>
                                    Have you ever spent on accommodation when you're travelling?
                                </p>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <FormControl
                                    control="select"
                                    name="accommodationExpenses"
                                    type="select"
                                    label="Accommodation expenses"
                                    options={accommodationExpensesValues}
                                    setFieldValue={setFieldValue}
                                />
                            </div>
                        </div>

                        {/* Accommodation pricing row */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>
                                    How much would you spend on accommodation?
                                </p>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <FormControl
                                    control="select"
                                    name="accommodationPricing"
                                    type="select"
                                    label="Accommodation pricing"
                                    options={leadFields ? arrayToObject(leadFields?.living_cost) : null}
                                    setFieldValue={setFieldValue}
                                />
                            </div>
                        </div>

                        {/* Accommodation payment method row */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>
                                    How do you pay for the accommodation?
                                </p>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <FormControl
                                    control="select"
                                    name="accommodationPaymentMethod"
                                    type="select"
                                    label="Accommodation payment method"
                                    options={accommodationPaymentMethodValues}
                                    setFieldValue={setFieldValue}
                                />
                            </div>
                        </div>


                        {/* Agent code row */}
                        <div className="row agents__space">
                            <div className="col-md-6 col-sm-12 mobile__space">
                                <p className='heading'>Enter agent code</p>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <FormControl
                                    control="input"
                                    name="agentCode"
                                    type="text"
                                    label="Agent code"
                                />
                            </div>
                        </div>

                        {/* Submit button */}

                        {!isLoading && <div className="row agents__space">
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                                <button type='submit'
                                    className='zbtn zbtn-primary-def'
                                    disabled={!(dirty && isValid)}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>}

                        {isLoading && <div className="row agents__space">
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                                <button type='submit'
                                    className='zbtn zbtn-primary-def'
                                    disabled={isLoading}
                                >
                                    Submitting ...
                                </button>
                            </div>
                        </div>}

                        {isError && <div className="row agents__space">
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                                {isError && (<AlertBox type='danger' message={errorMessage} />)}
                            </div>
                        </div>}


                    </Form>
                )}
            </Formik>}


            {showSuccess && <div className="success-box">
                <img src={Success} alt="success icon" className='icon' />
                <h4 className="heading text-center">You response have been submitted successfully.</h4>
            </div>}


        </div>
    )
}

export default QuestionsForm
