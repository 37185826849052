import React, { useRef, useState } from 'react'
import { useMerchantDetailsContext } from '../../../../hooks/useMerchantDetailsContext';
import axios from 'axios'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import FormControl from '../../../../components/forms/FormControl'
import { AlertBox } from '../../../../components/molecules/alertbox/AlertBox'
import Toast from '../../../../components/molecules/toast/Toast'
import ContentLoader from 'react-content-loader'
import { FiCheck, FiCopy, FiInfo } from 'react-icons/fi'
import { useMerchantAuthContext } from '../../../../hooks/useMerchantAuthContext';

function MerchantPersonalDetailsForm() {

    const baseURL = process.env.REACT_APP_BASEURL;
    const resourceURL = process.env.REACT_APP_RESOURCE_URL;
    const { user_merchant } = useMerchantAuthContext();
    const { merchant_details } = useMerchantDetailsContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [copied, setCopied] = useState(false);

    const toastRef = useRef(null);


    const validationSchema = Yup.object({
        companyName: Yup.string().required('Required'),
        companyEmail: Yup.string().email('Invalid email format').required('Required'),
        phoneNumber: Yup.string().max(11, "Phone number can't be more than 11 characters").required('Required'),
    })

    const onSubmit = values => {

        // merchant data to post
        const profileUpdate = {
            "company": values.companyName,
            "email": values.companyEmail,
            "mobile": values.phoneNumber,
        }

        // Update user profile details
        setIsLoading(true);
        setError(null);
        axios.post(`${baseURL}/merchant/update`, profileUpdate, {
            headers: {
                'Authorization': `Bearer ${user_merchant}`
            }
        }).then((response) => {

            // Initiate a toast here. 
            if (response) {
                toastRef.current.show();
                setIsLoading(false);
            }
        }).catch((error) => {
            // setError(error.response.data);
            setIsLoading(false);
        })

    }


    // handle Merchant link
    const merchantLink = `https://www.hostel.ng/rooms?ref=${merchant_details?.merchant_id}`;

    const copyLink = () => {

        setCopied(true);
        navigator.clipboard.writeText(merchantLink);

        setTimeout(() => {
            setCopied(false);
        }, 1500);
    }

    return (
        <>
            {merchant_details ? <div className='profile__form'>
                <Formik
                    initialValues={
                        {
                            companyName: merchant_details ? merchant_details?.company : '',
                            companyEmail: merchant_details ? merchant_details?.email : '',
                            phoneNumber: merchant_details ? merchant_details?.mobile : '',
                        }
                    }
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {formik => (
                        <Form>
                            {/* Comapany name row */}
                            <div className="row profile__space">
                                <div className="col-md-4 col-sm-12 mobile__space">
                                    <p className='heading'>Company name  </p>
                                    <span className='subheading'>Customize your company name</span>
                                </div>
                                <div className="col-md-6">
                                    <FormControl
                                        control="input"
                                        name="companyName"
                                        type="text"
                                        label="Company name"
                                    />
                                </div>
                            </div>


                            {/* Company Email address row */}

                            <div className="row profile__space">
                                <div className="col-md-4 col-sm-12 mobile__space">
                                    <p className='heading'>Company email address</p>
                                    <span className='subheading'>Your company email address</span>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <FormControl
                                        control="input"
                                        name="companyEmail"
                                        type="email"
                                        label="Company email address"
                                        disabled={true}
                                    />
                                    <div className='infotext'>
                                        <FiInfo className='info-icon' />
                                        <span>Your company email address is disabled for security reasons.</span>
                                    </div>
                                </div>

                            </div>

                            {/* Company Phone number row */}

                            <div className="row profile__space">
                                <div className="col-md-4 col-sm-12 mobile__space">
                                    <p className='heading'>Phone number</p>
                                    <span className='subheading'>Customize your company phone number</span>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <FormControl
                                        control="input"
                                        name="phoneNumber"
                                        type="text"
                                        label="Company Phone number"
                                    />
                                </div>
                            </div>

                            {!isLoading && <div className="row profile__space">
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <button type='submit'
                                        className='zbtn zbtn-primary-def'
                                        disabled={!(formik.dirty && formik.isValid)}
                                    >
                                        Save changes
                                    </button>
                                </div>
                            </div>}

                            {isLoading && <div className="row profile__space">
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <button type='submit'
                                        className='zbtn zbtn-primary-def'
                                        disabled={isLoading}
                                    >
                                        Saving changes ...
                                    </button>
                                </div>
                            </div>}

                            <div className="row profile__space">
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    {error && (<AlertBox type='danger' message={error} />)}
                                </div>
                            </div>

                        </Form>
                    )}

                </Formik>


                {/* Copy merchant link  */}
                <h3 className='section-seperator'>Your setup Instructions</h3>
                <div className="row profile__space">
                    <div className="col-md-4 col-sm-12 mobile__space">
                        <div className='subheading instructions'>
                            Click on the copy icon to copy your merchant url, 
                            paste the link on your website for conversions
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="merchant__linkbox">
                            <div className="link">
                                <div className="link__image">
                                    <img src={`${resourceURL}/${merchant_details.logo}`} alt="avatar" className='image' />
                                </div>
                                <div className="link__text">
                                    <p className='title'>Copy merchant link</p>
                                    <span className='subtitle'>hostel.ng/rooms?ref={merchant_details?.merchant_id}</span>
                                </div>
                            </div>
                            <div className="copy" onClick={copyLink}>
                                {!copied && <FiCopy size="3.2rem" className='copy__icon' />}
                                {copied && <FiCheck size="3.2rem" className='copy__checked' />}
                            </div>
                        </div>
                    </div>
                </div>


                <Toast ref={toastRef} type="success" message="Profile updated" />

            </div> : <div className='profile__form'>

                {/* Full name row */}
                <div className="row profile__space">

                    <div className="col-md-4 col-sm-12 mobile__space">
                        <p className='heading'>Company name  </p>
                        <span className='subheading'>Customize your name</span>
                    </div>
                    <div className="col-md-6">
                        <ContentLoader
                            speed={2}
                            width={289}
                            height={90}
                            viewBox="0 0 289 90"
                            backgroundColor="#F1F2F3"
                            foregroundColor="#F8F8F9"
                            style={{ width: "100%" }}
                        >
                            <rect x="0" y="0" rx="6" ry="6" width="289" height="55" />
                        </ContentLoader>
                    </div>
                </div>

                {/* Email address row */}

                <div className="row profile__space">
                    <div className="col-md-4 col-sm-12 mobile__space">
                        <p className='heading'>Company email address</p>
                        <span className='subheading'>Your company email address</span>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <ContentLoader
                            speed={2}
                            width={390}
                            height={90}
                            viewBox="0 0 390 90"
                            backgroundColor="#F1F2F3"
                            foregroundColor="#F8F8F9"
                            style={{ width: "100%" }}
                        >
                            <rect x="0" y="0" rx="6" ry="6" width="390" height="55" />
                        </ContentLoader>
                    </div>
                </div>

                {/* Phone number row */}

                <div className="row profile__space">
                    <div className="col-md-4 col-sm-12 mobile__space">
                        <p className='heading'>Company phone number</p>
                        <span className='subheading'>Customize your company phone number</span>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <ContentLoader
                            speed={2}
                            width={390}
                            height={90}
                            viewBox="0 0 390 90"
                            backgroundColor="#F1F2F3"
                            foregroundColor="#F8F8F9"
                            style={{ width: "100%" }}
                        >
                            <rect x="0" y="0" rx="6" ry="6" width="390" height="55" />
                        </ContentLoader>
                    </div>
                </div>

                {/* Save changes button */}
                <div className="row profile__space">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <ContentLoader
                            speed={2}
                            width={144}
                            height={42}
                            viewBox="0 0 390 42"
                            backgroundColor="#F1F2F3"
                            foregroundColor="#F8F8F9"
                            style={{ width: "100%" }}
                        >
                            <rect x="0" y="0" rx="6" ry="6" width="144" height="42" />
                        </ContentLoader>
                    </div>
                </div>

            </div>}
        </>
    )
}

export default MerchantPersonalDetailsForm
