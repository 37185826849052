import autoAnimate from '@formkit/auto-animate';
import React, { useEffect, useRef, useState } from 'react';
import { FiActivity, FiEye, FiEyeOff } from 'react-icons/fi';
import { GiWallet } from "react-icons/gi";
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import Naira from '../../../../assets/images/svg/naira-icon.svg';
import Modal from '../../../../components/molecules/modal/Modal';
import Toast from '../../../../components/molecules/toast/Toast';
import WithdrawForm from './WithdrawForm';
import { formatToNaira } from "../../../../shared/utils/currencyFormat";
import { useUserDetailsContext } from '../../../../hooks/useUserDetailsContext';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import AccountDetails from '../../../host/room-upload/AccountDetails';
import EditAccountDetails from './EditAccountDetails';

export default function Earnings() {

  const { user } = useAuthContext();
  const baseURL = process.env.REACT_APP_BASEURL;

  const navigate = useNavigate();
  const [hasEarnings, setHasEarnings] = useState(false);
  const [showEarnings, setShowEarnings] = useState(true);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [editAccountModal, setEditAccountModal] = useState(false);
  const [changeAmount, setChangeAmount] = useState([]);

  const [pendingWithdrawals, setPendingWithdrawals] = useState('');


  const showSection = false;
  const parentRef = useRef(null);
  const editAccountRef = useRef(null);
  const paymentRequestRef = useRef(null)

  // Get live user data and extract earnings data directly 
  const { data: earningsData, refetch: refetchEarningsData } = useQuery(['GetEarningsDetails'], async () => {
    return await axios.get(`${baseURL}/user/me`, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((res) => res.data)
  })

  const { user_details } = useUserDetailsContext();

  const totalEarnings = earningsData?.balance;
  const accountDetails = earningsData?.banks[0];
  const bankID = earningsData?.banks[0]?.id;
  const withdrawalRequestStatus = earningsData?.withdrawal.some( item => item.status === "Pending");
  const pendingRequestData = earningsData?.withdrawal.find((item) => { return item.status === "Pending" });
  const amountToWithdraw = pendingRequestData?.amount;
  

  useEffect(() => {
    setPendingWithdrawals(user_details?.withdrawal?.find((item) => { return item.status === "Pending" }))
    // user_details.withdrawal.find(({status}) =>  { return status === "Pending" ? setPendingWithdrawals(status) : []} )
  }, [pendingWithdrawals])


  useEffect(() => {
    setHasEarnings(true);
  }, [hasEarnings])


  useEffect(() => {
    if (parentRef.current) {
      autoAnimate(parentRef.current);
    }
  }, [parentRef])

  return (
    <div className='earnings'>

      {totalEarnings > 0 && <h1 className="overview">Overview</h1>}

      {/* Total earnings */}
      {totalEarnings > 0 && <div className="earnings__overview">
        <div className="total__earnings">
          <div className='title'>
            <span>Total Earnings</span>
            <div className='eyeicon' onClick={() => setShowEarnings(!showEarnings)}>
              {showEarnings ? <FiEye className="icon" size="1.4rem" /> : <FiEyeOff className="icon" size="1.4rem" />}
            </div>
          </div>
          <div className={showEarnings ? "value" : "value novalue"} ref={parentRef}>

            {showEarnings ? <NumericFormat value={totalEarnings} displayType={'text'} thousandSeparator={true} /> : "*****"}

            {showEarnings ? <img src={Naira} alt="Naira sign" className='naira' /> : ""}


          </div>
        </div>

        <div>
          {totalEarnings >= 1 ? <button
            className='zbtn zbtn-primary-def'
            onClick={() => setWithdrawModal(true)}
            disabled={withdrawalRequestStatus}
          >
            Withdraw
          </button> :
          <button className='zbtn zbtn-primary-def' disabled={withdrawalRequestStatus && totalEarnings < 1} >Withdraw</button>}
        </div>

      </div>}

      {withdrawalRequestStatus && <div className="earnings__info">
        <div className="zalert zalert-info">
          We have received your withdrawal request of <span className='color'>{formatToNaira(amountToWithdraw)} </span>
          You will be able to request for another withdrawal as soon as this is processed.
        </div>
      </div>}

      {showSection && <div className="earnings__conbox">

        {/* Total earnings */}

        <div className="earnings__box">
          <div className="titlebox">
            <div className="iconbox green"><GiWallet className='icon' size="2rem" /></div>
            <p className="text">Total Earnings</p>
          </div>
          <p className="value"><img src={Naira} alt="Naira sign" />  {totalEarnings}</p>
        </div>

        {/* Room Leasing Revenue */}
        <div className="earnings__box">
          <div className="titlebox">
            <div className="iconbox yellow"><FiActivity className='icon' size="2rem" /></div>
            <p className="text">Leasing Revenue</p>
          </div>
          <p className="value"><img src={Naira} alt="Naira sign" />  30,000</p>
        </div>

        {/* Refferrals Revenue */}
        <div className="earnings__box">
          <div className="titlebox">
            <div className="iconbox blue"><FiActivity className='icon' size="2rem" /></div>
            <p className="text">Refferral Revenue</p>
          </div>
          <p className="value"><img src={Naira} alt="Naira sign" />  30,000</p>
        </div>

        {/* Total Refferrals */}
        <div className="earnings__box">
          <div className="titlebox mb-2">
            <p className="textcount">2</p>
            <p className="text">Total Refferal(s)</p>
          </div>
          <button className='zbtn zbtn-primary-def'>Refer more friends</button>
        </div>

      </div>}

      {/* Card Details */}

      {totalEarnings > 0 && <div className="row">

        <h1 className="account-details">Bank Account Details</h1>
        <div className="debit-card">

          <div className="debit-card__wrapper">

            <div className="contentbox">
              <span className='text'>Account name</span>
              <span className='text'>{user_details?.lName} {user_details?.fName}</span>
            </div>

            <div className="contentbox">
              <span className='text'>Account number</span>
              <span className='text'>{accountDetails?.account_num}</span>
            </div>
          </div>

          <div className="debit-card__wrapper">

            <div className="contentbox">
              <span className='text'>Bank name</span>
              <span className='text'>{accountDetails?.name}</span>
            </div>

            <div className="contentbox">
              <button type='button' className='zbtn zbtn-secondary-outline-sm' onClick={() => setEditAccountModal(true)}>Edit details</button>
            </div>
          </div>

        </div>
      </div>}

      {/* Empty state for earnings */}

      {totalEarnings < 1 && <div className="empty-statecon">
        <h1 className='title'>You are yet to make money on hostel.ng </h1>
        <span className='subtitle'>Become a host to start earning.</span>
        <div className="cta">
          <button className='zbtn zbtn-primary-def' onClick={() => navigate('/host')}>Get Started</button>
          {/* <span>OR</span>
          <button className='zbtn zbtn-primary-def' disabled>Refer a Friend</button> */}
        </div>
      </div>}

      {/* Withdrawal Modal */}
      {withdrawModal && <Modal showModal={withdrawModal} setShowModal={setWithdrawModal} wrapClose>
        <div className="balancebox">
          <span className='subtitle'>Balance</span>
          <div className="balance">
            <img src={Naira} alt="Naira sign" className='naira' />
            {changeAmount ? <NumericFormat value={totalEarnings - changeAmount > 0 ? totalEarnings - changeAmount : "-- --"} displayType={'text'} thousandSeparator={true} /> :
              <NumericFormat value={totalEarnings} displayType={'text'} thousandSeparator={true} />}
          </div>
          <p className='title'>Set to withdraw your earnings</p>
        </div>

        <div className='alignme'>
          <WithdrawForm
            balance={totalEarnings}
            setWithdrawModal={setWithdrawModal}
            paymentRequestRef={paymentRequestRef}
            changeAmount={changeAmount}
            setChangeAmount={setChangeAmount}
            refetchEarningsData={refetchEarningsData}
          />
        </div>

      </Modal>}


      {/* Edit account details modal */}
      {editAccountModal && <Modal showModal={editAccountModal} setShowModal={setEditAccountModal} wrapClose>
        <div className="modal__header">
          <p className='modal__header--title'>Edit your bank details.</p>
        </div>
        <EditAccountDetails
          bankID={bankID}
          setEditAccountModal={setEditAccountModal}
          refetchEarningsData={refetchEarningsData}
          editAccountRef={editAccountRef}
        />
      </Modal>}

      <Toast ref={paymentRequestRef} type="success" message={`Payment request of ${formatToNaira(changeAmount)} sent successfully.`} />
      <Toast ref={editAccountRef} type="success" message={`Account details edited successfully.`} />
    </div>


  )
}
