import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FiGrid, FiHelpCircle, FiLogOut, FiUser, FiX } from 'react-icons/fi';

import Logo from '../../../assets/images/svg/hostelng_logo.svg'
import { useMerchantLogOut } from '../../../hooks/useMerchantLogOut';
import { motion } from 'framer-motion';
import { useMerchantAuthContext } from '../../../hooks/useMerchantAuthContext';
import { useMerchantDetailsContext } from '../../../hooks/useMerchantDetailsContext';
import axios from 'axios';

function MerchantSidebar({ toggleMenu, setToggleMenu }) {

  const { logOut } = useMerchantLogOut();

  const baseURL = process.env.REACT_APP_BASEURL;
  const { user_merchant } = useMerchantAuthContext();
  const { merchant_details, dispatch } = useMerchantDetailsContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const getMerchantDetails = async () => {
    setLoading(true);
    setError(null);

    if (user_merchant) {
      await axios.get(`${baseURL}/merchant/me`, {
        headers: {
          'Authorization': `Bearer ${user_merchant}`
        }
      }).then((response) => {

        localStorage.setItem('merchant_details', JSON.stringify(response.data.merchant));

        dispatch({
          type: "SET_MERCHANT_DETAILS",
          payload: response.data.merchant
        })

        setLoading(false);

      }).catch((error) => {
        setLoading(false);
        setError(error.message);
      })
    }

  }

  useEffect(() => {
    getMerchantDetails();
  }, []);



  return (
    <div
      className={`merchant__sidebar ${toggleMenu ? 'active' : ''}`}
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // transition={{ duration: 0.4 }}
      // exit={{ opacity: 0 }}
    >
      <div className="close" onClick={() => setToggleMenu(!toggleMenu)}>
        <FiX className='icon' />close
      </div>

      <div className="logo">
        <img src={Logo} alt="hostel.ng Logo" className='thelogo' />
      </div>

      <h3 className="welcome">Howdy {merchant_details?.company},</h3>
      <p className="merchant__id">Merchant ID: {merchant_details?.merchant_id}</p>

      {/* Navigation */}

      <nav className='nav'>
        <ul className='nav__con'>
          <li>
            <NavLink to="/merchant/dashboard" className='nav-link'>
              <FiGrid className='icon' /> Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink to="/merchant/profile" className='nav-link'>
              <FiUser className='icon' /> Profile
            </NavLink>
          </li>
          <li>
            <NavLink to="/merchant/help" className='nav-link'>
              <FiHelpCircle className='icon' /> Help center
            </NavLink>
          </li>
        </ul>

        <ul className='nav__con pb-5'>
          <li onClick={() => logOut()}>
            <Link to="" className='nav-link'>
              <FiLogOut className='icon' /> LogOut
            </Link>
          </li>
        </ul>

      </nav>
    </div>
  )
}

export default MerchantSidebar