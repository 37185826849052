import React, { useRef } from 'react';
import ContentLoader from 'react-content-loader';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import RoomList from '../homepage/RoomList';

export default function RoomsNearYou({ roomsNearBy }) {

    

    const listingRef = useRef(null);

    // Handle rooms carousel image slider.
    const handleBoxSlider = (direction) => {
        if (direction === "r") {
            listingRef.current.scrollBy({
                top: 0,
                left: 302,
                behavior: "smooth"
            });
        }else if(direction === "l") {
            listingRef.current.scrollBy({
                top: 0,
                left: -302,
                behavior: "smooth"
            });
        }
    }

    return (
        <>

            {roomsNearBy.length !== 0 && <div className="custom-listnav">
                <h4 className="section__badge section__badge--success">Rooms Near you</h4>
                <div className="navigation ">
                    <div className="round" onClick={() => handleBoxSlider("l")}> <FiChevronLeft className="icon" size='2.1rem' /> </div>
                    <div className="round" onClick={() => handleBoxSlider("r")}> <FiChevronRight className="icon" size="2.1rem" /> </div>
                </div>
            </div>}

            <div className='listing-wrapper-scroll' ref={listingRef} >
                {
                    roomsNearBy.slice(0, 8).map((room) => {
                        return (
                            <RoomList
                                key={room.id}
                                roomId={room.id}
                                roomImage={room.images[0].name}
                                roomTitle={room.name}
                                status={room.status}
                                roomLocation={room.address}
                                roomPrice={room.price}
                                payDuration={room.pay_duration}
                            />
                        )
                    })
                }
            </div>

            



        </>
    )
}
