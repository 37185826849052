import React, { useContext } from 'react'
import { FilterRoomsContext } from '../context/FilterRoomsContext'

function useFilterRoomsContext() {
    const context = useContext(FilterRoomsContext)

    if (!context) {
        throw Error('FilterRoom need to be in context')
    }

    return context
}

export default useFilterRoomsContext