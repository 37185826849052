import autoAnimate from '@formkit/auto-animate';
import React, { useEffect, useRef, useState } from 'react';
import { FiCopy, FiEye, FiEyeOff, FiPlus } from 'react-icons/fi';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import Naira from '../../../../assets/images/svg/naira-icon.svg';
import Modal from '../../../../components/molecules/modal/Modal';
import Toast from '../../../../components/molecules/toast/Toast';
import { formatToNaira } from "../../../../shared/utils/currencyFormat";
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMerchantAuthContext } from '../../../../hooks/useMerchantAuthContext';
import { useMerchantDetailsContext } from '../../../../hooks/useMerchantDetailsContext';
import AddBankDetails from './AddBankDetails';
import EditBankDetails from './EditBankDetails';
import MerchantWithdrawForm from './MerchantWithdrawForm';

function MerchantEarnings() {

    const baseURL = process.env.REACT_APP_BASEURL;
    const { user_merchant } = useMerchantAuthContext();
    const { merchant_details } = useMerchantDetailsContext();

    const navigate = useNavigate();
    const [hasEarnings, setHasEarnings] = useState(false);
    const [showEarnings, setShowEarnings] = useState(true);
    const [withdrawModal, setWithdrawModal] = useState(false);
    const [addBankModal, setAddBankModal] = useState(false);
    const [editAccountModal, setEditAccountModal] = useState(false);
    const [changeAmount, setChangeAmount] = useState([]);

    const [pendingWithdrawals, setPendingWithdrawals] = useState('');

    const parentRef = useRef(null);
    const addBankAccountRef = useRef(null);
    const editAccountRef = useRef(null);
    const paymentRequestRef = useRef(null)

    // Get live merchant data and extract earnings data directly 
    const { data: merchantData, refetch: refetchMerchantData } = useQuery(['GetMerchantDetails', user_merchant], async () => {
        if (user_merchant) {
            return await axios.get(`${baseURL}/merchant/me`, {
                headers: { 'Authorization': `Bearer ${user_merchant}` }
            }).then((res) => res.data)
        }
    })


    const totalEarnings = merchantData?.merchant?.balance;
    const bankID = merchantData?.merchant?.bank?.id;
    const withdrawalRequestStatus = merchantData?.merchant?.withdrawal?.some(item => item.status === "Pending");
    const pendingRequestData = merchantData?.merchant?.withdrawal?.find((item) => { return item.status === "Pending" });
    const amountToWithdraw = pendingRequestData?.amount;

    useEffect(() => {
        setPendingWithdrawals(merchant_details?.withdrawal?.find((item) => { return item.status === "Pending" }))
    }, [pendingWithdrawals]);


    useEffect(() => {
        setHasEarnings(true);
    }, [hasEarnings])


    useEffect(() => {
        if (parentRef.current) {
            autoAnimate(parentRef.current);
        }
    }, [parentRef])

    return (
        <div className='earnings'>

            <h1 className="overview">Overview</h1>

            {/* Total earnings */}
            {<div className="earnings__overview">
                <div className="total__earnings">
                    <div className='title'>
                        <span>Total Earnings</span>
                        <div className='eyeicon' onClick={() => setShowEarnings(!showEarnings)}>
                            {showEarnings ? <FiEye className="icon" size="1.4rem" /> : <FiEyeOff className="icon" size="1.4rem" />}
                        </div>
                    </div>
                    <div className={showEarnings ? "value" : "value novalue"} ref={parentRef}>

                        {showEarnings ? <NumericFormat value={totalEarnings} displayType={'text'} thousandSeparator={true} /> : "*****"}

                        {showEarnings ? <img src={Naira} alt="Naira sign" className='naira' /> : ""}

                    </div>
                </div>

                <div>
                    {totalEarnings >= 1 ? <button
                        className='zbtn zbtn-primary-def'
                        onClick={() => setWithdrawModal(true)}
                        disabled={withdrawalRequestStatus}
                    >
                        Withdraw
                    </button> :
                        <button className='zbtn zbtn-primary-def' disabled={totalEarnings < 1} >Withdraw</button>}
                </div>

            </div>}

            {/* Alert to approve withdrawal request */}
            {withdrawalRequestStatus && <div className="earnings__info">
                <div className="zalert zalert-info">
                    We have received your withdrawal request of <span className='color'>{formatToNaira(amountToWithdraw)} </span>
                    You will be able to request for another withdrawal as soon as this is processed.
                </div>
            </div>}


            {/* Card Details */}

            {merchantData?.merchant?.bank !== null ? (
                <div className="row">

                    <h1 className="account-details">Bank Account Details</h1>
                    <div className="debit-card">

                        <div className="debit-card__wrapper">

                            <div className="contentbox">
                                <span className='text'>Account name</span>
                                <span className='text'>{merchantData?.merchant?.bank?.account_name}</span>
                            </div>

                            <div className="contentbox">
                                <span className='text'>Account number</span>
                                <span className='text'>{merchantData?.merchant?.bank?.account_num}</span>
                            </div>
                        </div>

                        <div className="debit-card__wrapper">

                            <div className="contentbox">
                                <span className='text'>Bank name</span>
                                <span className='text'>{merchantData?.merchant?.bank?.name}</span>
                            </div>

                            <div className="contentbox">
                                <button type='button' className='zbtn zbtn-secondary-outline-sm' onClick={() => setEditAccountModal(true)}>Edit details</button>
                            </div>
                        </div>

                    </div>
                </div>) : (
                <div className="row">
                    {/* Add bank detials to merchant earnings section */}
                    <h1 className="account-details">Bank Account Details</h1>
                    <div className="add-bank-details" onClick={() => setAddBankModal(true)}>
                        <div className="iconbox">
                            <FiPlus size="2.1rem" className="icon" />
                        </div>
                        <div className="text">Add your bank details</div>
                    </div>
                </div>)
            }

            {/* Empty state for earnings */}
            {/* {totalEarnings < 1 && <div className="empty-statecon">
                <h1 className='title'>You are yet to make money on hostel.ng </h1>
                <span className='subtitle'>when you do, your earnings will show here.</span>
                <div className="cta">
                    <button className='zbtn zbtn-primary-outline-def'>Copy Merchant ID &nbsp; <FiCopy /> </button>
                </div>
            </div>} */}


            {/* Add bank details modal */}
            {addBankModal && <Modal showModal={addBankModal} setShowModal={setAddBankModal} wrapClose>
                <div className="modal__header">
                    <p className='modal__header--title'>Add bank account.</p>
                </div>
                <AddBankDetails
                    setAddBankModal={setAddBankModal}
                    refetchMerchantData={refetchMerchantData}
                    addBankAccountRef={addBankAccountRef}
                />
            </Modal>}

            {/* Edit account details modal */}
            {editAccountModal && <Modal showModal={editAccountModal} setShowModal={setEditAccountModal} wrapClose>
                <div className="modal__header">
                    <p className='modal__header--title'>Edit bank account details.</p>
                </div>
                <EditBankDetails
                    setEditAccountModal={setEditAccountModal}
                    refetchMerchantData={refetchMerchantData}
                    editAccountRef={editAccountRef}
                    merchantData={merchantData}
                    bankDetailsId={merchantData?.merchant?.bank?.id}
                />
            </Modal>}

            {/* Withdrawal request modal */}
            {withdrawModal && <Modal showModal={withdrawModal} setShowModal={setWithdrawModal} wrapClose>
                <div className="balancebox">
                    <span className='subtitle'>Balance</span>
                    <div className="balance">
                        <img src={Naira} alt="Naira sign" className='naira' />
                        {changeAmount ? <NumericFormat value={totalEarnings - changeAmount > 0 ? totalEarnings - changeAmount : "-- --"} displayType={'text'} thousandSeparator={true} /> :
                            <NumericFormat value={totalEarnings} displayType={'text'} thousandSeparator={true} />}
                    </div>
                    <p className='title'>Set to withdraw your earnings</p>
                </div>

                <div className='alignme'>
                    <MerchantWithdrawForm
                        balance={totalEarnings}
                        setWithdrawModal={setWithdrawModal}
                        paymentRequestRef={paymentRequestRef}
                        changeAmount={changeAmount}
                        setChangeAmount={setChangeAmount}
                        refetchMerchantData={refetchMerchantData}
                    />
                </div>

            </Modal>}

            <Toast ref={addBankAccountRef} type="success" message={`Account details added successfully.`} />
            <Toast ref={editAccountRef} type="success" message={`Account details edited successfully.`} />
            <Toast ref={paymentRequestRef} type="success" message={`Payment request of ${formatToNaira(changeAmount)} sent successfully.`} />

        </div>
    )
}

export default MerchantEarnings
