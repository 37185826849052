import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import clsx from 'clsx'
import { useState } from 'react'
import { DateRange } from 'react-date-range'
import {format} from 'date-fns'

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

function DateRangeField({ label, name, type, setFieldValue, disableFloatingLabel, ...rest }) {

    // Set state for dateranger picker
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ])

    const [isActive, setIsActive] = useState(false);
    // const theValue = format(dateRange[0].startDate, "MM/dd/yyyy")} format(dateRange[0].endDate, "MM/dd/yyyy")

    return (
        <div className='select__box'>
            <Field name={name} {...rest}>
                {
                    ({field, meta, value}) => {
                        return (
                            <div>
                                <input
                                    className={clsx('select__field', meta.touched && meta.error && 'error')}
                                    id={name}
                                    placeholder={label}
                                    value={dateRange[0].startDate }
                                    onClick={() => setIsActive(!isActive)}
                                    {...field}
                                    readOnly
                                />
                                {!disableFloatingLabel && <label htmlFor={name} className={clsx('select__label', meta.touched && meta.error && 'error')}>{label}</label>}

                                {isActive &&
                                    <DateRange
                                        editableDateInputs={false}
                                        onChange={item => 
                                            {
                                                setDateRange([item.selection])
                                                setFieldValue(name, [item.selection])
                                            }
                                        }
                                        moveRangeOnFirstSelection={false}
                                        ranges={dateRange}
                                    />
                                }
                            </div>
                        )
                    }
                }
            </Field>

            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default DateRangeField