import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import MerchantOnboarding from '../../../../components/layouts/merchant/MerchantOnboarding'
import AnimateRoute from '../../../../shared/components/animated-route/AnimateRoute'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { FiCheckCircle } from 'react-icons/fi'
import CircularPreloader from '../../../../shared/components/preloader/CircularPreloader'
import { AlertBox } from '../../../../components/molecules/alertbox/AlertBox'

function MerchantVerified() {

  const baseURL = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  const { code } = useParams();

  // Verify merchant user with code 
  const { data: verifyMerchant, isLoading, isError } = useQuery({
    queryKey: ['VerifyMerchant'],
    queryFn: async () => {
      return await axios.get(`${baseURL}/merchant/verify/${code}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => res.data);
    },
    onSuccess: () => {

    }
  });

  return (
    <AnimateRoute>

      <Helmet>
        <title>Hostel.ng | Verify Account</title>
        <meta name="description" content="Verify your hostel.ng account." />
      </Helmet>

      <MerchantOnboarding
        heading="Verify your account"
        subHeading=""
        image="authbg-4"
      >
        {isLoading && (<div className='d-flex justify-content-center align-items-center'>
          <CircularPreloader nocover />
        </div>)}
        {!isLoading && isError && <AlertBox
          type='danger'
          message="Oops! your verification code is invalid or has expired."
        />}

        {!isLoading && !isError && (<div className='statusbox'>

          <div className='iconbox'>
            <FiCheckCircle size="4rem" className="email-sent" />
          </div>
          <div>
            <p className='statusbox__userinfo'>
              Your account have been successfully verified. <br /> Click the button below to continue.
            </p>

          </div>

          <button type='button' className='zbtn zbtn-primary-def mt-3' onClick={() => navigate('/merchant/')}>Sign In to continue</button>

        </div>)}
      </MerchantOnboarding>

    </AnimateRoute>
  )
}

export default MerchantVerified