import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../../components/layouts/users/MainLayout'
import Modal from '../../../components/molecules/modal/Modal';
import useAnalytics from '../../../hooks/useAnalytics';
import { useAuthContext } from '../../../hooks/useAuthContext';
// import CustomSort from '../../../shared/components/custom-sort/CustomSort'
import RoomList from '../homepage/RoomList';
import ExtendStay from './ExtendStay';
import ReservedRoom from './ReservedRoom';
import EmptyList from '../../../assets/images/svg/empty-booked-state.svg'
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';

export default function ReservedRooms() {

  // Use Google Analytics
  useAnalytics()

  const baseURL = process.env.REACT_APP_BASEURL;
  const { user } = useAuthContext();

  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [roomData, setRoomData] = useState([]);
  const [reservedRooms, setReservedRooms] = useState([]);

  useEffect(() => {
    getReservedRooms();

  }, [baseURL])

  // Get all resevered rooms
  const getReservedRooms = () => {
    setIsLoading(true);
    setError(null);
    axios.get(`${baseURL}/user/bookings`, {
      headers: {
        'Content-Type': 'application/json,',
        "Accept": "application/json",
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${user}`,
      }
    }).then((response) => {
      setRoomData(response.data);
      setReservedRooms(response.data);
      setIsLoading(false);
    }).catch((error) => {
      setError(error.message);
      setIsLoading(false);
    })
  }

  // content placeholder list
  const myLoaderList = [1, 2, 3, 4];

  const [isModalActive, setIsModalActive] = useState(false);

  // Sort result by the following
  // const sortReservedRooms = [
  //   {
  //     label: 'Newest',
  //     value: 'newest'
  //   },
  //   {
  //     label: 'Oldest',
  //     value: 'oldest'
  //   }
  // ];

  // if(reservedRooms) {
  //   console.log(reservedRooms)
  // }

  return (
    <AnimateRoute>

      <Helmet>
        <title>Completely reserved rooms - Hostel.ng</title>
        <meta name="description" content="Find the best hostel rooms for your travel needs." />
        <link rel="canonical" href="/reserved-rooms" />
      </Helmet>

      <div className='rented-rooms'>
        <MainLayout>
          <div className="container">
            <div className="rented-rooms__header">
              {reservedRooms?.length > 0 && <h4 className="heading">My Reserved Room(s)</h4>}

              {/* Sort by section */}

              {/* <div className="sortby">
              <CustomSort options={sortReservedRooms} label="Sort by" />
            </div> */}
            </div>

            {/* Reserved rooms listing */}

            {reservedRooms && <div className='listing-wrapper'>
              {
                reservedRooms.map((room, index) => {
                  return (
                    <ReservedRoom
                      key={index}
                      roomId={room.hostel.id}
                      roomImage={room.hostel.images[0].name}
                      roomTitle={room.hostel.name}
                      dateIn={room.checkin_date}
                      dateOut={room.checkout_date}
                      roomLocation={room.hostel.address}
                      roomPrice={room.hostel.price}
                      payDuration={room.hostel.pay_duration}
                      setIsModalActive={setIsModalActive}
                    />
                  )
                })
              }
            </div>}

            {isLoading && <div className='listing-wrapper'>
              {
                myLoaderList.map((holder, index) => {
                  return (
                    <div className="listing-box no" key={index}>
                      <ContentLoader
                        speed={2}
                        width={282}
                        height={400}
                        viewBox="0 0 282 400"
                        backgroundColor="#F1F2F3"
                        foregroundColor="#F8F8F9"
                        style={{ width: "100%" }}

                      >
                        <rect x="0" y="0" rx="12" ry="12" width="281" height="220" />
                        <rect x="0" y="240" rx="2" ry="2" width="214" height="20" />
                        <rect x="0" y="268" rx="2" ry="2" width="140" height="14" />
                        <rect x="0" y="315" rx="2" ry="2" width="111" height="32" />
                      </ContentLoader>
                    </div>
                  )
                })
              }

            </div>}

            {!isLoading && reservedRooms.length < 1 && (<div className='listing-wrapper'>

              <div className="empty-state">
                <img src={EmptyList} alt="" />
                <h1 className='title'>You are yet to make a reservation.</h1>
                <span className='subtitle'>When you reserve a room, it will show here.</span>
                <button className='zbtn zbtn-primary-def click' type='button' onClick={() => navigate('/rooms')}>Reserve a Room</button>
              </div>

            </div>)}

          </div>
        </MainLayout>

        {isModalActive && <Modal heading="Extend your stay" isModalActive={isModalActive} setIsModalActive={setIsModalActive}>
          <ExtendStay />
        </Modal>}

      </div>

    </AnimateRoute>
  )
}
