import React, { useState } from 'react'
import MainLayout from '../../../components/layouts/users/MainLayout';
import clsx from 'clsx';
import PersonalDetails from './personal-details/PersonalDetails';
import Security from './security/Security';
import Earnings from './earnings/Earnings';
import useAnalytics from '../../../hooks/useAnalytics';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';
import { useUserDetailsContext } from '../../../hooks/useUserDetailsContext';
import Referrals from './referrals/Referrals';

export default function Profile() {

  // Use Google Analytics
  useAnalytics()

  const [index, setIndex] = useState(1);
  const [updateMe, setUpdateMe] = useState(1);
  const { user_details } = useUserDetailsContext(); 

  const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };


  return (
    <AnimateRoute>

      <Helmet>
        <title>Profile -  Hostel.ng.</title>
        <meta name="description" content="Find the best affordable rooms on Hostel.ng." />
        <link rel="canonical" href="/profile" />
      </Helmet>

      <div className='profile'>
        <MainLayout>
          <div className="profile__header">
            <div className="container">
              <p className='heading'><span>Hi</span>, {user_details.fName ? capitalize(user_details.fName) : ''} {user_details.lName ? capitalize(user_details.lName) : ''} 👋</p>
            </div>
          </div>
          
          <div className="container profile__pull">
            <div className='tabs'>
              <ul className='tabs__nav'>
                <li className={clsx('tabs__nav-link', index === 1 && 'active')} onClick={() => setIndex(1)}>Personal Details</li>
                <li className={clsx('tabs__nav-link', index === 2 && 'active')} onClick={() => setIndex(2)}>Security</li>
                <li className={clsx('tabs__nav-link', index === 3 && 'active')} onClick={() => setIndex(3)}>Earnings</li>
                <li className={clsx('tabs__nav-link', index === 4 && 'active')} onClick={() => setIndex(4)}>Referrals</li>
              </ul>

              <div className="tabs__contentbox">
                <div className={clsx('tabs__content', index === 1 && 'show')} >
                  <PersonalDetails updateMe={updateMe} setUpdateMe={setUpdateMe} />
                </div>

                <div className={clsx('tabs__content', index === 2 && 'show')} >
                  <Security />
                </div>

                <div className={clsx('tabs__content', index === 3 && 'show')} >
                  <Earnings />
                </div>

                <div className={clsx('tabs__content', index === 4 && 'show')} >
                  <Referrals />
                </div>
              </div>
            </div>
          </div>

        </MainLayout>

      </div>
    </AnimateRoute>
  )
}
