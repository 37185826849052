import axios from 'axios'
import { Form, Formik } from 'formik'
import React, { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import * as Yup from 'yup'
import FormControl from '../../../../components/forms/FormControl'
import { AlertBox } from '../../../../components/molecules/alertbox/AlertBox'
import { useAuthContext } from '../../../../hooks/useAuthContext'
import loading from '../../../../assets/images/gif/loading.gif'
import Toast from '../../../../components/molecules/toast/Toast'
import ContentLoader from 'react-content-loader'
import { FiInfo } from 'react-icons/fi'
import { useUserDetailsContext } from '../../../../hooks/useUserDetailsContext'

export default function PersonalDetailsForm() {

    const baseURL = process.env.REACT_APP_BASEURL;
    const { user } = useAuthContext();
    const {user_details} = useUserDetailsContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null)

    const [userObj, setUserObj] = useState(null);

    const toastRef = useRef(null);

    // useEffect(() => {
    //     getUserInfo();
    // }, [baseURL])

    // Get user details
    // const getUserInfo = () => {
    //     setIsLoading(true);
    //     setError(null);
    //     axios.get(`${baseURL}/user/me`, {
    //         headers: {
    //             'Authorization': `Bearer ${user}`
    //         }
    //     }).then((response) => {
    //         setUserObj(response.data);
    //     }).catch((error) => {
    //         // setError([...error, error.message])
    //         setIsLoading(false)
    //     })
    // }


    const genderOptions = [
        {
            key: 'Male',
            value: 'Male'
        },
        {
            key: 'Female',
            value: 'Female'
        }
    ]

    // Function to capitalize the first latter in a word;
    const capitalize = str => {
        return str.charAt(0)?.toUpperCase() + str.slice(1);
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email format').required('Required'),
        phoneNumber: Yup.string().max(11, "Phone number can't be more than 11 characters").required('Required'),
        gender: Yup.string().required('Required')
    })

    const onSubmit = values => {

        // User data to post
        const profileUpdate = {
            "fname": values.firstName,
            "lname": values.lastName,
            "mobile": values.phoneNumber,
            "gender": values.gender
        }

        // Update user profile details
        setIsLoading(true);
        setError(null);
        axios.post(`${baseURL}/user/update`, profileUpdate, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((response) => {
            // console.log(response.data)

            // Initiate a toast here. 
            if (response) {
                toastRef.current.show();
                setIsLoading(false);
            }
        }).catch((error) => {
            // setError(error.response.data);
            // console.log(error.response.data);
            setIsLoading(false);
        })

    }

    return (
        <>
            {user_details ? <div className='profile__form'>
                <Formik
                    initialValues={
                        {
                            firstName: user_details ? user_details?.fName : '',
                            lastName: user_details ? user_details?.lName : '',
                            email: user_details ? user_details?.email : '',
                            phoneNumber: user_details ? user_details?.mobile : '',
                            gender: user_details ? user_details?.gender : ''
                        }
                    }
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {formik => (
                        <Form>
                            {/* Full name row */}
                            <div className="row profile__space">
                                <div className="col-md-4 col-sm-12 mobile__space">
                                    <p className='heading'>Full name  </p>
                                    <span className='subheading'>Customize your name</span>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <FormControl
                                                control="input"
                                                name="firstName"
                                                type="text"
                                                label="First name"
                                            // value={fName}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FormControl
                                                control="input"
                                                name="lastName"
                                                type="text"
                                                label="Last name"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* Email address row */}

                            <div className="row profile__space">
                                <div className="col-md-4 col-sm-12 mobile__space">
                                    <p className='heading'>Email address</p>
                                    <span className='subheading'>Your email address</span>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <FormControl
                                        control="input"
                                        name="email"
                                        type="email"
                                        label="Email address"
                                        disabled={true}
                                    />
                                    <div className='infotext'>
                                        <FiInfo className='info-icon' /> 
                                        <span>Your email address is disabled for security reasons.</span>
                                    </div>
                                </div>

                            </div>

                            {/* Phone number row */}

                            <div className="row profile__space">
                                <div className="col-md-4 col-sm-12 mobile__space">
                                    <p className='heading'>Phone number</p>
                                    <span className='subheading'>Customize your phone number</span>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <FormControl
                                        control="input"
                                        name="phoneNumber"
                                        type="text"
                                        label="Phone number"
                                    />
                                </div>
                            </div>

                            {/* Gender section */}

                            <div className="row profile__space">
                                <div className="col-md-4 col-sm-12 mobile__space">
                                    <p className='heading'>Gender</p>
                                    <span className='subheading'>How you would like to be identified</span>
                                </div>
                                <div className="col-md-4 col-sm-12 mobile__space">
                                    <FormControl
                                        control="radio"
                                        name="gender"
                                        options={genderOptions}
                                    />
                                </div>
                            </div>

                            {!isLoading && <div className="row profile__space">
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <button type='submit'
                                        className='zbtn zbtn-primary-def'
                                        disabled={!(formik.dirty && formik.isValid)}
                                    >
                                        Save changes
                                    </button>
                                </div>
                            </div>}

                            {isLoading && <div className="row profile__space">
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <button type='submit'
                                        className='zbtn zbtn-primary-def'
                                        disabled={isLoading}
                                    >
                                        Saving changes ...
                                    </button>
                                </div>
                            </div>}

                            <div className="row profile__space">
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    {error && (<AlertBox type='danger' message={error} />)}
                                </div>
                            </div>

                        </Form>
                    )}

                </Formik>

                <Toast ref={toastRef} type="success" message="Profile updated" />

            </div> : <div className='profile__form'>

                {/* Full name row */}
                <div className="row profile__space">

                    <div className="col-md-4 col-sm-12 mobile__space">
                        <p className='heading'>Full name  </p>
                        <span className='subheading'>Customize your name</span>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6">
                                <ContentLoader
                                    speed={2}
                                    width={289}
                                    height={90}
                                    viewBox="0 0 289 90"
                                    backgroundColor="#F1F2F3"
                                    foregroundColor="#F8F8F9"
                                    style={{ width: "100%" }}
                                >
                                    <rect x="0" y="0" rx="6" ry="6" width="289" height="55" />
                                </ContentLoader>
                            </div>
                            <div className="col-md-6">
                                <ContentLoader
                                    speed={2}
                                    width={289}
                                    height={90}
                                    viewBox="0 0 289 90"
                                    backgroundColor="#F1F2F3"
                                    foregroundColor="#F8F8F9"
                                    style={{ width: "100%" }}
                                >
                                    <rect x="0" y="0" rx="6" ry="6" width="289" height="55" />
                                </ContentLoader>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Email address row */}

                <div className="row profile__space">
                    <div className="col-md-4 col-sm-12 mobile__space">
                        <p className='heading'>Email address</p>
                        <span className='subheading'>Your email address</span>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <ContentLoader
                            speed={2}
                            width={390}
                            height={90}
                            viewBox="0 0 390 90"
                            backgroundColor="#F1F2F3"
                            foregroundColor="#F8F8F9"
                            style={{ width: "100%" }}
                        >
                            <rect x="0" y="0" rx="6" ry="6" width="390" height="55" />
                        </ContentLoader>
                    </div>
                </div>

                {/* Phone number row */}

                <div className="row profile__space">
                    <div className="col-md-4 col-sm-12 mobile__space">
                        <p className='heading'>Phone number</p>
                        <span className='subheading'>Customize your phone number</span>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <ContentLoader
                            speed={2}
                            width={390}
                            height={90}
                            viewBox="0 0 390 90"
                            backgroundColor="#F1F2F3"
                            foregroundColor="#F8F8F9"
                            style={{ width: "100%" }}
                        >
                            <rect x="0" y="0" rx="6" ry="6" width="390" height="55" />
                        </ContentLoader>
                    </div>
                </div>

                {/* Gender section */}

                <div className="row profile__space">
                    <div className="col-md-4 col-sm-12 mobile__space">
                        <p className='heading'>Gender</p>
                        <span className='subheading'>How you would like to be identified</span>
                    </div>
                    <div className="col-md-4 col-sm-12 mobile__space">
                        <ContentLoader
                            speed={2}
                            width={390}
                            height={90}
                            viewBox="0 0 390 90"
                            backgroundColor="#F1F2F3"
                            foregroundColor="#F8F8F9"
                            style={{ width: "100%" }}
                        >
                            <rect x="0" y="0" rx="6" ry="6" width="390" height="55" />
                        </ContentLoader>
                    </div>
                </div>

                {/* Save changes button */}
                <div className="row profile__space">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <ContentLoader
                            speed={2}
                            width={144}
                            height={42}
                            viewBox="0 0 390 42"
                            backgroundColor="#F1F2F3"
                            foregroundColor="#F8F8F9"
                            style={{ width: "100%" }}
                        >
                            <rect x="0" y="0" rx="6" ry="6" width="144" height="42" />
                        </ContentLoader>
                    </div>
                </div>

            </div>}
        </>
    )
}
