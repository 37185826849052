import React from 'react'
import { FiCheckCircle } from "react-icons/fi";
import { useNavigate } from 'react-router-dom'

import MainLayout from '../../../components/layouts/users/MainLayout'

import RoomImage from '../../../assets/images/img/room-image.jpg';
import userImage from '../../../assets/images/img/user-profile-image.png';
import InsuranceImage from '../../../assets/images/img/insurance-image.png';
import JoinUsImage from '../../../assets/images/img/joinus-image.png';
import Accordionn from '../../../components/molecules/accordion/Accordionn';
import { useAuthContext } from '../../../hooks/useAuthContext';
import useGAEventsTracker from '../../../hooks/useGAEventsTracker';
import useAnalytics from '../../../hooks/useAnalytics';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';

export default function BecomeHost() {

  // Use Google Analytics
  useAnalytics()

  const navigate = useNavigate();

  const { user } = useAuthContext();

  const eventTracker = useGAEventsTracker("Become a Host");

  const isUserValid = () => {
    if (!user) {
      navigate('/login')
    } else if (user) {
      navigate('/room-upload')
    }
    eventTracker("Became a host button was clicked");
  }

  return (
    <AnimateRoute>

      <Helmet>
        <title>Lease your room on Hostel.ng.</title>
        <meta name="description" content="Start earning from your existing room on Hostel.ng." />
        <link rel="canonical" href="/host" />
      </Helmet>

      <div className='host'>
        <MainLayout>
          <div className="container">

            {/* Get started section */}
            <div className="getstarted">

              <div className="getstarted__rooms">

                <img src={RoomImage} alt="" className='getstarted__rooms--image' />
                <div className="getstarted__rooms--overlay"></div>
                <div className="getstarted__rooms--content">
                  <div className="imagebox">
                    <img src={userImage} alt="" className='profile-image' />
                  </div>

                  <div className="details">
                    <h5 className='heading'>Entire room hosted by Gift</h5>
                    <span className='subheading'>Member since May, 2022</span>
                  </div>
                </div>

              </div>

              <div className="getstarted__joinbox">
                <h4 className='heading'>Join our large community of hosts and start <span className='green'>earning</span> today.</h4>
                <p className='subheading'>Here are the things needed, for you to get started.</p>

                <ul className='listcon'>
                  <li><FiCheckCircle size="2.2rem" className='icon' /> <div>Provide your hostel room details.</div></li>
                  <li><FiCheckCircle size="2.2rem" className='icon' /> <div>Provide your room features.</div></li>
                  <li><FiCheckCircle size="2.2rem" className='icon' /> <div>Upload your room pictures.</div></li>
                  <li><FiCheckCircle size="2.2rem" className='icon' /> <div>Set your room price.</div></li>
                  <li><FiCheckCircle size="2.2rem" className='icon' /> <div>Provide your bank account details.</div></li>
                </ul>

                <button className='zbtn zbtn-primary-md large' onClick={() => isUserValid()}>Get Started</button>
              </div>

            </div>

            {/* Damage Insurance section */}
            <div className="insurance">
              <div className="insurance__content">
                <h4 className="heading">Damage Insurance</h4>
                <p className='subheading'>Free for all hosts, only on Hostel.ng <span className='badge'>coming soon</span></p>

                <button className='zbtn zbtn-primary-md large' onClick={() => navigate("/insurance")}>Learn More</button>
              </div>
              <div className="insurance__imagebox">
                <img src={InsuranceImage} alt="" className='image' />
              </div>
            </div>

            {/* Faq sections */}
            <div className="faqbox">
              <h4 className='heading'>Your questions, answered about hosting</h4>

              <Accordionn
                title="What happens when the Host doesn't respond to my reservation?"
                content={(
                  <>
                    <p>
                      We share your Host's contact details immediately after your
                      reservation is confirmed by the host.
                    </p>
                    <p>
                      As soon as you reserve a room, your Host receives your request and gets to accept or decline your reservation.
                      If accepted, we would immediately send your
                      Host contact details.After that, we encourage you to contact
                      them directly to ask questions and arrange your check-in!
                    </p>
                    <p>
                      If your host declines your reservation or doesn't
                      respond within 24hrs after your reservation was made,
                      the reservation would automatically be canceled and
                      your money will be fully refunded to you.
                    </p>
                  </>
                )}
              />

              <Accordionn
                title="Does Hostel.ng own the rooms listed?"
                content="No, we do not own the rooms listed on our platform. All of the 
                rooms we have are owned by carefully vetted Hosts."
              />

              <Accordionn
                title="Can I visit a place before paying for it?"
                content={(
                  <>
                    <p>
                      Hostel.ng wants to enable everyone, especially undergraduate
                      students, to easily get the perfect hostel accommodation.
                      This is why we go through the painful process of verifying listed rooms,
                      even as far as physically visiting the rooms. Think about it; you can
                      look at dozens of different rooms on our platform in
                      the same amount of time it would have taken you to physically inspect a room!
                    </p>

                    <p>
                      To make up for the fact that you don't need to personally visit before the
                      reservation, we do our best to ensure every listing on
                      our platform only includes accurate information about
                      every place. To do so, we invest in offering Hosts professional photography services.
                    </p>
                    <p>
                      Even with all these quality checks, we want to make sure you are
                      fully satisfied and confident with your choice. After you make a
                      reservation, we'll hold your first payment and only send it to your Host
                      after you have checked out. If you get to your place and you realize it's nothing
                      like it was described on the listing on our platform, you have 24 hours
                      to get in touch with us to let us know about the issue. We'll promptly
                      look into it and make sure we help you solve the issue. You can
                      reach out to our customer support with <a href="mailto: hello@hostel.ng">hello@hostel.ng</a>.

                    </p>
                  </>
                )}
              />

              <Accordionn
                title="How does Hostel.ng make money?"
                content="We charge 20% of the entire cost of the room before reservation."
              />

              <Accordionn
                title="As a Host, do I pay to list my room?"
                content="No, room listing is free, but you must be registered as a user before you can list your room."
              />

              <Accordionn
                title="How do I list my room?"
                content="Click on “Become a host” on the homepage, then you fill the form and 
                upload necessary details. After submission, our team 
                verifies listing applications, once your application is verified and 
                confirmed, your room would automatically be listed on Hostel.ng."
              />

              <Accordionn
                title="How do I get paid when my room has been reserved?"
                content="We send your money to your bank account within 24 
                hours after your guest has successfully checked-out."
              />

              <Accordionn
                title="How do I contact Hostel.ng?"
                content={(
                  <>
                    <p>
                      You can contact us via these channels;
                      <li>Email: <a href="mailto: hello@hostel.ng">hello@hostel.ng</a></li>
                      <li>Telephone: <a href="tel: 08087838534">08087838534</a></li>
                      <li>Twitter: <a href="https://twitter.com/HostelNg" rel='noreferrer' target="_blank">@HostelNg</a></li>
                      <li>Instagram: <a href="https://instagram.com/Hostel.ng" rel='noreferrer' target="_blank">@Hostel.ng</a></li>
                    </p>
                  </>
                )}
              />
            </div>

            {/* Join Us Section */}
            <div className="joinus">
              <div className="joinus__content">
                <h4 className="heading">Join us.</h4>
                <p className="subheading">We'll help you every step of the way.</p>

                <button className='zbtn zbtn-primary-md' onClick={() => isUserValid()}>Become a Host</button>
              </div>
              <div className="joinus__imagebox">
                <img src={JoinUsImage} alt="" className='image' />
              </div>
            </div>

          </div> {/* End of container*/}


        </MainLayout>
      </div>
    </AnimateRoute>
  )
}
