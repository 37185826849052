import React from 'react'
import Footer from '../users/Footer'
import AgentHeader from './AgentHeader'

function AgentLayout({ children }) {
    return (
        <div className='agents'>
            <AgentHeader />
            {children}
            <Footer />
        </div>
    )
}

export default AgentLayout
