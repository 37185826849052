import React from 'react'
import SecurityForm from './SecurityForm'

export default function Security() {
  return (
    <div className='security'>

      <div className='security__title'>
        <h2 className=''>Update your password</h2>
      </div>

      {/* Security tab */}
      <SecurityForm/>
    </div>
  )
}
