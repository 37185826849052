import React from 'react'
import MainLayout from '../../components/layouts/users/MainLayout'
import useAnalytics from '../../hooks/useAnalytics'
import AnimateRoute from '../../shared/components/animated-route/AnimateRoute'

export default function TermsOfUse() {
  // Use Google Analytics
  useAnalytics()

  return (
    <AnimateRoute>
      <div className='terms_privacy'>
        <MainLayout>
          <div className="terms_privacy__header">
            <h4 className='heading'>Terms of Service</h4>
            <div className="updated">Updated 19th May, 2023</div>
          </div>

          <div className="container">
            <div className="terms_privacy__content">
              <p className='text'>
                Please read these Terms of Service (“Terms”) carefully as
                they contain important information about your
                legal rights, remedies, and obligations. By accessing or
                using this website, you (“User”) agree to comply with and be bound by these Terms.
              </p>

              <p className='text'>
                These Terms of Service (“Terms”) are a binding legal agreement between you and
                Zimmer Technologies (aka Hostel.ng) that govern your right to use the
                websites, and other offerings from Hostel.ng.
              </p>

              <p className='text'>
                These terms and conditions apply to all types of services Hostel.ng
                ("Hostel.ng", "we", "us", "our" or "ours") offer, both direct or indirect.
                The terms "you", "your", "yourself", "yours", and "customer" refer to
                the individual person or corporation who subscribes to our
                newsletters and offers, creates an account with us or makes a
                reservation (whether complete/incomplete, successful/unsuccessful)
                through this website. The terms "Final Party" and "Accommodation
                Provider" refer to the hostel (not limited to organizations that
                o by 'hostel' only, but also apply to 'lodges',
                'flats', 'apartments', and 'houses') that is listed on Hostel.ng
                regardless of whether a signed deal between us and them exists or does not.
              </p>

              <p className='text'>
                We maintain other terms and policies that supplement these
                Terms like our Privacy Policy, which describes our collection and use of personal data.
              </p>

              <p className='text'>
                Terms and conditions may be revised and modified occasionally, hence,
                it is advisable not to familiarise yourself with them at any point in
                time, but to review them each and every time you use any of our services.
                Services (direct and indirect) apply to everything provided online, through
                mobile devices and computers of all platforms, by email, telephone, post, or
                through direct contact with our agents. You accept that you have read,
                understood, and agreed to the terms and conditions (as well as our privacy policy),
                if and when you make use of our service in any way,
                including but not limited to browsing this website, rating or giving
                hostel reviews, commenting on a review, or making a reservation.
              </p>

              <h1 className='title'>Legal Use</h1>

              <p className='text'>
                Your utilization of any of our services warrants that you
                will not make unlawful use of this website
                or any contents and materials we provide through other means
                with which we correspond with you or final parties on your behalf.
              </p>

              <h1 className='title'>Our Service Scope</h1>
              <p className='text'>
                Hostel.ng provides accommodation of all types, which include lodges,
                hostels, flats, apartments, etc. The provision of these services
                is found on the website through which you can reserve your chosen service.
                By placing a reservation through us, you enter into a legally binding
                agreement with the direct service provider (hostel and the likes) with which your reservation
                is made to. Right after your reservation is placed, Hostel.ng acts
                simply as a mediator between you and the service provider.
              </p>

              <p className="title">
                We pass along information you have provided towards placing a reservation unto the
                final party and relay confirmation messages, updates and invoices back to you via SMS,
                chat message, and email for and on their behalf. The accuracy of information provided
                on the website is exclusive to the service providers who are responsible for
                updating their accounts with Hostel.ng on their current status.
                Though we aim to provide information about final
                parties which are as correct as possible, we will not confirm, nor
                will we guarantee that they are complete or accurate.
              </p>

              <p className="title">
                Hostel reservation choices made by you are entirely your decision
                and any disappointments that is met cannot amount to a liability that we would bear.
              </p>

              <h1 className="title">Reservation</h1>
              <p>
                When you reserve a Listing, you are agreeing to pay all charges for your
                booking including the Listing price, applicable fees like Hostel.ng's service fee,
                offline fees, taxes, and any other items identified during checkout (collectively, “Total Price”).
                When you receive the booking confirmation, a contract for Host Services (a "Reservation")
                is formed directly between you and the Host. In addition to these Terms,
                you will be subject to, and responsible for complying with, all terms of the
                Reservation, including without limitation, the cancellation policy, and any other
                rules, standards, policies, or requirements identified in the Listing or
                during checkout that apply to the Reservation.
                It is your responsibility to read and understand these rules, standards,
                policies, and requirements prior to booking a Listing.
              </p>

              <p>
                As a Host, Hostel.ng offers you the right to use its website to share your Accommodation,
                Experience, or other Host Service with our vibrant community of Guests - and earn money doing it.
                It's easy to create a Listing and you are in control of how you
                host - set your price, availability, and rules for each Listing.
              </p>

              <h1 className="title">Accommodation Reservations.</h1>
              <p>
                An Accommodation Reservation is a limited license to enter,
                occupy, and use the Accommodation. The Host retains the right
                to re-enter the Accommodation during your stay, to the extent:
                (i) it is reasonably necessary,
                (ii) permitted by your contract with the Host, and
                (iii) consistent with applicable law. If you stay past checkout, the Host has
                the right to make you leave in a manner consistent with applicable law,
                including by imposing reasonable overstay penalties. You may
                not exceed the maximum number of allowed Guests.

              </p>

              <h1 className="title">Price Guarantee and Currency Exchange Rates</h1>
              <p>
                Hostel.ng does not process any other type of card payments,
                we collect payments through a third-party link we provide on the website.
                Any amount charged to you will be in Nigerian Naira. Tax
                rates and foreign exchange rates could change in the time between booking and stay.
              </p>

              <h1 className="title">Cancellation and no-show policy</h1>
              <p>
                Cancellation policies are two sides of a coin. By booking through
                Hostel.ng you accept both the cancellation and no-show terms of the
                accommodation provider and ours. Hostel.ng will give full refund of
                the amount paid but not yet transferred to the accommodation provider.
                Refunds of money already paid to the final party will attract their
                cancellation/no-show charges and also have our delivery/execution of
                operations cost deducted. If you pay directly to the accommodation provider,
                Hostel.ng will not be accountable for or entitled to reimburse these types of payments.
                Any of such refunds that come through us are only so on request by the
                accommodation provider and are not in any way our obligation.
              </p>

              <h1 className="title">Refund policy</h1>
              <p>
                If as a Guest you cancel a Reservation, the amount refunded to you is determined
                by the cancellation policy that applies to that Reservation. But, in certain
                situations, other policies take precedence and determine what amount is refunded to you.
                If the Host cancels, or you experience a Travel Issue,
                you may be eligible for rebooking assistance or a partial or full refund.
              </p>

              <h1 className="title">Reservation Modifications</h1>
              <p>
                Guests and Hosts are responsible for any reservation modifications
                they agree to make via the Hostel.ng or direct Hostel.ng customer
                service to make on their behalf <span className='bold'>("Reservation Modifications")</span>,
                and agree to pay any additional amounts, fees, or
                taxes associated with any Reservation Modification.
              </p>

              <h1 className='title'>Your Responsibilities</h1>
              <p>
                You are responsible and liable for your own acts and omissions and are also
                responsible for the acts and omissions of anyone you invite to join or
                provide access to any Accommodation, Experience, or other Host Service.
                For example, this means: (i) you are responsible for leaving an
                Accommodation (and related personal property) in the condition it was in
                when you arrived, (ii) you are responsible for paying all reasonable
                Damage Claim amounts necessary to cover damage that you, your guest(s),
                or your pet(s) cause to an Accommodation, and (iii) you must act with
                integrity, treat others with respect, and comply with applicable
                laws at all times. If you are booking for an additional guest who
                is a minor or if you bring a minor to a Host Service, you must be
                legally authorized to act on behalf of the minor and you are
                solely responsible for the supervision of that minor.
              </p>

              <h1 className="title">Your Assumption of Risk</h1>
              <p>
                You acknowledge that many activities carry inherent risks and agree that,
                to the maximum extent permitted by applicable law, you assume the entire
                risk arising out of your access to and use of Hostel.ng and any Content,
                including your stay at any Accommodation, use of any other Host Service,
                or any other interaction you have with other Members whether in person or
                online. This means it is your responsibility to investigate a Host Service
                to determine whether it is suitable for you. For example, Host Services may
                carry risk of illness, bodily injury, disability, or death, and you freely
                and willfully assume those risks by choosing to participate in those Host Services.
              </p>

              <h1 className="title">Indemnity</h1>
              <p>
                Zimmer technologies limited agrees to defend, indemnify and hold its officers, agents,
                employees, directors and affiliated entities harmless from any and all claims,
                damages, costs, liabilities and expenses (including
                reasonable attorney's fees) caused by, relating to or arising from any alleged
                libel, defamation, slander, invasion of privacy or any similar act, or breach of
                any contractual right of a third party, or infringement of any intellectual
                property right of a third party, including rights under patents, trademarks,
                copyright, trade secret, or confidentiality obligations, or rights of privacy
                and publicity resulting from, relating to or arising out of the performance of this
                Agreement.
              </p>

              <h1 className='title'>Sublease</h1>
              <p>
                Not to assign, sublet or part with possession of the said apartment or
                any part thereof for any reason whatsoever without the written consent of
                the Landlord or representatives of the Landlord first had and obtained.
                Not to make or permit to be made any alteration or additions to the
                apartment without the written consent of the Landlord or representatives of
                the Landlord first had and obtained. Without the consent of the landlord or
                representatives of the Landlord in writing the tenant shall not do or cause
                to be done any electrical wiring or rewiring in the room let to him other than
                to depend completely on the electric wiring provided by the landlord or
                representatives of the Landlord in the room.
              </p>

              <h1 className='title'>Force Majeure</h1>
              <p>
                If the performance of the obligations under this Agreement is prevented by
                reason of any contingencies, which could not have been reasonably avoided
                and are beyond the control of the parties, the party so affected shall not be
                liable to the other for damages to the extent of such prevention.
                Such contingencies include strikes of workers, fire, flood, explosion, riots
                sabotage, acts of God and war or enemy action.
              </p>

              <h1 className="title">Hostel.ng Rules.</h1>
              <p>You must follow these rules and must not help or induce others to break or circumvent these rules.</p>

              <ul>
                <li>Act with integrity and treat others with respect
                  <ul>
                    <li>Do not lie, misrepresent something or someone, or pretend to be someone else.</li>
                    <li>Be polite and respectful when you communicate or interact with others.</li>
                    <li>Be polite and respectful when you communicate or interact with others.</li>
                  </ul>
                </li>

                <li>Do not scrape, hack, reverse engineer, compromise or impair the Hostel.ng Platform
                  <ul>
                    <li>
                      Do not use bots, crawlers, scrapers, or other automated means to access or
                      collect data or other content from or otherwise interact with Hostel.ng.
                    </li>
                    <li>
                      Do not hack, avoid, remove, impair, or otherwise attempt to circumvent
                      any security or technological measure used to protect the Hostel.ng Platform or Content.
                    </li>
                    <li>
                      Do not decipher, decompile, disassemble, or
                      reverse engineer any of the software or hardware
                      used to provide the Hostel.ng Platform.
                    </li>
                    <li>
                      Do not take any action that could damage or adversely affect
                      the performance or proper functioning of the Hostel.ng Platform.
                    </li>
                  </ul>
                </li>

                <li>Only use Hostel.ng as authorized by these Terms or another agreement with us
                  <ul>
                    <li>
                      You may only use another Member's personal information as necessary to
                      facilitate a transaction using the Hostel.ng as authorized by these Terms
                    </li>
                    <li>
                      Do not use Hostel.ng, our messaging tools, or Members' personal information
                      to send commercial messages without the recipient's express consent.
                    </li>
                    <li>
                      You may use Content made available through Hostel.ng solely
                      as necessary to enable your use of Hostel.ng as a Guest or Host.
                    </li>
                    <li>
                      Do not use Content unless you have permission from the Content owner or
                      the use is authorized by us in these Terms or another agreement you have with us.
                    </li>
                    <li>
                      Do not request, make, or accept a reservation or any payment outside
                      of Hostel.ng to avoid paying fees, taxes, or for any other reason.
                    </li>
                    <li>
                      Do not require or encourage Guests to open an account, leave a review,
                      complete a survey, or otherwise interact, with a third-party website,
                      application or service before, during or after a Reservation, unless authorized by Hostel.ng.
                    </li>
                    <li>
                      Do not engage in any practices that are intended to manipulate our search algorithm.
                    </li>
                    <li>
                      Do not book Host Services unless you are actually using the Host Services.
                    </li>
                    <p>
                      Do not use, copy, display, mirror or frame the Hostel.ng Platform, any
                      Content, any Hostel.ng branding, or any page layout or design without our consent
                    </p>
                  </ul>
                </li>

                <li>Honor your legal obligations
                  <ul>
                    <li>
                      Understand and follow the laws that apply to you, including privacy, and data protection laws.
                    </li>
                    <li>
                      If you provide us with someone else's personal information, you:
                      (i) must do so in compliance with applicable law,
                      (ii) must be authorized to do so, and
                      (iii) authorize us to process that information under our Privacy Policy
                    </li>
                    <li>
                      Read and follow our Terms and Policies.
                    </li>
                    <li>
                      Do not organize or facilitate unauthorized parties or events.
                      You are responsible and liable for any party or event during your
                      Reservation that violates our Terms or rules of the hostel.
                    </li>
                    <li>
                      Do not use the name, logo, branding, or trademarks of Hostel.ng or others without permission.
                    </li>
                    <li>
                      Do not use or register any domain name, social media handle,
                      trade name, trademark, branding, logo, or other source
                      identifier that may be confused with Hostel.ng branding.
                    </li>
                    <li>
                      Do not offer Host Services that violate the laws or agreements that apply to you.
                    </li>
                    <li>
                      Do not offer or solicit prostitution or participate in or facilitate human trafficking.
                    </li>
                  </ul>
                </li>

              </ul>

              <h1 className="title">Further Correspondence</h1>
              <p>
                Right after you make a reservation through the website,
                we will send a confirmation of receipt of this request and send subsequent
                emails, chat messages and SMSs to keep you updated on the booking progress.
                Will remain in touch with you from the day you make your reservation, up until a
                week after you checkout to ensure you had a pleasant experience. We also
                welcome your feedback on the hostels you stay in.
              </p>

              <h1 className="title">Content.</h1>
              <p>
                Parts of the Hostel.ng enable you to provide feedback, text,
                photos, audio, video, information, and other content
                (collectively, <span className='bold'>“Content”</span> ). By providing Content, in whatever form
                and through whatever means, you grant Hostel.ng a non-exclusive,
                worldwide, royalty-free, irrevocable, perpetual, sub-licensable and
                transferable license to copy, modify, prepare derivative works of,
                distribute, publish and otherwise exploit, that Content, without
                limitation. If Content includes personal information, our Privacy
                Policy describes how we use that personal information. Where Hostel.ng
                pays for the creation of Content or facilitates its creation, Hostel.ng
                may own that Content, in which case supplemental terms or disclosures
                will say that. You are solely responsible for all Content that you
                provide and warrant that you either own it or are authorized to grant
                Hostel.ng the rights described in these Terms. You are responsible and
                liable if any of your Content violates or infringes the intellectual
                property or privacy rights of any third party. Content must comply
                with our Terms of Use which prohibit, among other things, discriminatory,
                obscene, harassing, deceptive, violent, and illegal content.
              </p>

              <h1 className="title">Customer Ratings and Reviews</h1>
              <p>
                Ratings and reviews of hostels are given by the public.
                Hostel.ng has no control over the rating tendencies
                and we cannot guarantee that the reviews are accurate
                or true, most of which are solely dependent on the
                likes and dislikes of the individual.
              </p>

              <h1 className="title">Reporting Violations</h1>
              <p>
                If you believe that a Member, Listing or Content poses an imminent
                risk of harm to a person or property, you should immediately contact
                local authorities before contacting Hostel.ng. In addition,
                if you believe that a Member, Listing, or Content has violated
                our Standards, you should report your concerns to Hostel.ng.
                If you reported an issue to local authorities, Hostel.ng may
                request a copy of that report. Except as required by law, you
                agree that we are not obligated to take action in response to any report.
              </p>

              <h1 className="title">TERMINATION</h1>
              <p>These Terms and Conditions shall remain in full force and effect while you use the website.</p>
              <p>
                WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS AND CONDITIONS,
                WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
                LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
                CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
                INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
                OR COVENANT CONTAINED IN THESE TERMS AND CONDITIONS OR OF ANY APPLICABLE
                LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE
                OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT
                ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
              </p>

              <p>
                If we terminate or suspend your account for any reason, you are prohibited
                from registering and creating a new account under your name, a fake or borrowed
                name, or the name of any third party, even if you may be acting on behalf of the third party
              </p>

              <p>
                In addition to terminating or suspending your account, we reserve the
                right to take appropriate legal action, including without limitation
                pursuing civil, criminal, and injunctive redress.
              </p>

              <h1 className='title'>Disclaimer</h1>
              <p>
                Hostel.ng bears no responsibility for any liability that may arise
                from the booking service, either to the accommodation provider,
                customer or any government agency. All final parties that may request
                Pay on Arrival options are liable for any loss they may incure and have
                to take their own measures to ensure they receive payments from customers.
              </p>
            </div>
          </div>
        </MainLayout>
      </div>
    </AnimateRoute>
  )
}
