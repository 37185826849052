import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import FormControl from '../../../components/forms/FormControl';
import { FiSearch } from 'react-icons/fi';
import { useRef } from 'react';
import useFilterRoomsContext from '../../../hooks/useFilterRoomsContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function SearchRooms({ homeUseage, setPage }) {
  // const [selectedOption, setSelectedOption] = useState('');


  const baseURL = process.env.REACT_APP_BASEURL;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [filtersFetch, setFiltersFetch] = useState([]);
  const [citiesFetch, setCitiesFetch] = useState([]);
  const [priceRangeFetch, setPriceRangeFetch] = useState([]);
  const [universityFectch, setUniversityFectch] = useState([]);
  const [facilitiesFectch, setFacilitiesFectch] = useState([]);
  const [stateFectch, setStateFectch] = useState([]);
  const [stayDuration, setStayDuration] = useState([]);

  const [clear, setClear] = useState(false);

  const { city, priceRange, facilities, dispatch } = useFilterRoomsContext();

  const handleResetFilter = () => {
    dispatch({ type: "RESET_FILTER" });
    setClear(false);
  }

  const navigate = useNavigate();

  // const {values} = useFormikContent()

  // Nigeria cities fetch
  useEffect(() => {
    // fetch cities in Nigeria from an external endpoint
    setIsLoading(true);
    setError(null);
    axios.get(`${baseURL}/room/create`, {
      headers: {
        'Content-Type': 'application/json,',
        "Accept": "application/json",
        'Access-Control-Allow-Origin': '*'
      }
    }).then((response) => {

      setIsLoading(false)
      setFiltersFetch(response.data);
      setCitiesFetch(response.data.cities);
      setPriceRangeFetch(response.data.PriceFilter);
      setUniversityFectch(response.data.university);
      setFacilitiesFectch(response.data.facilities);
      setStateFectch(response.data.states);
      setStayDuration(response.data.pay_duration);

    }).catch((error) => {

      setError(error.message);

    })

  }, []);


  // Convert citiesfetch array into a multiple object of key value pair
  // useEffect(() => {
  //   if (citiesFetch !== []) {
  //     const data = Object.entries(citiesFetch).map(city => city)
  //     const dataObject = data.map(([value, label]) => ({ value, label }))
  //     // setNigeriaCities(dataObject.sort())
  //   }

  // }, [citiesFetch])

  // Convert object to array
  const objectToArr = (obj) => {
    const data = Object.entries(obj).map(item => item);
    const dataObject = data.map(([value, label]) => ({ value, label }));
    return dataObject;
  }

  // Facilities Options
  // const facilitiesOptions = [
  //   { key: 'Television', value: 'television' },
  //   { key: 'Toilet', value: 'toilet' },
  //   { key: 'Security', value: 'security' },
  //   { key: 'Generator', value: 'generator' },
  //   { key: 'Wifi', value: 'wifi' },
  //   { key: 'Fridge', value: 'fridge' },
  //   { key: 'Table', value: 'table' },
  //   { key: 'Bed', value: 'bed' },
  // ]

  // Price range options
  // const optionsValues = [
  //   { value: 'range1', label: '3,000 - 4,999 (NGN)' },
  //   { value: 'range2', label: '5,000 - 7,999 (NGN)' },
  //   { value: 'range3', label: '8,000 - 9,999 (NGN)' },
  //   { value: 'range4', label: '10,000 (NGN) and Above' },
  // ]

  const initialValues = {
    cities: '',
    priceRange: '',
    facilities: [],
    payDuration: '',
  }

  const validationSchema = Yup.object({
    cities: Yup.string(),
    priceRange: Yup.string(),
    facilities: Yup.array(),
    payDuration: Yup.string(),
  })

  const onSubmit = (values) => {

    dispatch({
      type: "NEW_FILTER",
      payload: {
        city: values.cities,
        priceRange: values.priceRange,
        facilities: values.facilities.join(','),
        payDuration: values.payDuration,
      }
    })

    setClear(true);
    navigate('/rooms');
    if(setPage){
      setPage(1);
    }

  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {
        formik => (
          <Form>


            {homeUseage ? <div className="home-searchbox">
              <div className="cities">
                <FormControl
                  control="select"
                  name="cities"
                  type="select"
                  label="Search by cities"
                  options={objectToArr(citiesFetch)}
                  setFieldValue={formik.setFieldValue}
                  search
                  className="mobile"
                />
              </div>

              <div className="price-range">
                <FormControl
                  control="select"
                  name="priceRange"
                  type="select"
                  label="Price range"
                  className="mobile"
                  options={objectToArr(priceRangeFetch)}
                  setFieldValue={formik.setFieldValue}
                />
              </div>

              <div className="facilities">
                <FormControl
                  control="selectCheckbox"
                  name="facilities"
                  options={objectToArr(facilitiesFectch)}
                  label="Facilities"
                  setFieldValue={formik.setFieldValue}
                />
              </div>
              <div className="sendbtn">
                <button className="zbtn zbtn-primary-md block" type='submit' disabled={!(formik.dirty && formik.isValid)}>
                  <span className='search'>Search</span><FiSearch size="2.4rem" className='search-icon' />
                </button>
              </div>
            </div> : (<div className="filter__children">
              <div className="filter__children--dropbox">
                <FormControl
                  control="select"
                  name="cities"
                  type="select"
                  label="Search by cities"
                  options={objectToArr(citiesFetch)}
                  setFieldValue={formik.setFieldValue}
                  disableFloatingLabel={true}
                  passValue={formik.values.cities}
                  search
                  className="mobile"
                />
              </div>

              <div className="filter__children--dropbox">
                <FormControl
                  control="select"
                  name="payDuration"
                  type="select"
                  label="Stay duration"
                  className="mobile"
                  options={objectToArr(stayDuration)}
                  disableFloatingLabel={true}
                  setFieldValue={formik.setFieldValue}
                  passValue={formik.values.payDuration}
                />
              </div>

              <div className="filter__children--dropbox">
                <FormControl
                  control="select"
                  name="priceRange"
                  type="select"
                  label="Price range"
                  className="mobile"
                  options={objectToArr(priceRangeFetch)}
                  disableFloatingLabel={true}
                  setFieldValue={formik.setFieldValue}
                  passValue={formik.values.priceRange}
                />
              </div>

              <div className="filter__children--dropbox">
                <FormControl
                  control="selectCheckbox"
                  name="facilities"
                  options={objectToArr(facilitiesFectch)}
                  label="Facilities"
                  disableFloatingLabel={true}
                  setFieldValue={formik.setFieldValue}

                />
              </div>

              <div className="filter__children--dropbox group">
                <button className="zbtn zbtn-primary-def block" type='submit' disabled={!(formik.dirty && formik.isValid)}>
                  <span className='search'>Go </span>
                </button>

                {clear && <div className="clearbox ml-2">

                  <span className='clear' onClick={() => {
                    formik.resetForm();
                    handleResetFilter();
                  }} >Clear </span>
                </div>}
              </div>

            </div>)}




          </Form>
        )
      }
    </Formik>
  )
}
