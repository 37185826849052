import { useEffect } from "react";
import { useReducer } from "react";
import { createContext } from "react";


export const AuthContext = createContext()


// Initial state declaration
const initialState = {
    user: null,
}


// Auth reducer that determines the action to be taken
export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {user: action.payload};

        case 'SIGNUP':
            return {user: action.payload}
        
        case 'LOGOUT':
            return {user: null}

        default:
            return state;
    }
}

export const AuthContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(authReducer, initialState)

    // Check if user token exist in local storage then update global state 
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))

        if (user) {
            dispatch({
                type: 'LOGIN',
                payload: user
            })
        }

    }, [])

    return (
        <AuthContext.Provider value={{ ...state, dispatch}}>
            {children}
        </AuthContext.Provider>
    )
}