import { createContext, useEffect, useReducer } from "react";


export const UserDetailsContext = createContext();

const initialState = {
    user_details: {},
}

// User Details reducer that determines the action to be taken
export const userDetailsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_DETAILS':
            return { user_details: action.payload };

        case 'UNSET_DETAILS':
            return { user_details: {} }

        default:
            return state;
    }
}


// UserDetailsContext provider
export const UserDetailsContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(userDetailsReducer, initialState);

    // Check if user_details data exist in local storage then update global state
    useEffect(() => {
        const user_details = JSON.parse(localStorage.getItem('user_details'));

        if (user_details) {
            dispatch({
                type: 'SET_DETAILS',
                payload: user_details
            })
        }

    }, []);

    return (
        <UserDetailsContext.Provider value={{ ...state, dispatch }}>
            {children}
        </UserDetailsContext.Provider>
    )
}