import React, { useEffect, useState } from 'react'
import { FiSearch, FiX } from 'react-icons/fi'
import CircularPreloader from '../preloader/CircularPreloader';

export default function CustomSearch({ setSearchValue, searchValue, refetch, isLoading }) {

  const [isTouched, setIsTouched] = useState(false);

  // Get user search input
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  }

  // handle search on enter key press
  const searchOnEnter = (event) => {
    if (event.key === 'Enter') {
      if (searchValue !== "") {
        refetch();
      }
    }
  }

  // handle button submit
  const searchButtonClick = () => {
    if (searchValue !== "") {
      refetch();
    }
  }

  // Cancel search value
  const handleCancelSearch = () => {
    setSearchValue("");
  }

  // Handle if the search input field has been touched
  const handleBlur = () => {
    setIsTouched(true);
  }

  // debouncer
  useEffect(() => {
    const debounceTimer = setTimeout(() => {

      // check if the input field has been touched: if yes fire the refetch function
      if( isTouched) {
        refetch();
      }

    }, 1500);

    return () => clearTimeout(debounceTimer);
    
  }, [searchValue]);

  return (
    <div className='custom-search'>
      <input type="text" className='search'
        placeholder='Start your search'
        name='searchme'
        value={searchValue}
        onChange={(e) => handleSearch(e)}
        onKeyPress={searchOnEnter}
        onBlur={handleBlur}
      />
      <FiSearch className='icon' size="1.9rem" />

      {searchValue && <div className="cancel__search" onClick={() => handleCancelSearch()}>
        <FiX className='theicon' />
      </div>}

      <button className='zbtn zbtn-primary-def button' onClick={() => searchButtonClick}>Search</button>

      {isLoading && <CircularPreloader />}

    </div>
  )
}
