import React from 'react'
import {Field, ErrorMessage} from 'formik'
import TextError from './TextError'
import clsx from 'clsx'

export default function RadioButtonField({label, name, options, ...rest}) {
  return (
    <div className='radio__box'>
      <Field name={name} {...rest} >
          {
              ({field}) => {
                  return options.map((option) => {
                      return (
                          <React.Fragment key={option.value}>
                              
                                <label htmlFor={option.value} className="radio__label">
                                    <input 
                                        type="radio"
                                        id={option.value}
                                        {...field}
                                        value={option.value}
                                        checked={field.value === option.value}
                                        className="radio__input"
                                    />
                                    <span className={clsx('radio-check',{'checked': field.value === option.value})}></span>
                                    <span className='radio-text'>{option.key}</span>
                                </label>
                          </React.Fragment>
                      )
                  })
              }
          }
      </Field>

      <ErrorMessage  name={name} component={TextError} />
  </div>
  )
}
