import React from 'react'
import { FiChevronLeft, FiEdit } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom';
import MainLayout from '../../../components/layouts/users/MainLayout';
import Naira from '../../../assets/images/svg/naira-icon.svg';


import image1 from "../../../assets/images/img/hostel-room4.jpg";
import LoginForm from './LoginForm';
import { useState } from 'react';
import useAnalytics from '../../../hooks/useAnalytics';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';

export default function ReservationSummary() {

  // Use Google Analytics
  useAnalytics()

  const navigate = useNavigate();

  const [showLoginForm, setShowLoginForm] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  return (
    <AnimateRoute>
      <div className='reservation-summary'>
        <MainLayout>
          <div className="container">

            <div className="heading">
              <div className="heading__backicon" onClick={() => navigate('/room-details/3')}>
                <FiChevronLeft size="2.5rem" />
              </div>
              <div className="heading__text">Confirm and pay</div>
            </div>

            <div className="payment">

              <div className="hostel__content">
                <div className="details__box">

                  <div className='imageblock'>
                    <img src={image1} alt="hostel display" className='image' />
                  </div>

                  <div className="nameanduser">

                    <div className="name">
                      <h4 className="heading">Providence Hostel </h4>
                      <span className="subheading">Jamesbond street, off jackson avenue anambra</span>
                    </div>

                    <div className="user">
                      <div className="profile__image">
                        <img src={image1} alt="user profile avatar" className='image' />
                      </div>
                      <div className="reviewbox">
                        <div className="hostname">Olasupo Susan</div>
                        <div className="reviews">4 Reviews</div>
                      </div>
                    </div>

                  </div>

                </div>

                <div className="date__details">
                  <div className="checkinout">
                    <span className='title'>Check In Date</span>
                    <p className='datetime'>Feb 22, 2022 <span className='time'> - 2pm</span></p>
                  </div>
                  <div className="checkinout">
                    <span className='title'>Check Out Date</span>
                    <p className='datetime'>Feb 22, 2022 <span className='time'> - 2pm</span> <span className='edit'> <FiEdit size="1.8rem" className="icon" /> Edit</span></p>
                  </div>
                </div>
              </div>

              {/* Payment summary */}
              <div className="payment__summary">
                <h4 className="title">Payment summary</h4>

                <div className="payment-review">
                  <span><img src={Naira} alt="naira" /> 3,000 x 2 days</span>
                  <span><img src={Naira} alt="naira" /> 6,000</span>
                </div>

                <div className="payment-review">
                  <span>Service fee</span>
                  <span><img src={Naira} alt="naira symbol" /> 500</span>
                </div>

                <div className="payment-review last">
                  <span>Total</span>
                  <span><img src={Naira} alt="naira symbol" /> 6,500</span>
                </div>

                <div className="cta">
                  <button className='zbtn zbtn-primary-md zbtn-block'>Pay ₦ 6,500</button>
                  <span className='terms'>By clicking the button above, I accept Hostel.ng &nbsp; <Link to="/terms" className='link'> Terms of service</Link> </span>
                </div>

              </div>

            </div>


            <div className="authcheck__box">
              <div className="row">
                <div className="col-md-7">

                  {/* This section is displayed when user is successfully logged in. */}
                  {isLogin && <div className="policy">
                    <h4 className='title'>Cancellation policy</h4>
                    <p className='content'>
                      Canceling a room reservation 24hrs prior to check-in
                      time warrants a thousand naira (N1,000) cancellation fee.
                    </p>
                    <p className='content'>
                      Reservations can be canceled by Hostel.ng or the Host as they see fit if foul play is detected.
                    </p>
                  </div>}


                  {/* This section is displayed when user is not logged in. */}

                  {isLogin === false && <div className="auth">
                    <h4 className='title'>Sign In or Create an account to reserve a room.</h4>
                    <div className="cta">
                      <button className='zbtn zbtn-primary-md mr-3' onClick={() => setShowLoginForm(true)}>Sign in</button>
                      <button className='zbtn zbtn-primary-outline-md' onClick={() => navigate('/signup')}>Create an Account</button>
                    </div>
                  </div>}

                </div>
              </div>

              {/* Sign In Form */}

              {showLoginForm && <LoginForm setIsLogin={setIsLogin} />}

            </div>

          </div>
        </MainLayout>
      </div>
    </AnimateRoute>
  )
}
