import React from 'react'
import { Link } from 'react-router-dom';

import WhiteLogo from '../../assets/images/svg/hostelng_logo_white.svg';
import BlueLogo from '../../assets/images/svg/hostelng_logo.svg';

export default function Onboarding({children, image, heading, subHeading}) {
  return (
    <div className='onboarding'>

        <div className={`onboarding__image ${image}`}>
            <Link to="/" >
                <img src={WhiteLogo} alt="hostel.ng logo" className='onboarding__logo' />
            </Link>
        </div>

        <div className="onboarding__content">
            <div>
                <Link to="/" ><img src={BlueLogo} alt="hostel.ng logo" className='onboarding__content--logo'/></Link>
            </div>
            <div className="onboarding__content--title">
                <h1 className="display-1-small-semibold">{heading}</h1>
            </div>
            <div className="onboarding__content--subtitle">
                <span className="body-text-1-regular">{subHeading}</span>
            </div>
            <div className="onboarding__content--contentbox">
                {children}
            </div>
            
        </div>
    </div>
  )
}
