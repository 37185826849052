import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import RoomList from './RoomList';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useAuthContext } from '../../../hooks/useAuthContext';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import { useRef } from 'react';


export default function RoomListings() {

    const baseURL = process.env.REACT_APP_BASEURL;
    const { user } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [rooms, setRooms] = useState([])

    useEffect(() => {
        getRoomListings();

    }, [baseURL])

    // Get all room listings
    const getRoomListings = () => {
        setIsLoading(true);
        setError(null);
        axios.get(`${baseURL}/room/featured`, {
            headers: {
                'Content-Type': 'application/json,',
                "Accept": "application/json",
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${user}`,
            }
        }).then((response) => {
            setRooms(response.data.featuredHostels);
            setIsLoading(false);
        }).catch((error) => {
            setError(error.message)
            setIsLoading(false);
        })
    }

    // content placeholder list
    const myLoaderList = [1, 2, 3, 4];

    const listingRef = useRef(null);

    // Handle rooms carousel image slider.
    const handleBoxSlider = (direction) => {
        if (direction === "r") {
            listingRef.current.scrollBy({
                top: 0,
                left: 302,
                behavior: "smooth"
            });
        }else if(direction === "l") {
            listingRef.current.scrollBy({
                top: 0,
                left: -302,
                behavior: "smooth"
            });
        }
    }


    return (
        <>
            <div className="header__titlebox">

                <div className="header__titlebox--left">
                    <h1 className="title">Explore our rooms</h1>
                    <p className="subtitle">Discover amazing rooms available for you</p>
                </div>
                <div className="header__titlebox--right">
                    <div className="navigation">
                        <div className="round" onClick={() => handleBoxSlider("l")} > <FiChevronLeft className="icon" size='2.1rem' /> </div>
                        <div className="round" onClick={() => handleBoxSlider("r")} > <FiChevronRight className="icon" size="2.1rem" /> </div>
                    </div>
                </div>
            </div>

            {rooms && <div className='listing-wrapper-scroll' ref={listingRef}>
                {
                    rooms.slice(0, 8).map((room) => {
                        return (
                            <RoomList
                                key={room.id}
                                roomId={room.id}
                                roomImage={room.images[0].name}
                                roomTitle={room.name}
                                status={room.status}
                                roomLocation={`${room.address}, ${room.city.name}`}
                                roomPrice={room.price}
                                payDuration={room.pay_duration}
                            />
                        )
                    })
                }
            </div>}

            {rooms.length === 0 && <div className='listing-wrapper'>
                {
                    myLoaderList.map((holder, index) => {
                        return (
                            <div className="listing-box no" key={index}>
                                <ContentLoader
                                    speed={2}
                                    width={282}
                                    height={400}
                                    viewBox="0 0 282 400"
                                    backgroundColor="#F1F2F3"
                                    foregroundColor="#F8F8F9"
                                    style={{ width: "100%" }}

                                >
                                    <rect x="0" y="0" rx="12" ry="12" width="281" height="220" />
                                    <rect x="0" y="240" rx="2" ry="2" width="214" height="20" />
                                    <rect x="0" y="268" rx="2" ry="2" width="140" height="14" />
                                    <rect x="0" y="315" rx="2" ry="2" width="111" height="32" />
                                </ContentLoader>
                            </div>
                        )
                    })
                }


            </div>}

            <div className="seeall">
                <Link to="/rooms" className='text-primary'>See all <FiChevronRight /> </Link>
            </div>
        </>

    )
}
