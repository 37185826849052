import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import clsx from 'clsx'
import { useState } from 'react'
import dateFormat from 'dateformat';
import { Calendar } from 'react-date-range';

function DatePickerField({ label, name, type, setFieldValue, disableFloatingLabel, minDate, disabledDates, ...rest }) {

    // Set state for dateranger picker
    const [selectedDate, setSelectedDate] = useState(null);

    const [isActive, setIsActive] = useState(false);

    return (
        <div className='select__box'>
            <Field name={name} {...rest}>
                {
                    ({ field, meta, value }) => {
                        return (
                            <>
                                <div
                                    className={clsx('select__field', meta.touched && meta.error && 'error')}
                                    id={name}
                                    placeholder={label}
                                    // value={selectedDate ? dateFormat(selectedDate, "mmmm,dd, yyyy") : ''}
                                    onClick={() => setIsActive(!isActive)}
                                    {...field}
                                    readOnly

                                >
                                    {selectedDate ? dateFormat(selectedDate, "dddd, mmmm dS, yyyy") : label}
                                </div>
                                {!disableFloatingLabel && <label htmlFor={name} className={clsx('select__label', meta.touched && meta.error && 'error')}>{label}</label>}

                                {isActive &&
                                    <Calendar
                                        onChange={item => {
                                            setSelectedDate(item)
                                            setFieldValue(name, item)
                                            setIsActive(false)
                                        }
                                        }
                                        date={selectedDate}
                                        minDate={minDate}
                                        disabledDates={disabledDates}
                                        className="datepicker"
                                    />
                                }
                            </>
                        )
                    }
                }
            </Field>

            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default DatePickerField