import React, { useState } from 'react'
import MerchantLayout from '../../../components/layouts/merchant/MerchantLayout';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import Overview1 from '../../../assets/images/svg/user-signups.svg'
import Overview2 from '../../../assets/images/svg/rooms-bookings.svg'
import Overview3 from '../../../assets/images/svg/total-earnings.svg'
import UserSignupsToday from '../../../assets/images/svg/user-signups-time.svg'
import EarningsTime from '../../../assets/images/svg/earnings-time.svg'
import { NumericFormat } from 'react-number-format';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts'
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMerchantAuthContext } from '../../../hooks/useMerchantAuthContext';
import TheCustomSort from '../../../shared/components/custom-sort/TheCustomSort';

function MerchantDashboard() {

  const baseURL = process.env.REACT_APP_BASEURL;
  const { user_merchant } = useMerchantAuthContext();

  const [signUpValue, setSignUpValue] = useState("year");
  const [earningsValue, setEarningsValue] = useState("year");

  const filterOptions = [
    { label: 'This week', value: 'week' },
    { label: 'This year', value: 'year' },
  ]

  const signUpData = [
    { days: 'Mon', signups: 34950 },
    { days: 'Tue', signups: 310 },
    { days: 'Wed', signups: 120 },
    { days: 'Thur', signups: 550 },
    { days: 'Fri', signups: 70000 },
    { days: 'Sat', signups: 50 },
    { days: 'Sun', signups: 15 },
  ];

  const earningsData = [
    { days: 'Mon', earnings: 3540 },
    { days: 'Tue', earnings: 67010 },
    { days: 'Wed', earnings: 50020 },
    { days: 'Thur', earnings: 55800 },
    { days: 'Fri', earnings: 72890 },
    { days: 'Sat', earnings: 48950 },
    { days: 'Sun', earnings: 15203 },
  ];


  // Total Bookings Count
  const { data: getDashboardData, isLoading: DBLoading } = useQuery({
    queryKey: ["BookingsCount", user_merchant],
    queryFn: async () => {
      if (user_merchant) {
        return await axios.get(`${baseURL}/merchant/dashboard`, {
          headers: { 'Authorization': `Bearer ${user_merchant}` }
        }).then((res) => res.data)
      }
    }
  });


  // Get users signup Graph data
  const { data: getSignUpGraph, isLoading: SGLoading } = useQuery(["GetSignUpGraph", signUpValue, user_merchant], () => {
    if (user_merchant) {
      return axios.get(`${baseURL}/merchant/graph/users?type=${signUpValue ? signUpValue : "year"}`, {
        headers: { 'Authorization': `Bearer ${user_merchant}` }
      }).then((res) => res.data);
    }
  });

  // Get Earnings Graph data
  const { data: getEarningsGraph, isLoading: EGLoading } = useQuery(["GetEarningsGraph", earningsValue, user_merchant], () => {
    if (user_merchant) {
      return axios.get(`${baseURL}/merchant/graph/earnings?type=${earningsValue ? earningsValue : "year"}`, {
        headers: { 'Authorization': `Bearer ${user_merchant}` }
      }).then((res) => res.data);
    }
  });

  // console.log(getSignUpGraph?.graphData);

  return (
    <AnimateRoute>
      <MerchantLayout>

        <div className="merchant__dashboard">
          <div className="overview">

            <div className="overview__box">
              <div className="iconbox">
                <img src={Overview1} alt="" />
              </div>
              <div className="overview__details">
                <h4 className='value'>{getDashboardData?.usersCount}</h4>
                <span className='text'>Total User Signups</span>
              </div>
            </div>


            <div className="overview__box">
              <div className="iconbox">
                <img src={Overview2} alt="" />
              </div>
              <div className="overview__details">
                <h4 className='value'>{getDashboardData?.bookingsCount}</h4>
                <span className='text'>Total Room Bookings</span>
              </div>
            </div>

            <div className="overview__box">
              <div className="iconbox">
                <img src={Overview3} alt="" />
              </div>
              <div className="overview__details">
                <h4 className='value'><NumericFormat value={getDashboardData?.totalEarnings} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></h4>
                <span className='text'>Total Earnings</span>
              </div>
            </div>

            <div className="overview__box">
              <div className="iconbox">
                <img src={Overview3} alt="" />
              </div>
              <div className="overview__details">
                <h4 className='value'><NumericFormat value={getDashboardData?.hostelCount} displayType={'text'} thousandSeparator={true} /></h4>
                <span className='text'>Rooms Upload</span>
              </div>
            </div>

          </div>

          {/* Total user signups */}
          <div className="analytics">
            <div className="zcard analytics__graph ">
              <div className="header">
                <p className="heading">Total user Signups</p>
                <div className="filters">
                  <TheCustomSort options={filterOptions} setSelectedValue={setSignUpValue} label="Sort by" />
                </div>
              </div>

              <div className="graphbox">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    width={500}
                    height={300}
                    margin={{ top: 10, right: 20, left: 0, bottom: 0 }}
                    data={getSignUpGraph?.graphData ? getSignUpGraph?.graphData : []}
                  >
                    <defs>
                      <linearGradient id="signupColors" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#83A4EB" stopOpacity={0.8} />
                        <stop offset="100%" stopColor="#CDDBF7" stopOpacity={0} />
                      </linearGradient>
                    </defs>

                    <XAxis dataKey={signUpValue === "year" ? "month" : "day"} />
                    <YAxis />
                    <Tooltip />
                    {/* <Line dataKey="signups" type="monotone" stroke='#074AD7' activeDot={{ r: 8 }} /> */}
                    <Area type="monotone" dataKey="signups" stroke="#074AD7" fillOpacity={1} fill="url(#signupColors)" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>

            </div>

            <div className="zcard analytics__figures ">

              <div className="figurebox blue">

                <div className="content">
                  <h4 className="title">User Signups Today</h4>
                  <span className='count'>{getDashboardData?.signupToday}</span>
                </div>

                <div className="icon">
                  <img src={UserSignupsToday} alt="signups for today" />
                </div>

              </div>

              <div className="figurebox green">

                <div className="content">
                  <h4 className="title">User Signups This week</h4>
                  <span className='count'>{getDashboardData?.signupWeekly}</span>
                </div>

                <div className="icon">
                  <img src={UserSignupsToday} alt="user signups this week" />
                </div>

              </div>

            </div>
          </div>

          {/* Total Earnings */}
          <div className="analytics">
            <div className="zcard analytics__graph ">
              <div className="header">
                <p className="heading">Total Earnings</p>
                <div className="filters">
                  <TheCustomSort options={filterOptions} setSelectedValue={setEarningsValue} label="Sort by" />
                </div>
              </div>

              <div className="graphbox">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    width={500}
                    height={300}
                    margin={{ top: 10, right: 20, left: 0, bottom: 0 }}
                    data={getEarningsGraph?.graphData ? getEarningsGraph?.graphData : []}
                  >
                    <defs>
                      <linearGradient id="earningsColor" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#9BD8C3" stopOpacity={0.8} />
                        <stop offset="100%" stopColor="#D7EFE7" stopOpacity={0} />
                      </linearGradient>
                    </defs>

                    <XAxis dataKey={earningsValue === "year" ? "month" : "day"} />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="amount" stroke="#9BD8C3" fillOpacity={1} fill="url(#earningsColor)" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>

            </div>

            <div className="zcard analytics__figures ">

              <div className="figurebox blue">
                <div className="content">
                  <h4 className="title">Earnings Today</h4>
                  <span className='count'><NumericFormat value={getDashboardData?.earningsToday} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></span>
                </div>
                <div className="icon">
                  <img src={EarningsTime} alt="signups for today" />
                </div>
              </div>

              <div className="figurebox green">
                <div className="content">
                  <h4 className="title">Earnings This week</h4>
                  <span className='count'>
                    <NumericFormat value={getDashboardData?.earningsWeekly} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                  </span>
                </div>
                <div className="icon">
                  <img src={EarningsTime} alt="user signups this week" />
                </div>
              </div>

            </div>
          </div>




        </div>
      </MerchantLayout>
    </AnimateRoute>


  )
}

export default MerchantDashboard