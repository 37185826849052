import React from 'react'
import {Field, ErrorMessage} from 'formik'
import TextError from './TextError'
import clsx from 'clsx'

export default function TextAreaField(props) {

  const {name, label, ...rest} = props
  return (
    <div className='input__box'>
      <Field  name={name} {...rest}>
        {
          ({field, meta}) => {
            return (
              <div>
                <textarea 
                    id={name} 
                    placeholder={label}
                    className={clsx('input__field textarea', meta.touched && meta.error && 'error')}
                    autoComplete="off"
                    {...field}
                >{label}</textarea>
                <label htmlFor={name} className={clsx('input__label', meta.touched && meta.error && 'error')}>{label}</label>
                
              </div>
            )
          }
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}
