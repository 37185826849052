import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query';
import ContentLoader from 'react-content-loader';
import { Form, Formik } from 'formik';
import FormControl from '../../../../components/forms/FormControl';
import { AlertBox } from '../../../../components/molecules/alertbox/AlertBox';
import axios from 'axios';
import * as Yup from 'yup';
import { useMerchantAuthContext } from '../../../../hooks/useMerchantAuthContext';

function EditBankDetails({ setEditAccountModal, refetchMerchantData, editAccountRef, bankDetailsId, merchantData }) {
    const baseURL = process.env.REACT_APP_BASEURL;
    const { user_merchant } = useMerchantAuthContext();

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const getBankList = {
        "Access Bank": "Access Bank",
        "Citibank": "Citibank",
        "Diamond Bank": "Diamond Bank",
        "Ecobank Nigeria": "Ecobank Nigeria",
        "Fidelity Bank Nigeria": "Fidelity Bank Nigeria",
        "First Bank of Nigeria": "First Bank of Nigeria",
        "First City Monument Bank": "First City Monument Bank",
        "Guaranty Trust Bank": "Guaranty Trust Bank",
        "Heritage Bank Plc": "Heritage Bank Plc",
        "Jaiz Bank": "Jaiz Bank",
        "Keystone Bank Limited": "Keystone Bank Limited",
        "Polaris Bank": "Polaris Bank",
        "Providus Bank Plc": "Providus Bank Plc",
        "Standard Chartered Bank": "Standard Chartered Bank",
        "Suntrust Bank Nigeria Limited": "Suntrust Bank Nigeria Limited",
        "Union Bank of Nigeria": "Union Bank of Nigeria",
        "United Bank for Africa": "United Bank for Africa",
        "Unity Bank Plc": "Unity Bank Plc",
        "Wema Bank": "Wema Bank",
        "Zenith Bank": "Zenith Bank"
    }

    // Get all bank list
    // const { data: getBankList, isError } = useQuery(['GetBankList'], () => {
    //     return axios.get(`${baseURL}/user/banks`, {
    //         headers: {
    //             'Authorization': `Bearer ${user_merchant}`
    //         }
    //     }).then((res) => res.data)
    // });

    // convert object to array
    const objectToArr = (obj) => {
        const data = Object.entries(obj).map(item => item);
        const dataObject = data.map(([value, label]) => ({ value, label }));
        return dataObject;
    }

    const initialValues = {
        bankName: merchantData ? merchantData?.merchant?.bank?.name : '',
        accountName: merchantData ? merchantData?.merchant?.bank?.account_name : '',
        accountNumber: merchantData ? merchantData?.merchant?.bank?.account_num : ''
    }

    const validationSchema = Yup.object({
        bankName: Yup.string().required('Bank name is required'),
        accountName: Yup.string().required('Account name is required'),
        accountNumber: Yup.string().min(10, "Account number must be 10 digits").max(10, "Account number can't be more than 10 digits").required('Account number is required')
    })

    const onSubmit = values => {

        const editBankDetails = {
            "bankName": values.bankName,
            "accountName": values.accountName,
            "accountNum": values.accountNumber.toString()
        }

        // Add bank details
        setIsLoading(true);
        setError(null);

        axios.put(`${baseURL}/merchant/${bankDetailsId}/updateBank`, editBankDetails, {
            headers: { 'Authorization': `Bearer ${user_merchant}` }
        }).then((response) => {

            if (response) {
                setIsLoading(false);
                refetchMerchantData();
                editAccountRef.current.show();
            }
            setEditAccountModal(false);


        }).catch((error) => {
            if (error.response.status === 422) {
                setError("Account number already taken. Please try another");
                setIsLoading(false);
            }

            if (error.response.status === 404) {
                setError("Something went wrong, kindly try again later.");
                setIsLoading(false);
            }
        })

    }


    return (
        <>

            {getBankList ? <div className="formbox">

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ setFieldValue, dirty, isValid }) => (
                        <Form>
                            <FormControl
                                control="select"
                                name="bankName"
                                type="select"
                                label="Select bank name"
                                options={objectToArr(getBankList)}
                                setFieldValue={setFieldValue}
                                search
                            />

                            <FormControl
                                control="input"
                                name="accountName"
                                type="text"
                                label="Account name"
                            />

                            <FormControl
                                control="inputNumbers"
                                name="accountNumber"
                                type="text"
                                label="Account number"
                                maxLength="10"
                            />

                            {!isLoading && <button type='submit' className='zbtn zbtn-primary-md zbtn-block' disabled={!(dirty && isValid)}>Save Account Details</button>}
                            {isLoading && <button type='submit' className='zbtn zbtn-primary-md zbtn-block' disabled={isLoading}>Saving...</button>}

                            {error && (<AlertBox type='danger' message={error} />)}
                        </Form>
                    )}
                </Formik>
            </div> : <div className='formbox'>
                <ContentLoader
                    speed={2}
                    width={282}
                    height={250}
                    viewBox="0 0 282 250"
                    backgroundColor="#F1F2F3"
                    foregroundColor="#F8F8F9"
                    style={{ width: "100%" }}

                >
                    <rect x="0" y="0" rx="2" ry="2" width="412" height="55" />
                    <rect x="0" y="90" rx="2" ry="2" width="412" height="55" />
                    {/* <rect x="0" y="180" rx="2" ry="2" width="412" height="55" /> */}
                </ContentLoader></div>}
        </>
    )
}

export default EditBankDetails;