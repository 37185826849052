import { Form, Formik } from 'formik';
import React from 'react';
import FormControl from '../../../components/forms/FormControl';
import * as Yup from 'yup';

export default function LoginForm({setIsLogin}) {


    const initialValues = {
        email : '',
        password: ''
    }

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email format').required('Required'),
        password: Yup.string().required('Required')
    })

    const onSubmit = values => {
        console.log('Form Data', values);
        setIsLogin(true);
    }

  return (
    <div className="login__box">
        <Formik 
        initialValues={initialValues} 
        validationSchema={validationSchema} 
        onSubmit={onSubmit}
      >
        {   
          formik => (
            <Form>
              
                <FormControl 
                    control="input" 
                    name="email" 
                    type="email" 
                    label="Email Address" 
                />

                <FormControl 
                    control="input" 
                    name="password" 
                    type="password" 
                    label="Password" 
                />

                <button type='submit' className='zbtn zbtn-primary-def' disabled={!(formik.dirty && formik.isValid)}>Continue to Login</button>

            </Form>
          )

        }  
      </Formik>  
    </div>
  )
}
