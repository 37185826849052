import {createSlice} from "@reduxjs/toolkit";

const initialStateValue = {
    userInfo: {},
    loading: false,
    error: false,
    errorMessage: null
}

const userDetailsSlice = createSlice({
    name: "userDetails",
    initialState: {value: initialStateValue},
    reducers: {
        userDetailsLoading: (state, action) => {
            state.value.loading = true;
        },
        userDetailsSuccess: (state, action) => {
            state.value.loading = false;
            state.value.userInfo = action.payload;
        },
        changeUserAvatar: (state, action) => {
            state.value.loading = false;
            state.value.userInfo = action.payload;
        },
        userDetailsError: (state, action) => {
            state.value.loading = false;
            state.value.error = true;
            state.value.errorMessage = action.payload;
        }
    }
})

export const { 
    userDetailsLoading, 
    userDetailsSuccess, 
    userDetailsError, 
    changeUserAvatar,

} = userDetailsSlice.actions

export default userDetailsSlice.reducer