import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useRef, useState, useEffect } from 'react';
import * as Yup from 'yup'
import FormControl from '../../../components/forms/FormControl';
import AddImage from '../../../assets/images/svg/add-image-icon.svg';
import { FiTrash2 } from 'react-icons/fi';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { AlertBox } from '../../../components/molecules/alertbox/AlertBox';
import axios from 'axios';
import { useUserDetailsContext } from '../../../hooks/useUserDetailsContext';
import { motion } from 'framer-motion';
import CurrencyInput from 'react-currency-input-field';
import TextError from '../../../components/forms/TextError';
import CircularPreloader from '../../../shared/components/preloader/CircularPreloader';
import ContentLoader from 'react-content-loader';
import ProgressBar from '../../../shared/progressbar/ProgressBar';

export default function RoomUploadForm({ visible, setVisible, setRoomUpload, roomUpload }) {

    const hideFileInput = useRef(null)

    const baseURL = process.env.REACT_APP_BASEURL;
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const { user_details } = useUserDetailsContext();

    // Facilities Options
    const facilitiesOptions = [
        { key: 'Television', value: 'television' },
        { key: 'Toilet', value: 'toilet' },
        { key: 'Security', value: 'security' },
        { key: 'Generator', value: 'generator' },
        { key: 'Wifi', value: 'wifi' },
        { key: 'Fridge', value: 'fridge' },
        { key: 'Table', value: 'table' },
        { key: 'Bed', value: 'bed' },
    ]

    const [isLoading, setIsLoading] = useState(null);
    const [optionsLoading, setOptionsLoading] = useState(false);
    const [error, setError] = useState(null);

    // const [citiesFetch, setCitiesFetch] = useState([])
    const [nigeriaCities, setNigeriaCities] = useState(null);

    const [citiesFetch, setCitiesFetch] = useState([]);
    const [universityFectch, setUniversityFectch] = useState([]);
    const [facilitiesFectch, setFacilitiesFectch] = useState([]);
    const [paymentDuration, setPaymentDuration] = useState(null);
    const [paymentChecker, setPaymentChecker] = useState("");
    const [getPrice, setGetPrice] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);




    const [selectedImages, setSelectedImages] = useState([]);
    const [hideInputBox, setHideInputBox] = useState(true);
    const [base64, setBase64] = useState([]);
    const [roomImages, setRoomImages] = useState([]);

    // const hasBank = JSON.parse(localStorage.getItem('hasB'));
    const hasBank = user_details?.banks;

    useEffect(() => {
        // fetch cities in Nigeria from an external endpoint
        setOptionsLoading(true);
        setError(null);
        axios.get(`${baseURL}/room/create`, {
            headers: {
                'Content-Type': 'application/json,',
                "Accept": "application/json",
                'Access-Control-Allow-Origin': '*'
            }
        }).then((response) => {

            setOptionsLoading(false);
            setCitiesFetch(response.data.cities);
            setUniversityFectch(response.data.university);
            setFacilitiesFectch(response.data.facilities);
            setPaymentDuration(response.data.pay_duration);

        }).catch((error) => {
            setError(error.message);
            setOptionsLoading(false)
        })

    }, []);

    // Convert object to array
    const objectToArr = (obj) => {
        const data = Object.entries(obj).map(item => item);
        const dataObject = data.map(([value, label]) => ({ value, label }));
        return dataObject;
    }

    //Initiate choose file upload
    const handleFileClick = (e) => {
        e.preventDefault();
        hideFileInput.current.click();
    }

    // Remove all images from preview
    const removeAllImages = () => {
        setSelectedImages([]);
        setHideInputBox(true);
    }


    // Set the image upload box or image view box visible
    useEffect(() => {

        if (selectedImages === []) {
            setHideInputBox(false)
        }

        if (selectedImages.length > 0) {
            setHideInputBox(false)
        }
    }, [hideInputBox, selectedImages]);


    const toBase64 = (file) => {

        return new Promise((resolve, reject) => {

            const reader = new FileReader();

            reader.onload = async () => {
                await resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            }
            reader.readAsDataURL(file);

        })
    }

    // Capitalize text
    const capitalize = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const initialValues = {
        schoolName: '',
        hostelName: '',
        roomNumber: '',
        facilities: [],
        city: '',
        hostelAddress: '',
        payDuration: '',
        roomPrice: '',
        images: []
    }

    const validationSchema = Yup.object({
        schoolName: Yup.string().required('Required'),
        hostelName: Yup.string().required('Required'),
        roomNumber: Yup.string().required('Required'),
        facilities: Yup.array().min(1, "Select at least one facility").required('Required'),
        city: Yup.string().required('Required'),
        hostelAddress: Yup.string().required('Required'),
        payDuration: Yup.string().required('Select your payment duration'),
        roomPrice: Yup.number().positive().label('roomPrice').min(2500, "Room price must be above N2,500").required('Required'),
        images: Yup.array().min(5, "Minimum of 5 images is required").max(10, "You can upload more than 10 images").required('Required')
    })

    const base64Images = [];

    Object.values(base64).map(async (image) => {
        base64Images.push(await toBase64(image))
    })


    const onSubmit = values => {

        if (hasBank?.length > 0) {

            const formdata = new FormData();

            formdata.append("schoolName", values.schoolName);
            formdata.append("hostelName", capitalize(values.hostelName));
            formdata.append("roomNum", values.roomNumber);

            values.facilities.forEach((facility, index) => {
                formdata.append(`facilities[${index}]`, facility)
            });

            formdata.append("city", values.city);
            formdata.append("address", values.hostelAddress.toLowerCase());
            formdata.append("pay_duration", values.payDuration);
            formdata.append("price", values.roomPrice);

            for (let image of roomImages) {
                formdata.append('images[]', image)
            }

            // Upload percentage value method
            // const uploadOptions = {
            //     onUploadProgress: (progressEvent) => {
            //         const { loaded, total } = progressEvent;
            //         let percentCompleted = Math.round((loaded * 100) / total);

            //         setUploadProgress(percentCompleted);
            //         if (percentCompleted < 100) {
            //             console.log(percentCompleted)
            //         }
            //     }
            // }


            setIsLoading(true);
            setError(null);

            axios.post(`${baseURL}/room/store`, formdata, {
                headers: {
                    'Content-Type': 'application/json,',
                    "Accept": "application/json",
                    'Authorization': `Bearer ${user}`,
                    'Access-Control-Allow-Origin': '*'
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percentCompleted = Math.round((loaded * 100) / total);

                    if (percentCompleted < 100) {
                        setUploadProgress(percentCompleted);
                    }
                }
            }).then((response) => {
                if (response) {
                    setIsLoading(false);
                    setUploadProgress(100);

                    navigate("/upload-successful")
                }
            }).catch((error) => {

                if (error.response.status === 422) {
                    setError("Oops!, all fields are required.")
                }

                if (error.response.status === 409) {
                    setError("You are required to upload minimum of 5 and maximum of 10 images.");
                }
                setIsLoading(false)
            })


        } else {
            setRoomUpload(values);
            setVisible(false);
        }


        // base64Images.forEach((image, index) => {
        //     formdata.append(`images[${index}]`, image)
        // });

        // formdata.forEach(item => console.log(item))

        // for (let obj of formdata) {
        //     console.log(obj);
        // }

    }


    return (
        <>
            {hasBank.length === 0 && <p className='stepper'>Step 1 of 2 </p>}

            {!optionsLoading ? <div className="formbox">
                <p className='heading'>Set to upload your room details</p>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ dirty, isValid, values, setFieldValue, touched, errors }) => (
                        <Form>
                            <FormControl
                                control="select"
                                name="schoolName"
                                type="select"
                                label="School name"
                                options={objectToArr(universityFectch)}
                                setFieldValue={setFieldValue}
                                search
                            />

                            <FormControl
                                control="input"
                                name="hostelName"
                                type="text"
                                label="Hostel name"
                            />

                            <FormControl
                                control="input"
                                name="roomNumber"
                                type="text"
                                label="Room number"
                            />

                            <FormControl
                                control="checkbox"
                                name="facilities"
                                options={objectToArr(facilitiesFectch)}
                                label="Select your room facilities"
                            />

                            <FormControl
                                control="select"
                                name="city"
                                label="City"
                                type="select"
                                options={objectToArr(citiesFetch)}
                                setFieldValue={setFieldValue}
                                search
                            />

                            <FormControl
                                control="input"
                                name="hostelAddress"
                                type="text"
                                label="Hostel address"
                            />

                            {paymentDuration && <div className="radio__con">
                                <p className='radio__heading'>Specify your preferred charging duration.</p>

                                <div className='radio__box'>
                                    {objectToArr(paymentDuration).map((option) => {
                                        return (
                                            <React.Fragment key={option.value}>
                                                <label htmlFor={option.value} className="radio__label">
                                                    <input
                                                        type="radio"
                                                        id={option.value}
                                                        name='payDuration'
                                                        value={option.value}
                                                        checked={paymentChecker === option.value}
                                                        className="radio__input"
                                                        onChange={(e) => {
                                                            setPaymentChecker(e.target.value);
                                                            setFieldValue('payDuration', e.target.value);
                                                        }
                                                        }
                                                    />
                                                    <span className={clsx('radio-check', { 'checked': paymentChecker === option.value })}></span>
                                                    <span className='radio-text'>{option.label}</span>
                                                </label>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </div>}

                            {paymentChecker && <motion.div
                                initial={{ opacity: 0, y: -5 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.1, duration: 0.3 }}>


                                <div className="input__box">

                                    <CurrencyInput
                                        id="roomPrice"
                                        name="roomPrice"
                                        placeholder={paymentChecker === "Daily" ? "Set your daily room price" : "Set your monthly room price"}
                                        decimalsLimit={2}
                                        allowNegativeValue={false}
                                        step={500}
                                        value={values.roomPrice}
                                        onValueChange={(value) => {
                                            setFieldValue('roomPrice', value)
                                        }}
                                        intlConfig={{ locale: 'en-NG', currency: 'NGN' }}
                                        className={clsx('input__field', touched.roomPrice && errors.roomPrice && 'error')}
                                    />
                                    <label htmlFor="roomPrice" className={clsx('input__label')}>{paymentChecker === "Daily" ? "Set your daily room price" : "Set your monthly room price"}</label>

                                    {/* <ErrorMessage name="roomPrice" component={TextError} /> */}
                                    {errors.roomPrice && (<div className={clsx(errors.roomPrice && 'input__error')}>{errors.roomPrice}</div>)}
                                </div>

                            </motion.div>}

                            {hideInputBox && <div className="room-upload__selection">
                                <div className="imagelabel" onClick={handleFileClick}>
                                    <img src={AddImage} alt="rooms" className="imageicon" />
                                    <span className='text'>Add your room images</span>
                                    <span className='text-danger'>Min of 5 and Max of 10 images.</span>
                                    <span className="text-small">(Maximum size allowed is 5MB of PNG, JPEG, JPG)</span>
                                </div>

                                <input
                                    ref={hideFileInput}
                                    type="file"
                                    name="images"
                                    hidden
                                    className={clsx('fileInput', touched && errors && 'error')}
                                    onChange={(e) => {

                                        // Get  selected images
                                        let files = e.target.files;
                                        let result = Array.from(files)
                                        setRoomImages(result);
                                        // Set images field value
                                        setFieldValue('images', result)

                                        setBase64(result)

                                        let selectedFilesArray = Array.from(files);

                                        const imagePreviewArray = selectedFilesArray.map((file) => {
                                            return URL.createObjectURL(file);
                                        })

                                        setSelectedImages(imagePreviewArray);
                                    }}
                                    multiple={true}
                                    accept='image/png, image/jpeg, image/jpg'
                                />
                            </div>}

                            {selectedImages.length > 0 && <div className='mb-5'>
                                <div className="room-upload__imagecon">

                                    {selectedImages.map((image, index) => (
                                        <div className="imagebox" key={image}>
                                            <img src={image} alt="" className='image' />
                                            <div className="counter">{index + 1}</div>
                                            <div className="remove" onClick={() => {
                                                setSelectedImages(selectedImages.filter(e => e !== image))
                                                selectedImages === [] ? setHideInputBox(false) : setHideInputBox(true)
                                            }}><FiTrash2 size="1.3rem" /></div>
                                        </div>
                                    ))}
                                </div>
                                <span className='text-link-danger' onClick={removeAllImages}>Remove all images</span>
                            </div>
                            }

                            {!isLoading && <button type='submit' className='zbtn zbtn-primary-md zbtn-block' disabled={!(dirty && isValid)}>
                                Continue
                            </button>}

                            {isLoading && <button type='submit' className='zbtn zbtn-primary-md zbtn-block' disabled={isLoading}>
                                Uploading ...
                            </button>}

                            {uploadProgress > 0 && isLoading && <div className='progressbar'>
                                <progress value={uploadProgress ? uploadProgress : 0} max="100" className='bar'></progress>
                                <span className={`label ${uploadProgress > 51 ? 'white': ''}`} >{uploadProgress}%</span>
                            </div>}

                            {error && (<AlertBox type='danger' message={error} />)}
                        </Form>
                    )}
                </Formik>

                <p className='terms'>By clicking continue I agree to hostel.ng Terms of service. </p>
            </div> : <ContentLoader
                speed={2}
                width={412}
                height={400}
                viewBox="0 0 412 400"
                backgroundColor="#F1F2F3"
                foregroundColor="#F8F8F9"
                style={{ width: "100%" }}
            >
                <rect x="0" y="0" rx="6" ry="6" width="412" height="55" />
                <rect x="0" y="90" rx="6" ry="6" width="412" height="55" />
                <rect x="0" y="180" rx="6" ry="6" width="412" height="100" />
                <rect x="0" y="320" rx="6" ry="6" width="412" height="45" />
            </ContentLoader>}
        </>

    )
}
