import React from 'react';
import ga4 from 'react-ga4';

function useGAEventsTracker(category = "Event Category") {

    const trackEvents = (action = "action") => {
        ga4.event({
            category: category, 
            action: action
        });
    }

  return trackEvents;
}

export default useGAEventsTracker