import React, { useState } from 'react'
import './App.scss';
import { Route, Routes, useLocation } from 'react-router-dom';

// Pages import
import Homepage from './pages/guest/homepage/Homepage';

import Login from './pages/auth/login/Login';
import SignUp from './pages/auth/signup/SignUp';
import ResetPassword from './pages/auth/reset-password/ResetPassword';
import ForgotPassword from './pages/auth/forgot-password/ForgotPassword';
import VerifyAccount from './pages/auth/verify-account/VerifyAccount';

import HelpCenter from './pages/guest/help-center/HelpCenter';
import Profile from './pages/guest/profile/Profile';
import ReservationSummary from './pages/guest/reservation-summary/ReservationSummary';
import ReservedRooms from './pages/guest/reserved-rooms/ReservedRooms';
import RoomDetails from './pages/guest/room-details/RoomDetails';
import RoomListing from './pages/guest/room-listing/RoomListing';

import BecomeHost from './pages/host/become-host/BecomeHost';
import RentedRooms from './pages/host/rented-rooms/RentedRooms';
import RoomUpload from './pages/host/room-upload/RoomUpload';

import Faqs from './pages/generic/Faqs';
import PrivacyPolicy from './pages/generic/PrivacyPolicy';
import TermsOfUse from './pages/generic/TermsOfUse';
import RoomUploadSuccess from './pages/host/room-upload/RoomUploadSuccess';
import ScrollToTop from './hooks/ScrollToTop';
import ResetPasswordSuccess from './pages/auth/reset-password/ResetPasswordSuccess';
import Verified from './pages/auth/verify-account/Verified';
import Insurance from './pages/generic/Insurance';
import RequireAuth from './pages/auth/require-auth/RequireAuth';
import ReservationSuccess from './pages/guest/room-details/ReservationSuccess';
import { AnimatePresence } from 'framer-motion';
import LoginMerchant from './pages/merchant/auth/login/LoginMerchant';
import SignUpMerchant from './pages/merchant/auth/signup/SignUpMerchant';
import MerchantDashboard from './pages/merchant/dashboard/MerchantDashboard';
import MerchantProfile from './pages/merchant/profile/MerchantProfile';
import MerchantForgetPassword from './pages/merchant/auth/forget-password/MerchantForgetPassword';
import MerchantVerifyAccount from './pages/merchant/auth/verify-account/MerchantVerifyAccount';
import MerchantResetPassword from './pages/merchant/auth/reset-password/MerchantResetPassword';
import PageNotFound from './pages/generic/PageNotFound';
import HostRoomDetails from './pages/host/rented-rooms/HostRoomDetails';
import MerchantHelpCenter from './pages/merchant/help-center/MerchantHelpCenter';
import MerchantVerified from './pages/merchant/auth/verify-account/MerchantVerified';
import Questions from './pages/agents/questions/Questions';
import { useIdleTimer } from 'react-idle-timer'
import { useMerchantLogOut } from './hooks/useMerchantLogOut';
import GetRide from './pages/generic/GetRide';
import DearSanta from './pages/generic/santa/dear-santa';

function App() {

  const location = useLocation();
  const { logOut: merchantLogOut } = useMerchantLogOut();

  const [count, setCount] = useState(0)
  // const [remaining, setRemaining] = useState(0)

  const theMerchant = localStorage.getItem("user_merchant");

  const onIdle = () => {
    // console.log("You have been idle on the platform");
    if (theMerchant) {
      merchantLogOut();
    }
  }

  // const onActive = () => {
  //   // console.log("You are active on the platform");
  // }

  // const onAction = () => {
  //   setCount(count + 1)
  // }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    // onActive,
    // onAction,
    // timeout: 10000
    timeout: 1000 * 60 * 60 * 24
  })

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setRemaining(Math.ceil(getRemainingTime() / 1000))
  //   }, 1000)

  //   return ()=> {
  //     clearInterval(interval)
  //   }
  // }, [])

  return (
    <>
      <ScrollToTop />
      <AnimatePresence exitBeforeEnter>

        <Routes key={location.pathname}>

          <Route path='/' element={<Homepage />}></Route>

          <Route path='/home' element={<Homepage />}></Route>

          {/* Aunthentication Routes */}

          <Route path='/login' element={<Login />}></Route>
          {/* <Route path='/login' element={!user ? <Login /> : <Navigate to="/" /> }></Route> */}

          <Route path='/signup' element={<SignUp />}></Route>

          <Route path='/verify-account' element={<VerifyAccount />}></Route>

          <Route path='/verification/:code' element={<Verified />}></Route>

          <Route path='/reset-password' element={<ResetPassword />}></Route>

          <Route path='/reset-password-success' element={<ResetPasswordSuccess />}></Route>

          <Route path='/forgot-password' element={<ForgotPassword />}></Route>


          {/* Guest routes */}

          <Route element={<RequireAuth />}>

            <Route path='/help-center' element={<HelpCenter />} />

            <Route path='/profile' element={<Profile />} />

            <Route path='/reservation-summary' element={<ReservationSummary />} />

            <Route path='/reserved-rooms' element={<ReservedRooms />} />

            <Route path='/payment-successful' element={<ReservationSuccess />} />
          </Route>

          <Route path='/room-details/:id' element={<RoomDetails />}></Route>

          <Route path='/rooms' element={<RoomListing />}></Route>


          {/* Host routes */}
          <Route path='/host' element={<BecomeHost />}></Route>

          <Route element={<RequireAuth />}>

            <Route path='/my-rooms' element={<RentedRooms />} />

            <Route path='/room-upload' element={<RoomUpload />} />

            <Route path='/upload-successful' element={<RoomUploadSuccess />} />

            <Route path='/host-room-details/:id' element={<HostRoomDetails />}></Route>

          </Route>


          {/* Generic routes */}
          <Route path='/faqs' element={<Faqs />} />

          <Route path='/privacy-policy' element={<PrivacyPolicy />} />

          <Route path='/terms' element={<TermsOfUse />} />

          <Route path='/insurance' element={<Insurance />} />

          <Route path='/book-travel-bus' element={<GetRide />} />

          {/* <Route path='/dear-santa' element={<DearSanta />} /> */}

          <Route path='/pagenotfound' element={<PageNotFound />} />

          {/* Merchant Routing */}

          <Route path='/merchant/' element={<LoginMerchant />} />
          <Route path='/merchant/signup' element={<SignUpMerchant />} />
          <Route path='/merchant/forgot-password' element={<MerchantForgetPassword />} />
          <Route path='/merchant/reset-password' element={<MerchantResetPassword />} />
          <Route path='/merchant/verify-account' element={<MerchantVerifyAccount />} />
          <Route path='/merchant/verification/:code' element={<MerchantVerified />} />
          <Route path='/merchant/dashboard' element={<MerchantDashboard />} />
          <Route path='/merchant/profile' element={<MerchantProfile />} />
          <Route path='/merchant/help' element={<MerchantHelpCenter />} />

          {/* Agent routing */}
          <Route path='/questions' element={<Questions />} />

          <Route path='*' element={<PageNotFound />} />

        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
