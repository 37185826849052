import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async';
import MainLayout from '../../../components/layouts/users/MainLayout'
import useAnalytics from '../../../hooks/useAnalytics';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import AccountDetails from './AccountDetails';
import RoomUploadForm from './RoomUploadForm'

export default function RoomUpload() {
  // Use Google Analytics
  useAnalytics()

  const [visible, setVisible] = useState(true);
  const [roomUpload, setRoomUpload] = useState(null);
  
  return (
    <AnimateRoute>

      <Helmet>
        <title>Lease your room on Hostel.ng.</title>
        <meta name="description" content="Start earning from your existing room on Hostel.ng." />
        <link rel="canonical" href="/room-upload" />
      </Helmet>

      <div className='room-upload'>
        <MainLayout>
          <div className="container">
            <div className="room-upload__content">

              {/* Room upload form (step 1)*/}
              {visible && <RoomUploadForm visible={visible} setVisible={setVisible} setRoomUpload={setRoomUpload} roomUpload={roomUpload} />}

              {/* Host account details form (step 2)*/}

              {!visible && <AccountDetails setVisible={setVisible} roomUpload={roomUpload} />}

            </div>
          </div>
        </MainLayout>
      </div>
    </AnimateRoute>
  )
}