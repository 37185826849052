import React from 'react'
import HostelLogo from "../../../assets/images/svg/hostelng_logo.svg"

function AgentHeader() {
    return (
        <div className='agents__navigation'>
            <div className="container">
                <div className="logobox">
                    <img src={HostelLogo} alt="hostel.ng logo" className='logo' />
                </div>
            </div>
        </div>
    )
}

export default AgentHeader
