import { createContext, useEffect, useReducer } from "react";


export const MerchantAuthContext = createContext();

// Initial state
const initialState = {
    user_merchant: null,
}

// MerchantAuth reducer that determines the action to be taken
export const merchantAuthReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { user_merchant: action.payload };

        case 'LOGOUT':
            return { user_merchant: null }

        default:
            return state;
    }
}

// MerchantAuthContext provider
export const MerchantAuthContextProvider = ({children}) => {
    
    const [state, dispatch] = useReducer(merchantAuthReducer, initialState);

    // Check if user_merchant token exist in local storage then update global state 
    useEffect(() => {
        const user_merchant = JSON.parse(localStorage.getItem('user_merchant'));

        if (user_merchant) {
            dispatch({
                type: 'LOGIN',
                payload: user_merchant
            })
        }

    }, []);

    return (
        <MerchantAuthContext.Provider value={{ ...state, dispatch }}>
            {children}
        </MerchantAuthContext.Provider>
    )
}