import axios from 'axios';
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup'
import FormControl from '../../../components/forms/FormControl';
import { AlertBox } from '../../../components/molecules/alertbox/AlertBox';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useQuery } from '@tanstack/react-query';
import ContentLoader from 'react-content-loader';
import ScrollToTop from '../../../hooks/ScrollToTop';

export default function AccountDetails({ roomUpload }) {

    const baseURL = process.env.REACT_APP_BASEURL;
    const { user } = useAuthContext();

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingRooms, setIsLoadingRooms] = useState(false);
    const [error, setError] = useState(null);
    const [errorRooms, setErrorRooms] = useState(null);

    const [roomDone, setRoomDone] = useState(false);
    const [accountSaved, setAccountSaved] = useState(false);

    const [uploadProgress, setUploadProgress] = useState(0);



    // Get all bank list
    const { data: getBankList, isError, isLoading: loadingBanks } = useQuery(['GetBankList'], () => {
        return axios.get(`${baseURL}/user/banks`, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((res) => res.data)
    });

    // Capitalize text
    const capitalize = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    // convert object to array
    const objectToArr = (obj) => {
        const data = Object.entries(obj).map(item => item);
        const dataObject = data.map(([value, label]) => ({ value, label }));
        return dataObject;
    }

    // Save bank details
    const saveBankDetails = async (details) => {

        setIsLoading(true);
        setError(null);
        await axios.post(`${baseURL}/user/addBank`, details, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((response) => {

            if (response) {
                setAccountSaved(true);
                setIsLoading(false);
            }
        }).catch((error) => {

            if (error.response.status === 422) {
                setError("Oops!, all fields are required.")
            }
            setIsLoading(false);
        })
    }

    // Upload room details

    const uploadRoomDetails = async () => {

        const formdata = new FormData();

        formdata.append("schoolName", roomUpload?.schoolName);
        formdata.append("hostelName", capitalize(roomUpload?.hostelName));
        formdata.append("roomNum", roomUpload?.roomNumber);

        roomUpload?.facilities.forEach((facility, index) => {
            formdata.append(`facilities[${index}]`, facility)
        });

        formdata.append("city", roomUpload?.city);
        formdata.append("address", roomUpload?.hostelAddress.toLowerCase());
        formdata.append("pay_duration", roomUpload?.payDuration);
        formdata.append("price", roomUpload?.roomPrice);

        for (let image of roomUpload?.images) {
            formdata.append('images[]', image)
        }

        setIsLoadingRooms(true);
        setError(null);

        await axios.post(`${baseURL}/room/store`, formdata, {
            headers: {
                'Content-Type': 'application/json,',
                "Accept": "application/json",
                'Authorization': `Bearer ${user}`,
                'Access-Control-Allow-Origin': '*'
            },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percentCompleted = Math.round((loaded * 100) / total);

                if (percentCompleted < 100) {
                    setUploadProgress(percentCompleted);
                }
            }
        }).then((response) => {
            if (response) {
                setIsLoadingRooms(false);
                setRoomDone(true);
                setUploadProgress(100);
            }
        }).catch((error) => {

            if (error.response.status === 422) {
                setErrorRooms("Oops!, all fields are required.")
            }

            if (error.response.status === 409) {
                setErrorRooms("You are required to upload minimum of 5 and maximum of 10 images.");
            }
            setIsLoadingRooms(false)
        })
    }

    const initialValues = {
        accountName: '',
        bankName: '',
        accountNumber: ''
    }

    const validationSchema = Yup.object({
        bankName: Yup.string().required('Required'),
        accountNumber: Yup.string().min(10, "Account number must be 10 characters").required('Required'),
        accountName: Yup.string().required('Required'),
    })

    const onSubmit = values => {

        const addBankDetails = {
            "bankName": values.bankName,
            "accountNum": values.accountNumber.toString(),
            "accountName": values.accountName,
        }

        uploadRoomDetails();
        saveBankDetails(addBankDetails);

    }

    if (roomDone && accountSaved) {
        navigate("/upload-successful");
    }

    return (
        <>
            <ScrollToTop />
            {/* <span onClick={() => setVisible(true)}>back</span> */}
            <p className='stepper'>Step 2 of 2</p>

            {getBankList ? <div className="formbox">
                <p className='heading'>Set to upload your room details</p>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {formik => (
                        <Form>

                            <FormControl
                                control="input"
                                name="accountName"
                                type="text"
                                label="Account name"
                            />

                            <FormControl
                                control="select"
                                name="bankName"
                                type="select"
                                label="Select bank name"
                                options={objectToArr(getBankList)}
                                setFieldValue={formik.setFieldValue}
                                search
                            />

                            <FormControl
                                control="inputNumbers"
                                name="accountNumber"
                                type="text"
                                label="Account number"
                                maxLength="10"
                            />

                            {!isLoadingRooms && <button type='submit' className='zbtn zbtn-primary-md zbtn-block' disabled={!(formik.dirty && formik.isValid)}>Save</button>}
                            {isLoadingRooms && <button type='submit' className='zbtn zbtn-primary-md zbtn-block' disabled={isLoadingRooms === true}>Saving...</button>}

                            {uploadProgress > 0 && <div className='progressbar'>
                                <progress value={uploadProgress ? uploadProgress : 0} max="100" className='bar'></progress>
                                <span className={`label ${uploadProgress > 51 ? 'white' : ''}`} >{uploadProgress}%</span>
                            </div>}

                            {error && (<AlertBox type='danger' message={error} />)}
                            {errorRooms && (<AlertBox type='danger' message={errorRooms} />)}
                        </Form>
                    )}
                </Formik>
            </div> : <div className='formbox'>
                <ContentLoader
                    speed={2}
                    width={282}
                    height={400}
                    viewBox="0 0 282 400"
                    backgroundColor="#F1F2F3"
                    foregroundColor="#F8F8F9"
                    style={{ width: "100%" }}

                >
                    <rect x="0" y="0" rx="2" ry="2" width="412" height="55" />
                    <rect x="0" y="90" rx="2" ry="2" width="412" height="55" />
                    <rect x="0" y="180" rx="2" ry="2" width="412" height="55" />
                </ContentLoader></div>}
        </>
    )
}
