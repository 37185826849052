import React, { useEffect, useState } from 'react'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import FormControl from '../../../components/forms/FormControl'
import { useLocation, useNavigate } from 'react-router-dom'
import dateFormat from 'dateformat';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { usePaystackPayment } from 'react-paystack';
import { NumericFormat } from 'react-number-format';
import Naira from '../../../assets/images/svg/naira-icon.svg';
import RoomInUse from '../../../assets/images/svg/form-empty-state.svg';
import { AlertBox } from '../../../components/molecules/alertbox/AlertBox';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import { addDays, differenceInDays } from 'date-fns';
import CircularPreloader from '../../../shared/components/preloader/CircularPreloader'
import { useUserDetailsContext } from '../../../hooks/useUserDetailsContext'

export default function Reservationform({ price, roomId, status, roomUserId, payDuration, disableThisDates }) {

  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuthContext();
  const {user_details}= useUserDetailsContext();

  const baseURL = process.env.REACT_APP_BASEURL;
  const PSPUBKEY = process.env.REACT_APP_PSPUBKEY;

  // Get details from storage
  const userEmail = user_details?.email;
  const userID = user_details?.id;
  const hasB = user_details?.banks;

  const [isLoading, setIsLoading] = useState(false);

  const checkInTimeOptions = [
    { label: "6 am", value: "06am" },
    { label: "7 am", value: "07am" },
    { label: "8 am", value: "08am" },
    { label: "9 am", value: "09am" },
    { label: "10 am", value: "10am" },
    { label: "11 am", value: "11am" },
    { label: "12 noon", value: "12pm" },
    { label: "1 pm", value: "01pm" },
    { label: "2 pm", value: "02pm" },
    { label: "3 pm", value: "03pm" },
    { label: "4 pm", value: "04pm" },
    { label: "5 pm", value: "05pm" },
    { label: "6 pm", value: "06pm" },
    { label: "7 pm", value: "07pm" },
    { label: "8 pm", value: "08pm" },
    { label: "9 pm", value: "09pm" },
    { label: "10 pm", value: "10pm" },
  ];

  // Formik values is here
  const [formikValues, setFormikValues] = useState({});

  useEffect(() => {

  }, [formikValues]);


  const getCheckInDate = new Date(formikValues.checkInDate); // checkin date here.
  const getCheckOutDate = new Date(formikValues.checkOutDate); // checkout date here.


  const stayDuration = differenceInDays(getCheckOutDate, getCheckInDate); // Difference in days.

  const serviceCharge = Math.ceil(price * stayDuration * 0.2);  // Hostel.ng service charge.
  const totalAmount = serviceCharge + (price * stayDuration);  // Total amount.
  const amountPayable = totalAmount * 100;                 // Total Payable amount.

  const booking_reference = "hostel-ng-" + (new Date()).getTime().toString() + Math.floor(Math.random() * 1000); // Booking reference number.

  const config = {
    reference: booking_reference,
    email: userEmail,
    amount: amountPayable,
    publicKey: PSPUBKEY,
  };

  // Function called if payment & booking of room are successful.
  const onSuccess = (reference) => {

    // Booking details to post
    const bookingDetails = {
      "amount": totalAmount,
      "tranzID": booking_reference,
      "duration": stayDuration > 1 ? `${stayDuration} days` : `${stayDuration} day`,
      "checkInTime": formikValues.checkInTime,
      "checkInDate": dateFormat(getCheckInDate, "mmm dd, yyyy"),
      "checkOutDate": dateFormat(getCheckOutDate, "mmm dd, yyyy")
    }

    // Reserve room api call 
    setIsLoading(true);
    axios.post(`${baseURL}/room/book/${roomId}`, bookingDetails, {
      headers: {
        'Authorization': `Bearer ${user}`
      }
    }).then((response) => {
      if (response) {
        setIsLoading(false);
        // Goto success page
        navigate('/payment-successful');
      }
    }).catch((error) => {
      setIsLoading(false);
    })

  };

  // This is called on close of paystack payment modal.
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.


  }

  // Initialize paystack payment.
  const intializePayment = usePaystackPayment(config);

  const initialValues = {
    checkInDate: '',
    checkOutDate: '',
    checkInTime: ''
  }

  const validationSchema = Yup.object({
    checkInDate: Yup.string().required('Required'),
    checkOutDate: Yup.string().required('Required'),
    checkInTime: Yup.string().required('Required')
  })

  const onSubmit = values => {
    // Initiate Paystack payment modal.
    intializePayment(onSuccess, onClose);

  }

  const disabledDates = [];

  // Combine 2 dimensional array into 1 array.
  const flatten = arr => arr.reduce(
    (a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []
  );

  const flattenDates = flatten(disableThisDates);
  flattenDates?.map(date => disabledDates.push(new Date(date)));

  return (
    <>
      {status === 'available' ? <div className='dates-form'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          innerRef={(formikActions) => (formikActions ? setFormikValues(formikActions.values) : setFormikValues({}))}
        >
          {formik => (
            <Form>

              <FormControl
                control="date"
                name="checkInDate"
                type="date"
                label="Check-In date"
                disableFloatingLabel={true}
                setFieldValue={formik.setFieldValue}
                minDate={ addDays (new Date(), 1)}
                disabledDates={disabledDates}
                readOnly
              />

              <FormControl
                control="date"
                name="checkOutDate"
                type="date"
                label="Check-Out date"
                disableFloatingLabel={true}
                setFieldValue={formik.setFieldValue}
                minDate={formik.values.checkInDate ? addDays( new Date(formik.values.checkInDate), 1 ) : addDays (new Date(), 1)}
                disabledDates={disabledDates}
                disabled="disabled"
                readOnly
              />

              <FormControl
                control="select"
                name="checkInTime"
                type="select"
                label="Check-In Time"
                options={checkInTimeOptions}
                disableFloatingLabel={true}
                setFieldValue={formik.setFieldValue}
              />

              {userID === roomUserId && (<AlertBox type='warning' message="You are not allowed to reserve your room as a host." />)}

              {user && userID !== roomUserId && <button type='submit' className='zbtn zbtn-primary-md zbtn-block' disabled={totalAmount < 1} >Reserve Room</button>}
              {!user && <button type='button' onClick={() => navigate('/login', { state: { previousUrl: location.pathname } })} className='zbtn zbtn-primary-md zbtn-block'>Login In</button>}

              {serviceCharge > 1 && <div className="payment-reviewbox">
                <div className="payment-review">
                  <span>Room reservation </span>
                  <span>
                    <img src={Naira} alt="naira" className='naira' />&nbsp;
                    {<NumericFormat value={price} displayType={'text'} thousandSeparator={true} />} {stayDuration > 1 ? ` x ${stayDuration} days` : ''}
                  </span>
                </div>

                <div className="payment-review">
                  <span>Service fee</span>
                  <span>
                    <img src={Naira} alt="naira symbol" className='naira' />&nbsp;
                    {serviceCharge ?
                      <NumericFormat value={serviceCharge} displayType={'text'} thousandSeparator={true} /> :
                      <NumericFormat value={Math.ceil(price * 0.2)} displayType={'text'} thousandSeparator={true} />
                    }
                  </span>
                </div>

                <div className="payment-review">
                  <span>Total</span>
                  <span>
                    <img src={Naira} alt="naira symbol" />&nbsp;
                    {totalAmount ?
                      <NumericFormat value={totalAmount} displayType={'text'} thousandSeparator={true} /> :
                      <NumericFormat value={price + Math.ceil(price * 0.2)} displayType={'text'} thousandSeparator={true} />
                    }
                  </span>
                </div>
              </div>}

              {/* Display this error box if checkout date is lesser than checkin date */}
              {totalAmount < 1 && (<AlertBox type='warning' message="Kindly select your check-in date and check-out date properly." />)}

            </Form>
          )}
        </Formik>
      </div> : <div className='emptybox'>

        {status ? <>
          <img src={RoomInUse} alt='' />
          <h5 className="title">Room currently in use</h5>
          <p className="text">
            We've got a fast finger here, click on the button below, let's find you another room. 😉
          </p>
          <button onClick={() => navigate('/rooms')} className="zbtn zbtn-primary-outline-def">Explore more rooms</button>
        </> : <ContentLoader
          speed={2}
          width={176}
          height={35}
          viewBox="0 0 176 35"
          backgroundColor="#F1F2F3"
          foregroundColor="#F8F8F9"
        >
          <rect x="0" y="0" rx="2" ry="2" width="176" height="35" />
        </ContentLoader>}

      </div>

      }

      {isLoading && <CircularPreloader />}

    </>

  )
}
