import React from 'react'
import { useState, forwardRef, useImperativeHandle } from 'react'
import { FiCheck, FiInfo, FiXCircle } from 'react-icons/fi'

const Toast = forwardRef(({ type, message }, ref)=> {

    const [showToast, setShowToast] = useState(false);

    useImperativeHandle(ref, () => ({
        show(){
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 4000);
        },
    }))

    switch (type) {
        case 'success':
            return (
                <div className={`toaster toaster-success ${showToast ? 'show' : 'hide'}`}>
                    <div className="iconbox">
                        <FiCheck className="" size="1.5rem" />
                    </div>
                    <div className='toaster__content'>
                        <span className='title'>{type}</span>
                        <p className='message'>{message}</p>
                    </div>

                    <FiXCircle className='cancel' size="1.4rem" onClick={() => setShowToast(false)} />
                </div>
            )

        case 'warning':
            return (
                <div className='toaster toaster-warning'>
                    <div className="iconbox">
                        <FiInfo className="" size="1.5rem" />
                    </div>
                    <div className='toaster__content'>
                        <span className='title'>{type}</span>
                        <p className='message'>{message}</p>
                    </div>

                    <FiXCircle className='cancel' size="1.4rem" onClick={() => setShowToast(false)} />
                </div>
            )

        case 'info':
            return (
                <div className='toaster toaster-info'>
                    <div className="iconbox">
                        <FiInfo className="" size="1.5rem" />
                    </div>
                    <div className='toaster__content'>
                        <span className='title'>{type}</span>
                        <p className='message'>{message}</p>
                    </div>

                    <FiXCircle className='cancel' size="1.4rem" onClick={() => setShowToast(false)} />
                </div>
            )

        case 'error':
            return (
                <div className='toaster toaster-danger'>
                    <div className="iconbox">
                        <FiXCircle className="" size="1.5rem" />
                    </div>
                    <div className='toaster__content'>
                        <span className='title'>{type}</span>
                        <p className='message'>{message}</p>
                    </div>

                    <FiXCircle className='cancel' size="1.4rem" onClick={() => setShowToast(false)} />
                </div>
            )

        default:
            return null;
    }

})

export default Toast