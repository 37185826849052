import React from 'react'

function CircularPreloader({ small, nocover }) {
  return (
    <>
      {nocover ? (<div className={small ? "lds-ring-small flex" : "lds-ring flex"}><div></div><div></div><div></div><div></div></div>) : (<div className='preloadercon'>
        <div className={small ? "lds-ring-small flex" : "lds-ring flex"}><div></div><div></div><div></div><div></div></div>
      </div>)}
    </>
  )
}

export default CircularPreloader