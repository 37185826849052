import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import MainLayout from '../../../components/layouts/users/MainLayout';
import { NumericFormat, NumberFormatBase } from 'react-number-format';
import dateFormat from 'dateformat';
import ContentLoader from "react-content-loader";

import {
  FiShare2,
  FiBookmark,
  FiCamera,
  FiCheckCircle,
  FiStar,
  FiX,
  FiChevronLeft,
  FiChevronRight,
  FiCopy,
  FiInfo
} from "react-icons/fi";
import { AiOutlineWhatsApp } from "react-icons/ai";
import Naira from '../../../assets/images/svg/naira-icon.svg';

import Reservationform from '../../guest/room-details/Reservationform';
import clsx from 'clsx';
import useAnalytics from '../../../hooks/useAnalytics';
import axios from 'axios';
import Facilities from '../../guest/room-details/Facilities';
import autoAnimate from '@formkit/auto-animate';
import Toast from '../../../components/molecules/toast/Toast';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';
import MonthlyReservationForm from '../../guest/room-details/MonthlyReservationForm';
import { Alert } from 'bootstrap';
import { AlertBox } from '../../../components/molecules/alertbox/AlertBox';

function HostRoomDetails() {
  // Use Google Analytics
  useAnalytics();

  const { id } = useParams();

  const baseURL = process.env.REACT_APP_BASEURL;
  const resourceURL = process.env.REACT_APP_RESOURCE_URL;
  const [roomData, setRoomData] = useState(null);
  const [roomsNearBy, setRoomsNearBy] = useState(null);
  const [roomImages, setRoomImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [shareToggle, setShareToggle] = useState(false);

  const shareRef = useRef(null);
  const toastRef = useRef(null);

  useEffect(() => {
    getRoomInfo();
  }, [baseURL, id])

  // Get user details
  const getRoomInfo = () => {
    setIsLoading(true);

    axios.get(`${baseURL}/room/${id}`
    ).then((response) => {
      setRoomData(response.data.RoomData);
      setRoomsNearBy(response.data.RoomsNearBy);
      setRoomImages(response.data.RoomData.images);
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
    })
  }

  // Share button toggle animation
  useEffect(() => {
    if (shareRef.current) {
      autoAnimate(shareRef.current);
    }
  }, [shareRef])

  // handle share button toggle
  const handleShareToggle = () => {
    setShareToggle(!shareToggle);
  }

  // handle copy to clipboard
  const copyLink = () => {
    setShareToggle(false);
    toastRef.current.show();
    navigator.clipboard.writeText(window.location.href);
  }

  // handle share via whatsapp
  const whatsAppLink = () => {
    setShareToggle(false);
    const text = 'Check out this link!';
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text)}%20${encodeURIComponent(window.location.href)}`;
    window.open(whatsappUrl, '_blank');
  }


  // Handle modal visibility
  const handleModal = (getIndex) => {

    setSlideNumber(getIndex);
    setOpenModal(true);
  }

  // Handle  view all photos button
  const viewAllPhotos = event => {
    event.stopPropagation();
    setSlideNumber(0);
    setOpenModal(true);

  }

  // 

  // Handle image slider
  const handleSlider = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      // newSlideNumber = slideNumber === 0 ? imageStack.length : slideNumber - 1;
      newSlideNumber = slideNumber - 1;
    } else if (direction === "r") {
      // newSlideNumber = slideNumber === imageStack.length ? 0 : slideNumber + 1;
      newSlideNumber = slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  }

  // Capitalize text
  const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const imagePlaceholder = [
    { x: 0, y: 0, width: 400, height: 355 },
    { x: 0, y: 0, width: 300, height: 170 },
    { x: 0, y: 0, width: 300, height: 170 },
    { x: 0, y: 0, width: 300, height: 170 },
    { x: 0, y: 0, width: 300, height: 170 },
  ];

  // content placeholder list
  const myLoaderList = [1, 2, 3, 4];

  // handle slider wrapper
  const sliderWrapper = event => {
    event.stopPropagation();
  }

  return (
    // <>{roomData ? 
    <AnimateRoute>

      <Helmet>
        <title>{`${roomData ? roomData?.name + '-' : ''}  Affordable room to rent ${roomData ? 'in ' + roomData?.city?.name : ''}.`}</title>
        <meta name="description" content="Find the best hostel rooms for your travel needs." />
      </Helmet>

      <div className='room-details'>
        <MainLayout>
          <div className="container">

            {/* Room information */}
            <div className="room-info">

              <div className="room-info__name">
                <h3 className='name'>
                  {roomData && roomData.name ? capitalize(roomData.name) : <ContentLoader
                    speed={2}
                    width={382}
                    height={29}
                    viewBox="0 0 382 29"
                    backgroundColor="#F1F2F3"
                    foregroundColor="#F8F8F9"
                    style={{ width: "100%" }}
                  >
                    <rect x="0" y="0" rx="2" ry="2" width="382" height="29" />
                  </ContentLoader>}
                </h3>
                <span className='location'>
                  {roomData && roomData.address ? `${roomData.address} , ${roomData.city.name}` : <ContentLoader
                    speed={2}
                    width={200}
                    height={21}
                    viewBox="0 0 200 21"
                    backgroundColor="#F1F2F3"
                    foregroundColor="#F8F8F9"
                    style={{ width: "100%" }}
                  >
                    <rect x="0" y="0" rx="2" ry="2" width="200" height="21" />
                  </ContentLoader>}
                </span>
              </div>

              <div className="room-info__price">
                <p className='value'>
                  {roomData && roomData.price ?
                    (<>
                      <img src={Naira} alt="naira" className='Naira' /> {<NumericFormat value={roomData.price} displayType={'text'} thousandSeparator={true} />}
                      <span className='day'>/{roomData.pay_duration === "Daily" ? "day" : "month"}</span>
                    </>
                    ) : <ContentLoader
                      speed={2}
                      width={134}
                      height={34}
                      viewBox="0 0 134 34"
                      backgroundColor="#F1F2F3"
                      foregroundColor="#F8F8F9"
                      style={{ width: "100%" }}
                    >
                      <rect x="0" y="0" rx="2" ry="2" width="134" height="34" />
                    </ContentLoader>}

                </p>

                <div className='controls' ref={shareRef}>
                  <div>
                    <span className='iconcon'><FiShare2 className='icon' size="2rem" onClick={handleShareToggle} /></span>
                    {/* <span className='iconcon'><FiBookmark className='icon' size="2rem" /></span> */}
                  </div>

                  {shareToggle && <div className="sharebox">
                    <FiCopy className='icon' size="2rem" onClick={copyLink} />
                    <AiOutlineWhatsApp className='icon' size="2rem" onClick={whatsAppLink} />
                  </div>}
                </div>
              </div>
            </div>

            {/* Room images */}

            <div className="room-images">

              {
                roomImages ? roomImages.slice(0, 5).map((image, index) => {
                  return (
                    <div className="imagebox" key={index} onClick={() => handleModal(index)}>
                      <img src={`${resourceURL}${image.name}`} alt="" className='image' />
                      {index === 4 && <button type="button" className='image__counter' onClick={viewAllPhotos}>
                        <FiCamera size="2rem" className='icon' /> {roomImages.length} Photos
                      </button>}
                    </div>
                  )
                }) : (
                  <>
                    {imagePlaceholder.map((holder, index) => {
                      return (
                        <div className="imagebox" key={index}>
                          <ContentLoader
                            speed={2}
                            width={`${holder.width}`}
                            height={holder.height}
                            viewBox={`${holder.x} ${holder.y} ${holder.width} ${holder.height}`}
                            backgroundColor="#F1F2F3"
                            foregroundColor="#F8F8F9"
                            className='image'
                            style={{ width: "100%" }}
                          >
                            <rect x={holder.x} y={holder.y} rx="2" ry="2" width="382" height={holder.height} />
                          </ContentLoader>
                        </div>
                      )
                    })}

                  </>)
              }

            </div>

            {/* Room details section */}

            <div className="room-details">
              <div className="room-details__main">

                {/* Room host */}
                <div className="host">

                  <div className="host__details">
                    <div className="picture">
                      {roomData && roomData.host_data.avatar ?
                        <img src={`${roomData && resourceURL}/${roomData && roomData.host_data.avatar}`} className='image' alt='' /> :
                        <ContentLoader
                          speed={2}
                          width={50}
                          height={50}
                          viewBox="0 0 50 50"
                          backgroundColor="#F1F2F3"
                          foregroundColor="#F8F8F9"
                          style={{ width: "100%" }}
                        >
                          <circle cx="5" cy="6" r="100" />
                        </ContentLoader>
                      }
                    </div>
                    <div className="text">
                      <div className="username">
                        {
                          roomData ?
                            `Room hosted by ${roomData && capitalize(roomData.host_data.lName)} ${roomData && capitalize(roomData.host_data.fName)}` :
                            <ContentLoader
                              speed={2}
                              width={250}
                              height={21}
                              viewBox="0 0 250 21"
                              backgroundColor="#F1F2F3"
                              foregroundColor="#F8F8F9"
                              style={{ width: "100%" }}
                            >
                              <rect x="0" y="0" rx="2" ry="2" width="250" height="21" />
                            </ContentLoader>}
                      </div>
                      <span className="membership">{roomData ?
                        `Member since ${roomData && dateFormat(roomData.host_data.created_at, "mmmm, yyyy")}` :
                        <ContentLoader
                          speed={2}
                          width={172}
                          height={17}
                          viewBox="0 0 172 17"
                          backgroundColor="#F1F2F3"
                          foregroundColor="#F8F8F9"
                          style={{ width: "100%" }}
                        >
                          <rect x="0" y="0" rx="2" ry="2" width="172" height="17" />
                        </ContentLoader>}
                      </span>
                    </div>
                  </div>

                  {/* <div className="host__reviews">
                  <FiStar size="1.8rem" className="icon" />
                  <span className='user-reviews'>No reviews yet</span>
                  
                </div> */}


                  {roomData?.hostel_state === "approved" && <div className="host__room-status available">approved</div>}
                  {roomData?.hostel_state === "pending" && <div className="host__room-status inuse">pending approval</div>}


                </div>

                {/* Hostel Amenities */}
                <div className="amenities">
                  <h4 className="section__badge section__badge--success">Amenities</h4>

                  <div className="amenitiesbox">
                    <Facilities facilities={roomData && roomData.facilities.split(',').sort()} />
                  </div>

                  <div className="seemore">
                    {/* <button type='button' className='btnlink'>Explore all amenities</button> */}
                  </div>

                </div>

                {/* Hostel Rules */}
                <div className="rules">
                  <h4 className="section__badge section__badge--success">Room Bookings</h4>

                </div>

              </div>

              <div className="room-details__payment">
                <div className="paymentbox">
                  {roomData?.pay_duration === "Monthly" && <div className="infobox">
                    <FiInfo />
                    <span>This room can only be reserved monthly</span>
                  </div>}
                  {roomData && roomData.status === "available" && <><div className="price">{roomData ?
                    (<>
                      <img src={Naira} alt="naira" />&nbsp;
                      {roomData && <NumericFormat value={roomData.price} displayType={'text'} thousandSeparator={true} />}
                      <span className='day'>/{roomData.pay_duration === "Daily" ? "day" : "month"}</span>
                    </>
                    ) : <ContentLoader
                      speed={2}
                      width={176}
                      height={35}
                      viewBox="0 0 176 35"
                      backgroundColor="#F1F2F3"
                      foregroundColor="#F8F8F9"
                    >
                      <rect x="0" y="0" rx="2" ry="2" width="176" height="35" />
                    </ContentLoader>}
                  </div>

                    <p className='dates'>Select check-in and checkout dates </p> </>}

                  {/* checkin and checkout dates Forms */}

                  {roomData?.pay_duration === "Daily" ? <Reservationform
                    status={roomData?.status}
                    price={roomData?.price}
                    roomId={id}
                    roomUserId={roomData?.user_id}
                    payDuration={roomData?.pay_duration}
                  /> : <MonthlyReservationForm
                    status={roomData?.status}
                    price={roomData?.price}
                    roomId={id}
                    roomUserId={roomData?.user_id}
                  />}


                </div>
              </div>

            </div>

          </div>
        </MainLayout>

        {/* Hostel imaage carousel slider */}
        {openModal && <div className='modal__carousel' onClick={() => setOpenModal(false)}>
          <div className="wrapper" onClick={sliderWrapper}>
            <div className="controls">
              <div className="counter">{`${slideNumber + 1}/ ${roomImages.length}`}</div>
              <div className="cancel" onClick={() => setOpenModal(!openModal)}> <FiX className='icon' size="1.8rem" /> </div>
            </div>

            <div className="imagebox">
              <img src={`${roomImages && resourceURL}${roomImages && roomImages[slideNumber].name}`} className="single__image" alt="single room" />

              <div className={clsx('arrow arrow-left', slideNumber === 0 && 'disabled')} onClick={() => handleSlider("l")}>
                <FiChevronLeft className="icon" size="2.2rem" />
              </div>

              <div className={clsx('arrow arrow-right', slideNumber === roomImages.length - 1 && 'disabled')} onClick={() => handleSlider("r")}>
                <FiChevronRight className="icon" size="2.2rem" />
              </div>

            </div>
          </div>
        </div>

          // <ModalCarousel 
          //     images={imageStack} 
          //     close={openModal} 
          //     setClose={setOpenModal} 
          //     slideNumber={slideNumber}
          //     handleSlider={handleSlider()}
          //   >

          //   </ModalCarousel> 
        }

        <Toast ref={toastRef} type="success" message="Copied room link to clipboard." />

      </div>
    </AnimateRoute>
  )
}

export default HostRoomDetails