import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik';
import Onboarding from '../../../components/layouts/Onboarding';
import FormControl from '../../../components/forms/FormControl';
import { AlertBox } from '../../../components/molecules/alertbox/AlertBox';
// import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../../hooks/useAnalytics';
import { useAuthContext } from '../../../hooks/useAuthContext';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';

export default function ResetPassword() {

  // Use Google Analytics
  useAnalytics()

  const baseURL = process.env.REACT_APP_BASEURL;

  const navigate = useNavigate();
  const { user } = useAuthContext();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState(null);
  const [showMessage, setShowMessage] = useState(false);

  const initialValues = {
    resetCode: '',
    password: '',
    confirmPass: ''
  }

  const validationSchema = Yup.object({
    resetCode: Yup.string().required("Required"),
    password: Yup.string().min(6, "Minimum of 6 charaters required").required('Required'),
    confirmPass: Yup.string().min(6, "Minimum of 6 charaters required").required('Required')
  })

  const onSubmit = values => {

    setIsLoading(true);
    setError(null);

    fetch(`${baseURL}/auth/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        "resetCode": values.resetCode,
        "password": values.password,
        "password_confirmation": values.confirmPass
      })

    }).then((response) => {

      if (response.status === 422) {
        setIsLoading(false)
        throw Error("Password mismatch, please try again.")
      }

      if (response.status === 200) {
        setShowMessage(!showMessage);
        navigate('/reset-password-success');
      }

    }).then((data) => {
      setIsLoading(false)
    }).catch((error) => {
      setIsLoading(false)
      setError(error.message)
    })
  }

  return (
    <AnimateRoute>

      <Helmet>
        <title>Hostel.ng | Reset Password</title>
        <meta name="description" content="Reset your hostel.ng account password." />
        <link rel="canonical" href="/reset-password" />
      </Helmet>

      <Onboarding
        heading="Set a new password"
        image="authbg-3"
      >
        {!showMessage && <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {
            formik => (
              <Form>

                <FormControl
                  control="input"
                  name="resetCode"
                  type="text"
                  label="Password reset code"
                />

                <FormControl
                  control="input"
                  name="password"
                  type="password"
                  label="Enter new password"
                />

                <FormControl
                  control="input"
                  name="confirmPass"
                  type="password"
                  label="Confirm new password"
                />

                <div className="action-container">

                  <p className='text'></p>

                  {!isLoading && <button className='zbtn zbtn-primary-def' disabled={!(formik.dirty && formik.isValid)} type='submit'>
                    Reset password
                  </button>}

                  {isLoading && <button className='zbtn zbtn-primary-def' disabled={isLoading} type='submit'>
                    Resetting password ...
                  </button>}

                </div>

                {error && (<AlertBox type='danger' message={error} />)}

              </Form>
            )

          }
        </Formik>}

      </Onboarding>
    </AnimateRoute>
  )
}
