import React from 'react'
import MainLayout from '../../components/layouts/users/MainLayout'
import useAnalytics from '../../hooks/useAnalytics'
import AnimateRoute from '../../shared/components/animated-route/AnimateRoute'

export default function Insurance() {
    // Use Google Analytics
    useAnalytics()
    return (
        <AnimateRoute>
            <div className='terms_privacy'>
                <MainLayout>
                    <div className="terms_privacy__header">
                        <h4 className='heading'>Damage Insurance</h4>
                    </div>

                    <div className="container">
                        <div className="terms_privacy__content">

                            <p>
                                Host damage Insurance, is a Hostel.ng added value offer for Hosts,
                                It would provide Hosts with insurance coverage in the rare event your
                                place or belongings are damaged by a guest during a Hostel.ng stay.
                            </p>

                            <h1 className="title">What's covered?</h1>
                            <p>
                                Host damage insurance covers you for damage to your place or belongings caused by a guest.
                            </p>

                            <p>Host damage insurance wouldn't cover:</p>

                            <ul>
                                <li>
                                    Damage from wear and tear due to ordinary and reasonable use
                                </li>
                                <li>
                                    Loss due to acts of nature (like earthquakes and hurricanes)
                                </li>
                                <li>
                                    Other exclusions apply
                                </li>
                            </ul>

                            <p>This feature would be available to hosts very soon.</p>
                        </div>
                    </div>
                </MainLayout>
            </div>
        </AnimateRoute>
    )
}
