import React from 'react'
import MainLayout from '../../components/layouts/users/MainLayout'
import useAnalytics from '../../hooks/useAnalytics'
import AnimateRoute from '../../shared/components/animated-route/AnimateRoute'

export default function PrivacyPolicy() {

  // Use Google Analytics
  useAnalytics()

  return (
    <AnimateRoute>
      <div className='terms_privacy'>
        <MainLayout>
          <div className="terms_privacy__header">
            <h4 className='heading'>Privacy Policy</h4>
          </div>

          <div className="container">
            <div className="terms_privacy__content">
              <p>
                Hostel.ng uses hostels to create access to more sustainable and
                affordable rooms for undergraduate students —to build a future where students
                don't see getting accommodation as a challenge. We are a company built on trust.
                A fundamental part of earning that trust means being clear and honest about
                how we use your information and protecting your human right to privacy.
              </p>

              <p>
                This Privacy Policy describes how Zimmer Technologies Limited (aka Hostel.ng)
                and its affiliates (<span className='bold'>“we,” “us,” or “Hostel.ng”</span> ), process personal information
                concerning your use of Hostel.ng. Depending on where you live and what you are
                doing on Hostel.ng, the additional privacy pages listed below may apply to you.
              </p>

              <h1 className="title">What this Privacy Policy Covers</h1>
              <p>
                Your utilization of any of our services warrants that you will not make
                unlawful use of this website or any contents and materials we provide
                through other means with which we correspond with you or final parties on your behalf.
              </p>
              <p>
                This privacy policy does not apply to the practices of companies that
                Hostel.ng does not own or control, or of persons that Hostel.ng does not
                employ or manage, including any third-party content contributors bound by
                contract and any third-party websites to which Hostel.ng websites link.
              </p>

              <h1 className="title">Personal Information we collect and what we do with them?</h1>
              <p>
                We collect personal information about you when you use Hostel.ng.
                Without it, we may not be able to provide all services requested. This information includes:
              </p>
              <ul>
                <li>
                  <span className="bold">Contact Information, Account, Profile Information.</span>
                  Such as your first name, last name, phone number,
                  hostel address, room number, email address, and profile photo.
                </li>
                <li>
                  <span className="bold">Payment Information.</span> Such as bank account or payment account information.
                  If you are not a Hostel.ng user, we may receive payment information
                  relating to you, such as when a Hostel.ng user
                  provides your payment card to complete a reservation.
                </li>
              </ul>

              <p>
                By accessing the services of Hostel.ng and voluntarily providing us
                with the requested personal information, you consent to the collection
                and use of the information in accordance with this privacy policy.
                Your consent can be withdrawn by sending a request to that effect to the contact information supplied below.
              </p>

              <p>
                If you do not consent to collecting, using, or disclosing your personal
                information as outlined in this policy, please do not
                provide any personal information to Hostel.ng.
              </p>

              <h1 className="title">HOW DO WE USE THE INFORMATION WE COLLECT?</h1>
              <p>We may use the information we collect from you when you register as a user in the following ways to:</p>

              <ul>
                <li>
                  Enable you to access Hostel.ng and make or receive payments.
                </li>
                <li>
                  Personalize and customize your experience based on your interactions
                  with the Hostel.ng, your search and reservation history, your profile
                  information and preferences, and other content you submit to allow us
                  to deliver the type of content and product offerings
                  in which you are most interested.
                </li>
                <li>
                  if you provide us with your contacts' information, such as your friends,
                  we may process this information:
                  (i) to facilitate your referral invitations,
                  (ii) for fraud detection and prevention, and
                  (iii) to facilitate your requests or for any other purpose, you authorize.
                </li>
                <li>
                  Allow us to better serve you in responding to your customer service requests.
                </li>
                <li>
                  Enable you to access and use our reservation tabs to quickly process your transactions.
                </li>
                <li>
                  Detect and prevent fraud, spam, abuse, security and safety incidents, and other harmful activity.
                </li>
                <li>Conduct security investigations and risk assessments.</li>
                <li>Conduct checks against databases and other information sources.</li>
                <li>Comply with legal obligations (such as anti-money laundering regulations).</li>
                <li>Enforce our terms and other policies.</li>
                <li>Follow-up reasons (by email or phone inquiries).</li>
                <li>
                  Send you promotional messages, marketing, advertising, and other
                  information based on your preferences and social media advertising
                  through social media platforms.
                </li>
              </ul>

              <h1 className="title">Information Sharing and Disclosure</h1>
              <p>
                We do not sell, trade, or otherwise transfer to outside parties
                your Personal Data unless we provide users with advance notice.
                However, Hostel.ng may disclose your personal information to third
                parties who work on behalf of Hostel.ng to provide products and
                services requested by you. We will share personal information for
                these purposes only with third parties whose privacy policies are
                consistent with ours or who agree to abide by our policies
                with respect to personal information
              </p>
              <p>
                Hostel.ng may otherwise disclose your personal information when:
              </p>
              <ol>
                <li>We have your express consent to share the information for a specified purpose;</li>
                <li>We need to respond to subpoenas, court orders, or such other legal processes;</li>
                <li>We need to protect the personal safety of the users of our websites or defend the rights or property of Hostel.ng;</li>
                <li>
                  We find that your actions on our websites violate the Hostel.ng Terms of Use
                  document or any of our usage guidelines for specific products or services.
                </li>
              </ol>

              <h1 className="title">REMEDIES</h1>
              <p>
                In the event of any violation of this Privacy Policy by Hostel.ng, the
                Customer shall be entitled to demand the return of all Personal Data
                collected and to demand the end of the use of any such Personal Data.
                This remedy shall be available from the time of breach of this
                Privacy Policy, provided the Customer has already sent a complaint that was not attended to.
              </p>

              <h1 className="title">YOUR RIGHTS</h1>

              <span className='bold'>Managing Your Information.</span> <br />
              <p>
                You can access and update some of your personal information through
                your Account settings. You are responsible for keeping your personal information up to date.
              </p>

              <span className='bold'>Rectification of Inaccurate or Incomplete Information.</span>
              <p>
                You have the right to ask us to correct inaccurate or incomplete personal
                information about you, which you cannot update yourself within your Hostel.ng Account.
              </p>

              <h1 className="title">Data Erasure.</h1>
              <p>
                We generally retain your personal information for as long as is essential
                for the performance of the contract between us. You may request that your
                personal information be deleted. Please note that if you request deletion
                of your personal information, or if your account is suspended, terminated,
                or voluntarily closed:
              </p>

              <ul>
                <li>
                  We may retain your personal information as necessary for our
                  legitimate business interests, such as prevention of money laundering,
                  fraud detection and prevention, and enhancing safety. For instance,
                  if we suspend a Hostel.ng account for fraud or safety reasons, we may
                  retain information from that Hostel.ng account to prevent that Member
                  from opening a new Hostel.ng account in the future.
                </li>

                <li>
                  We may retain and use your personal information to the extent necessary to comply with our legal obligations.
                </li>

                <li>
                  Information you have shared with others (e.g., Reviews) will continue to be
                  publicly visible on Hostel.ng, even after your Hostel.ng account is canceled.
                  However, attribution of such information to you will be removed. Some copies
                  of your information (e.g., log records) will remain in our database but are
                  disassociated from personal identifiers.
                </li>

                <li>
                  Because we take measures to protect data from accidental or malicious loss and
                  destruction, residual copies of your personal information may not be
                  removed from our backup systems for a limited period.
                </li>
              </ul>

              <h1 className="title">Security</h1>
              <p>
                Unfortunately, no data transmission over the Internet can be considered
                100% secure. However, your Hostel.ng Information is protected for your
                privacy and security. we are continuously implementing and updating
                administrative, technical, and physical security measures to help
                protect your information against unauthorized access, loss, destruction, or alteration.
              </p>

              <p>
                We also safeguard your personal information from unauthorized access,
                through access control procedures, network firewalls, and physical
                security measures. If you know or have reason to believe that your
                Hostel.ng account credentials have been lost, stolen, misappropriated,
                or otherwise compromised, or in case of any actual or suspected
                unauthorized use of your Hostel.ng account, please contact us.
              </p>

              <h1 className="title">Changes to this Privacy Policy</h1>
              <p>
                Hostel.ng may at any time, without notice to you and in its sole discretion,
                amend this policy from time to time. Please review this policy periodically.
                Your continued use of Hostel.ng websites after any such amendments
                signifies your acceptance thereof.
              </p>

              <h1 className="title">Questions or Suggestions</h1>
              <p>
                If you have questions or suggestions about this privacy policy
                or your personal information, please email us at <a href="mailto: hello@hostel.ng">hello@Hostel.ng</a>.
              </p>
            </div>
          </div>
        </MainLayout>
      </div>
    </AnimateRoute>
  )
}
