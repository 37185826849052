import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../../components/layouts/users/MainLayout';
import ContentLoader from 'react-content-loader';
import useAnalytics from '../../../hooks/useAnalytics';
import { useAuthContext } from '../../../hooks/useAuthContext';
import RentedRoom from './RentedRoom';
import { useQuery } from '@tanstack/react-query';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';
// import CustomSort from '../../../shared/components/custom-sort/CustomSort';

export default function RentedRooms() {

  // Use Google Analytics
  useAnalytics()

  const baseURL = process.env.REACT_APP_BASEURL;
  const { user } = useAuthContext();
  const navigate = useNavigate();

  // Get all user uploaded rooms
  const { data: myRooms, isLoading, } = useQuery(["MyRooms"], () => {
    return axios.get(`${baseURL}/user/rooms`, {
      headers: {
        'Content-Type': 'application/json,',
        "Accept": "application/json",
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${user}`,
      }
    }).then((res) => res.data)
  })


  // Resume a room

  // Sort result by the following
  // const sortRentedRooms = [
  //   {
  //     label: 'Newest',
  //     value: 'newest'
  //   },
  //   {
  //     label: 'Oldest',
  //     value: 'oldest'
  //   }
  // ]

  // content placeholder list
  const myLoaderList = [1, 2, 3, 4];

  return (
    <AnimateRoute>

      <Helmet>
        <title>Listed rooms on  Hostel.ng.</title>
        <meta name="description" content="Start earning from your existing room on Hostel.ng." />
        <link rel="canonical" href="/my-rooms" />
      </Helmet>

      <div className='rented-rooms'>
        <MainLayout>
          <div className="container">
            <div className="rented-rooms__header">
              <h4 className="heading">{myRooms?.length > 1 ? "My Rooms" : "My Room"}</h4>

              {/* Sort by section */}

              {/* <div className="sortby">
              <CustomSort options={sortRentedRooms} label="Sort by" />
            </div> */}

              <button className='zbtn zbtn-primary-def' onClick={() => navigate('/room-upload')}>Add new room</button>
            </div>

            {/* Rentend rooms listing */}
            {myRooms && <div className='listing-wrapper'>
              {
                myRooms.map((room) => {
                  return (
                    <RentedRoom
                      key={room.id}
                      roomId={room.id}
                      roomImage={room.images[0].name}
                      roomTitle={room.name}
                      status={room.status}
                      roomLocation={room.address}
                      roomPrice={room.price}
                      payDuration={room.pay_duration}
                      staysCount={room.bookings_count}
                      hostel_state={room.hostel_state}
                    />
                  )
                })
              }
            </div>}

            {isLoading && <div className='listing-wrapper'>
              {
                myLoaderList.map((holder, index) => {
                  return (
                    <div className="listing-box no" key={index}>
                      <ContentLoader
                        speed={2}
                        width={282}
                        height={400}
                        viewBox="0 0 282 400"
                        backgroundColor="#F1F2F3"
                        foregroundColor="#F8F8F9"
                        style={{ width: "100%" }}

                      >
                        <rect x="0" y="0" rx="12" ry="12" width="281" height="220" />
                        <rect x="0" y="240" rx="2" ry="2" width="214" height="20" />
                        <rect x="0" y="268" rx="2" ry="2" width="140" height="14" />
                        <rect x="0" y="315" rx="2" ry="2" width="111" height="32" />
                      </ContentLoader>
                    </div>
                  )
                })
              }

            </div>}

            {!isLoading && myRooms.length < 1 && (<div className='listing-wrapper'>

              <div className="empty-state">
                <h1 className='title'>You are yet to upload a room.</h1>
                <span className='subtitle'>When you upload a room, it will show here.</span>
                <button className='zbtn zbtn-secondary-def click' type='button' onClick={() => navigate('/rooms')}>Become a Host</button>
              </div>

            </div>)}

          </div>
        </MainLayout>
      </div>
    </AnimateRoute>
  )
}
