import React from 'react'
import AgentLayout from '../../../components/layouts/agents/AgentLayout'
import QuestionsForm from './QuestionsForm'

function Questions() {
    return (
        <AgentLayout>
            <div className="container">
                <div className="agents__banner">
                    <div className="titlebox">
                        Let's hear from you.
                    </div>
                </div>

                <div className="agents__formbox">
                    <QuestionsForm />
                </div>
            </div>
        </AgentLayout>
    )
}

export default Questions
