import React, { useEffect } from 'react'
import MainLayout from '../../../components/layouts/users/MainLayout';
import RoomList from '../homepage/RoomList';
import CustomSearch from '../../../shared/components/custom-search/CustomSearch';
import { BiFilter } from "react-icons/bi";
import CustomSelect from '../../../shared/components/custom-select/CustomSelect';
import { useState } from 'react';
import CustomSort from '../../../shared/components/custom-sort/CustomSort';
import { useAuthContext } from '../../../hooks/useAuthContext';
import axios from 'axios';
import loading from '../../../assets/images/gif/loading.gif';
import useAnalytics from '../../../hooks/useAnalytics';
import { useRef } from 'react';
import autoAnimate from '@formkit/auto-animate';
import ContentLoader from "react-content-loader"
import useFilterRoomsContext from '../../../hooks/useFilterRoomsContext';
import { FiXCircle } from 'react-icons/fi';
import SearchRooms from '../homepage/SearchRooms';
import ReactPaginate from 'react-paginate';
import InfiniteScroll from 'react-infinite-scroll-component';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';
import EmptyList from '../../../assets/images/svg/empty-booked-state.svg';
import { useSearchParams } from "react-router-dom";



export default function RoomListing() {

  // Use Google Analytics
  useAnalytics()


  const baseURL = process.env.REACT_APP_BASEURL;
  const { user } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [page, setPage] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const merchantRef = searchParams.get("ref");
  if (merchantRef) {
    localStorage.setItem("merchantRef", merchantRef);
  }

  const roomsPerPage = 12;

  // setPageCount(Math.ceil(rooms?.length / roomsPerPage));


  // const [currentItems, setCurrentItems] = useState([]);
  // const [pageCount, setPageCount] = useState(0);
  // const [itemOffset, setItemOffset] = useState(0);

  // useEffect(() => {
  //   const endOffSet = itemOffset + itemsPerPage;
  //   setCurrentItems(rooms.slice(itemOffset, endOffSet));
  //   setPageCount(Math.ceil(rooms.length / itemsPerPage));
  // }, [itemOffset, itemsPerPage, rooms])

  // const handlePageClick = ({ selected: selectedPage }) => {
  //   console.log("Selected", selectedPage)
  //   setCurrentPage(selectedPage);
  // }


  const { city, priceRange, facilities, payDuration, dispatch } = useFilterRoomsContext();

  // Handl reset filters
  const handleResetFilter = () => {
    dispatch({ type: "RESET_FILTER" });
  }


  const showMe = false;

  // Sort result by the following
  const sortRooms = [
    {
      label: 'Highest price',
      value: 'lowest'
    },
    {
      label: 'Lowest price',
      value: 'highest'
    },

  ]



  const parentRef = useRef(null);

  useEffect(() => {
    getRoomListings();

  }, [baseURL, page, city, priceRange, facilities, payDuration])

  // console.log(searchValue ? searchValue : "");

  // Get all room listings
  const getRoomListings = () => {

    setIsLoading(true);
    setEmpty(false)
    setError(null);
    axios.get(`${baseURL}/room?page=${page}&city=${city}&range=${priceRange}&facilities=${facilities}&duration=${payDuration}&searchValue=${searchValue}`, {
      headers: {
        'Content-Type': 'application/json,',
        "Accept": "application/json",
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${user}`,
      }
    }).then((response) => {
      // setRooms(response.data.data);
      if (page > 1) {
        const result = [...rooms, ...response.data.data]
        setRooms(result);

      } else {
        setRooms(response.data.data);
      }
      setPageCount(response.data.last_page);
      setIsLoading(false);
      setEmpty(true)
    }).catch((error) => {
      setError(error.message);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    if (parentRef.current) {
      autoAnimate(parentRef.current)
    }
  }, [parentRef])

  // const handleScroll = () => {
  //   if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {

  //     setPage((prev) => prev + 1);
  //   }
  // }


  // content placeholder list
  const myLoaderList = [1, 2, 3, 4, 5, 6, 7, 8];

  // Handle pagination
  const paginateRooms = (direction) => {
    if (page < pageCount && direction === "next") {
      setPage(page + 1);
    }

    if (direction === "prev" && page > 1) {
      setPage(page - 1)
    }

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }

  // Handle load more rooms
  const loadMoreRooms = () => {
    if (page < pageCount) {
      setPage(page + 1);
    }
  }

  const [empty, setEmpty] = useState(false);



  return (
    <AnimateRoute>

      <Helmet>
        <title>View all affordable Hostel rooms for rent - Hostel.ng.</title>
        <meta name="description" content="Find the best hostel rooms for your travel needs." />
        <link rel="canonical" href="/rooms" />
      </Helmet>

      <div className='room-list'>
        <MainLayout>
          <div className="container">

            {/* search and filter controls */}
            <div className="room-list__controlbox">
              {/* search control */}
              <div className="search__control" ref={parentRef}>

                <div className="search_and_filter">

                  <CustomSearch
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    refetch={getRoomListings}
                    isLoading={isLoading}
                  />

                  <div className=''>
                    <button type='button' className="zbtn zbtn-white" onClick={() => setShowFilters(!showFilters)}>
                      <BiFilter className='icon' size="2rem" />
                      Filters
                    </button>
                    {city !== "" && <span className='reset_filter' onClick={() => handleResetFilter()}><FiXCircle /> Reset filters</span>}
                  </div>
                </div>

                {/* Filters section */}
                {showFilters && <SearchRooms setPage={setPage} />}

              </div>

              {/* filter control */}
              {showMe && <div className="filter__control">
                <CustomSort rooms={rooms} setRooms={setRooms} options={sortRooms} page={page} label="Sort by" />
              </div>}

            </div>

            {/* Room Listings */}

            {rooms?.length >= 1 ? <>
              <div className='listing-wrapper'>

                {
                  rooms?.map((room) => {
                    // console.log(room.images.map(image => image.name))
                    return (
                      <RoomList
                        key={room.id}
                        roomId={room.id}
                        roomImage={room.images[0].name}
                        roomTitle={room.name}
                        status={room.status}
                        roomLocation={`${room.address} , ${room.city.name}`}
                        roomPrice={room.price}
                        payDuration={room.pay_duration}
                      />
                    )
                  })


                }

              </div>


              <div className="paginatecon">
                {page !== pageCount && <button className='zbtn zbtn-primary-def' onClick={() => loadMoreRooms()} disabled={page === pageCount}>Load more</button>}
                {/* <button className='zbtn zbtn-primary-def' onClick={() => paginateRooms("prev")} disabled={page === 1}>Previous</button>
              <button className='zbtn zbtn-primary-def' onClick={() => paginateRooms("next")} disabled={page === pageCount}>Next</button> */}
              </div>

            </> : empty ? <div className="empty-statecon">
              <img src={EmptyList} alt="" />
              <h1 className='title'>No exact matches for your preferred options.</h1>
              <span className='subtitle'>Kindly click on the button below to try again.</span>
              <button className='zbtn zbtn-primary-def click' type='button' onClick={() => getRoomListings()}>Reset options</button>

            </div> : null
            }

            {empty && rooms?.length === 0 && <div className="empty-statecon">
              <img src={EmptyList} alt="" />
              <h1 className='title'>No exact matches for your preferred options.</h1>
              <span className='subtitle'>Kindly click on the button below to try again.</span>
              {/* <button className='zbtn zbtn-primary-def click' type='button' onClick={() => navigate('/rooms')}>Reset Filter</button> */}

            </div>}

            {/* {!rooms && <img src={loading} className="loadingGif" alt='' />} */}

            {isLoading && <div className='listing-wrapper'>
              {
                myLoaderList.map((holder, index) => {
                  return (
                    <div className="listing-box no" key={index}>
                      <ContentLoader
                        speed={2}
                        width={282}
                        height={400}
                        viewBox="0 0 282 400"
                        backgroundColor="#F1F2F3"
                        foregroundColor="#F8F8F9"
                        style={{ width: "100%" }}

                      >
                        <rect x="0" y="0" rx="12" ry="12" width="281" height="220" />
                        <rect x="0" y="240" rx="2" ry="2" width="214" height="20" />
                        <rect x="0" y="268" rx="2" ry="2" width="140" height="14" />
                        <rect x="0" y="315" rx="2" ry="2" width="111" height="32" />
                      </ContentLoader>
                    </div>
                  )
                })
              }

            </div>}

            {/* {!isLoading && rooms && rooms.length === 0  && } */}



          </div>
        </MainLayout>
      </div>
    </AnimateRoute>
  )
}
