import autoAnimate from '@formkit/auto-animate';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'

export default function Accordionn({title, content}) {

    const parentRef = useRef(null);

    useEffect(() => {
        if(parentRef.current){
            autoAnimate(parentRef.current);
        }
    }, [parentRef])

    const [selected, setSelected] = useState(false);
    
    return (
        <div className='accordion' ref={parentRef}>
            <div className="accordion__heading" onClick={() => setSelected(!selected)}>
                <h4 className={clsx('text', selected === true && 'active')}>{title}</h4>
                <FiChevronDown className={clsx('icon', selected === true && 'active')} size="1.8rem" />
            </div>

            {selected && 
                <div className={clsx('accordion__content', selected === true && 'active')}>
                    <div>{content}</div>
                </div>
            }
        </div>
    )
}
