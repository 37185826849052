import React from 'react'
import AnimateRoute from '../../shared/components/animated-route/AnimateRoute'
import { Helmet } from 'react-helmet-async'
import MainLayout from '../../components/layouts/users/MainLayout'
import T40LOGO from "../../assets/images/svg/t40-partnership.svg"
import ElevateLine from "../../assets/images/svg/elevate-icon.svg"
import RideImages from "../../assets/images/img/busride-image.png"

function GetRide() {
    return (
        <AnimateRoute>
            <Helmet>
                <title>Affordable Hostel rooms for rent - Hostel.ng.</title>
                <meta name="description" content="Find the best hostel rooms for your travel needs." />
                <link rel="canonical" href="/get" />
            </Helmet>

            <MainLayout>
                <div className="getbus">
                    <div className="container">
                        <div className="getbus__con">
                            <div className="textbox">
                                <h1 className='heading'>
                                    <span className='elevate'>
                                        Elevate
                                        <img src={ElevateLine} alt="elevate icon" className='elevate__icon' />
                                    </span> your  <span className='stay'>stay</span>
                                </h1>

                                <p className='subheading'>
                                    Smooth rides ahead! as you can now get a ride to your next destination with
                                    <span className='blue'> ease.</span>
                                </p>

                                {/* <p className='subheading'>
                                    Our Partnership with T40 Technologies now ensures you experience
                                    <span className='blue'> Stress-Free Travels. </span>
                                </p> */}

                                <div className='actions'>
                                    <a href='https://t40.link/intercity-hostelng' rel="noreferrer" target='_blank' className='zbtn zbtn-primary-md busbtn'>Book a bus</a>
                                    <div className='ml-4'><img src={T40LOGO} alt="T40 logo" className='' /></div>
                                </div>
                            </div>

                            <div className="imagebox">
                                <div className="rideimage">
                                    <img src={RideImages} alt="" className='book-image' />
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* <img src={RideLines} alt="lines" className='ridelines' /> */}
                </div>
            </MainLayout>
        </AnimateRoute>
    )
}

export default GetRide
