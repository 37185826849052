import React from 'react';
import { useEffect } from 'react';
import { FiCheckCircle, FiInfo } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import Onboarding from '../../../components/layouts/Onboarding';
import axios from 'axios';
import { useState } from 'react';
import useAnalytics from '../../../hooks/useAnalytics';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';

export default function Verified() {

    // Use Google Analytics
    useAnalytics()

    const baseURL = process.env.REACT_APP_BASEURL;

    const navigate = useNavigate();

    const { code } = useParams();

    const [error, setError] = useState([])
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {

        // Verify the otp from the user
        const verifyUser = async () => {

            setIsLoading(true);
            if (code) {

                axios.get(`${baseURL}/auth/verify/${code}`).then((response) => {
                    if (response) {
                        setIsLoading(false);
                    }
                }).catch((error) => {
                    setError(error.response.data)
                    setIsLoading(false)
                })
            }
        }

        verifyUser();

    }, [code, baseURL])

    return (
        <AnimateRoute>

            <Helmet>
                <title>Hostel.ng | Verify Account</title>
                <meta name="description" content="Verify your hostel.ng account." />
            </Helmet>

            <Onboarding
                heading=""
                image="authbg-2"
            >
                <>
                    {!isLoading && <div className='statusbox'>

                        <div className='iconbox'>
                            <FiCheckCircle size="4rem" className="email-sent" />
                        </div>
                        <div>
                            <p className='statusbox__userinfo'>
                                Your account have been successfully verified. <br /> Click the button below to continue.
                            </p>

                        </div>

                        <button type='button' className='zbtn zbtn-primary-def mt-3' onClick={() => navigate('/login')}>Sign In to continue</button>

                    </div>}

                    {/* {error && !isLoading && <div className='statusbox'>

                    <div className='iconbox iconbox--error'>
                        <FiInfo size="4rem" className="email-sent" />
                    </div>
                    <div>
                        <p className='statusbox__userinfo'>
                            {error}
                        </p>

                    </div>

                    <button type='button' className='zbtn zbtn-primary-def mt-3' onClick={() => navigate('/')}>Back to Homepage</button>

                </div>} */}
                </>
            </Onboarding>
        </AnimateRoute>
    )
}
