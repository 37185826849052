import React from 'react'
import { Helmet } from 'react-helmet-async'
import MainLayout from '../../../components/layouts/users/MainLayout'
import useAnalytics from '../../../hooks/useAnalytics'
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute'
import ContactForm from './ContactForm'

export default function HelpCenter() {
  // Use Google Analytics
  useAnalytics()

  return (
    <AnimateRoute>
      <Helmet>
        <title>Help Center - Hostel.ng.</title>
        <meta name="description" content="Send in your questions and requests to us on Hostel.ng." />
        <link rel="canonical" href="/help-center" />
      </Helmet>

      <div className="help-center">
      
        <MainLayout>
          <div className="help-center__header">
            <h4 className='heading'>Help Center</h4>
            <p className='subheading'>We are always here to help you, send us an email.</p>
          </div>

          {/* Contact form section */}
          <div className="formbox">
            <ContactForm />
          </div>

        </MainLayout>
      </div>
    </AnimateRoute>
  )
}
