import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import { FilterRoomsContextProvider } from './context/FilterRoomsContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from "react-redux";
import store from "./redux/store";
import { HelmetProvider } from 'react-helmet-async';
import { MerchantAuthContextProvider } from './context/MerchantAuthContext';
import { UserDetailsContextProvider } from './context/UserDetailsContext';
import { MerchantDetailsContextProvider } from './context/MerchantDetailsContext';


const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <MerchantAuthContextProvider>
        <UserDetailsContextProvider>
          <MerchantDetailsContextProvider>
            <FilterRoomsContextProvider>

              <QueryClientProvider client={queryClient}>
                <Router>
                  <Provider store={store}>
                    <HelmetProvider>
                      <App />
                    </HelmetProvider>
                  </Provider>
                </Router>
              </QueryClientProvider>

            </FilterRoomsContextProvider>
          </MerchantDetailsContextProvider>
        </UserDetailsContextProvider>
      </MerchantAuthContextProvider>
    </AuthContextProvider>

  </React.StrictMode>,
  document.getElementById('root')
);
