import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

export default function CheckBoxField({ label, name, options, ...rest }) {
  return (
    <div className='check__box'>
      <div className='check__box--title'>{label}</div>
      <Field name={name} {...rest} >
        {
          ({ field }) => {
            return options.map((option) => {
              return (
                <React.Fragment key={option.value}>
                  <label htmlFor={option.value} className="check__box--label">
                    <input
                      type="checkbox"
                      id={option.value}
                      {...field}
                      value={option.value}
                      checked={field.value.includes(option.value)}
                    /> 
                    
                    <span className="check__box--checker">{option.label}</span>
                  </label>

                </React.Fragment>
              )
            })
          }
        }
      </Field>

      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}
