
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "./useAuthContext"
import { useUserDetailsContext } from "./useUserDetailsContext"
import axios from "axios";


export const useLogOut = () => {

    const baseURL = process.env.REACT_APP_BASEURL;
    const { user } = useAuthContext();

    const { dispatch } = useAuthContext()
    const { dispatch: userDispatch } = useUserDetailsContext();

    const merchantRef = localStorage.getItem('merchantRef');

    // LogOut User 
    const logOutUser = async () => {
        return await axios.get(`${baseURL}/auth/logout`, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((res) => res.data);
    };

    const logOut = () => {

        // remove user from local storage
        // navigate('/login');
        localStorage.removeItem('user');
        localStorage.removeItem('firstname');
        localStorage.removeItem('lastname');
        localStorage.removeItem('hasB');
        localStorage.removeItem('avatar');
        localStorage.removeItem('email');
        localStorage.removeItem('hCount');
        localStorage.removeItem('userID');
        localStorage.removeItem('balance');
        localStorage.removeItem('user_details');
        localStorage.removeItem('userRef');
        // Check if merchant referral code exist and clear it from local storage
        if (merchantRef) {
            localStorage.removeItem('merchantRef');
        }

        // dispatch logout action
        dispatch({ type: 'LOGOUT' });
        userDispatch({ type: 'UNSET_DETAILS' });

        // Clear user Session on the server
        logOutUser();
    }

    return { logOut }
}