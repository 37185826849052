import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../../hooks/useAuthContext';

function RequireAuth() {

    const { user } = useAuthContext();
    const location = useLocation();

    return (
        user ? <Outlet/>
            : <Navigate to="/login" state={{previousUrl : location.pathname }} />

    )
}

export default RequireAuth