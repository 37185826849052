import React from 'react'
import MainLayout from '../../../components/layouts/users/MainLayout';
import successImage from '../../../assets/images/gif/happy-swinging-boy.gif';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../../hooks/useAnalytics';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';

function ReservationSuccess() {

    // Use Google Analytics
    useAnalytics()

    const navigate = useNavigate();

    return (
        <AnimateRoute>
            <div className='room-upload'>
                <MainLayout>
                    <div className="container">
                        <div className="room-upload__content">
                            <div className="successbox">
                                <img src={successImage} alt="room upload successful" className='image' />
                                <h4 className='heading'>Payment successful!</h4>
                                <p className='subheading'>
                                    You just successfully reserved a room.
                                </p>

                                {/* <button type='button' className='zbtn zbtn-primary-def' onClick={() => navigate('/rented-rooms')}>View Listings</button> */}
                                <button type='button' className='zbtn zbtn-primary-def' onClick={() => navigate('/reserved-rooms')}>View my room</button>
                            </div>
                        </div>
                    </div>
                </MainLayout>
            </div>
        </AnimateRoute>

    )
}

export default ReservationSuccess