import { useContext } from "react"
import { MerchantDetailsContext } from "../context/MerchantDetailsContext"


export const useMerchantDetailsContext = () => {
    const context = useContext(MerchantDetailsContext);

    if(!context){
        throw Error("MerchantDetails need to be in context")
    }

    return context
}