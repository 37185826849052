import { useNavigate } from "react-router-dom";
import { useMerchantAuthContext } from "./useMerchantAuthContext"
import axios from "axios";
import { useMerchantDetailsContext } from "./useMerchantDetailsContext";


export const useMerchantLogOut = () => {

    const baseURL = process.env.REACT_APP_BASEURL;
    const { user_merchant } = useMerchantAuthContext();

    const navigate = useNavigate();
    const { dispatch } = useMerchantAuthContext();
    const { dispatch: merchantDispatch } = useMerchantDetailsContext();

    // LogOut User 
    const logOutUser = async () => {
        return await axios.get(`${baseURL}/merchant/logout`, {
            headers: {
                'Authorization': `Bearer ${user_merchant}`
            }
        }).then((res) => res.data);
    };

    const logOut = () => {
        
        localStorage.removeItem('user_merchant');
        localStorage.removeItem('merchant_details');

        // Unset user details from their context.
        dispatch({ type: 'LOGOUT' });
        merchantDispatch({ type: 'UNSET_MERCHANT_DETAILS'});

        // Navigate to login screen
        navigate('/merchant/');

        // Clear user Session on the server
        logOutUser();
    }

    return { logOut };
}