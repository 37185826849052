import React from 'react'
import { useNavigate } from 'react-router-dom';
import Smiling from '../../../assets/images/img/ladies-smiling.png';

export default function ReferBox() {
  const navigate = useNavigate()
  return (
    <div className="refer__box">
        <div className="content">
            <h4 className="title">Lease your room!</h4>
            <p className="subtitle">You are just one step away from your wealth creation journey.</p>
            <button type="button" className="referbtn" onClick={() => navigate("/host")}>Become a Host</button>
        </div>
        <div className="image">
            <img src={Smiling} alt="ladies smiling after earning on hostel.ng" />
        </div>
    </div>
  )
}
