import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Onboarding from '../../../components/layouts/Onboarding';
import FormControl from '../../../components/forms/FormControl';
import { useForgetPassword } from '../../../hooks/useForgetPassword';
import { AlertBox } from '../../../components/molecules/alertbox/AlertBox';
import EmailIcon from '../../../assets/images/svg/email-success.svg'
import { useState } from 'react';
import useAnalytics from '../../../hooks/useAnalytics';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useNavigate } from 'react-router-dom';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';

export default function ForgotPassword() {

  // Use Google Analytics
  useAnalytics()

  const { user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);


  const { forgetPassword, resetPasswordSent, isLoading, error } = useForgetPassword()

  const [getEmail, setGetEmail] = useState('')

  const initialValues = {
    email: ''
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required')
  })

  const onSubmit = values => {
    forgetPassword(values.email)
    setGetEmail(values.email)
  }

  const resendForgotPassword = () => {
    if (getEmail) {
      forgetPassword(getEmail)
    }
  }

  return (
    <AnimateRoute>

      <Helmet>
        <title>Hostel.ng | Forgot Password</title>
        <meta name="description" content="Reset your hostel.ng account password." />
        <link rel="canonical" href="/forgot-password" />
      </Helmet>

      <Onboarding
        heading="Reset password"
        subHeading="Let's get you back on track."
        image="authbg-3"
      >

        {!resetPasswordSent && <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {
            formik => (
              <Form>

                <FormControl
                  control="input"
                  name="email"
                  type="email"
                  label="Email Address"
                />

                <div className="action-container">

                  {!isLoading && <button type='submit' className='zbtn zbtn-primary-def' disabled={!(formik.dirty && formik.isValid)}>Send reset password</button>}
                  {isLoading && <button type='submit' className='zbtn zbtn-primary-def' disabled={isLoading}>Sending link ...</button>}

                </div>

                {error && (<AlertBox type='danger' message={error} />)}
              </Form>
            )

          }
        </Formik>}

        {resetPasswordSent && <div className='statusbox'>
          <img src={EmailIcon} alt="" className='email-sent' />

          <div>
            <p className='statusbox__userinfo'>
              {/* Hi <span className='username'>Folaranmi</span>,  */}
              A reset password link has just been sent to your email address.
              Click the link in your inbox to reset your password.
            </p>
            <p className='resend'>Didn't get the mail? <span className="action" onClick={resendForgotPassword}>Click to resend</span></p>
          </div>


        </div>}
      </Onboarding>
    </AnimateRoute>
  )
}
