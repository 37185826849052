import { Form, Formik } from 'formik'
import React from 'react';
import * as Yup from 'yup';
import FormControl from '../../../components/forms/FormControl';

export default function ExtendStay() {

    const initialValues = {
        extendedDate: '',
        extendedTime: ''
    }

    const validationSchema = Yup.object({
        extendedDate: Yup.string().required("Field Required"),
        extendedTime: Yup.string().required("Field Required")
    })

    const onSubmit = values => {
        console.log('Form Data', values)
    }

  return (
    <div className='extend-stay'>
        <Formik 
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        > 
            {
                formik => (

                    <Form>

                        <FormControl 
                            control="input" 
                            name="extendedDate" 
                            type="date" 
                            label="Set your new checkout date"
                        />
                        
                        <FormControl 
                            control="input" 
                            name="extendedTime" 
                            type="text" 
                            label="Set your new checkout time"
                        />

                        <div className="box">
                            <div className="datebox">
                                <span className='title'>PREVIOUS CHECKOUT</span>
                                <span className='date'>Sep 27, 2022 - 2pm</span>
                            </div>

                            <div className="datebox">
                                <span className='title'>CURRENT CHECK OUT</span>
                                <span className='date'>Sep 27, 2022 - 2pm</span>
                            </div>
                        </div>

                        <button type='submit' className='zbtn zbtn-primary-def' disabled={!(formik.dirty && formik.isValid)}>Ok, cool</button>

                    </Form>
                )
            }
            </Formik>
    </div>
  )
}
