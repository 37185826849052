import React from 'react'
import Footer from './Footer'
import Header from './Header'

export default function MainLayout({children, nmargin}) {
  return (
    <div>
        <Header/>
            {children}
        <Footer nmargin={nmargin}/>
    </div>
  )
}
