import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import Onboarding from '../../../components/layouts/Onboarding';
import FormControl from '../../../components/forms/FormControl'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AlertBox } from '../../../components/molecules/alertbox/AlertBox';
import useAnalytics from '../../../hooks/useAnalytics';
import { useAuthContext } from '../../../hooks/useAuthContext';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';
// import { useSignUp } from '../../../hooks/useSignUp';

export default function SignUp() {

  // Use Google Analytics
  useAnalytics()

  const baseURL = process.env.REACT_APP_BASEURL;

  const navigate = useNavigate()

  const { user } = useAuthContext();

  // Get referral code from url
  const [searchParams, setSearchParams] = useSearchParams();
  const userRef = searchParams.get("hosref");

  if (userRef) {
    localStorage.setItem("userRef", userRef);
  }

  // Check if the user referrral exist in the local storage
  const userRefExist = localStorage.getItem("userRef");

  // console.log(userRefExist);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);


  const [isLoading, setIsLoading] = useState(null)
  const [error, setError] = useState(null)
  const [createUserLink, setCreateUserLink] = useState("");

  const optionsValues = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' }
  ]

  const merchantRef = localStorage.getItem("merchantRef");

  useEffect(() => {
    if (merchantRef) {
      setCreateUserLink(`signup/${merchantRef}`);
    } else {
      setCreateUserLink(`signup`);
    }
  }, [merchantRef]);

  const initialValues = {
    fname: '',
    lname: '',
    email: '',
    password: '',
    mobile: '',
    gender: '',
    referee: (userRef || userRefExist) ? (userRef || userRefExist) : ''
  }

  const validationSchema = Yup.object({
    fname: Yup.string().required('Required'),
    lname: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email format').required('Required'),
    password: Yup.string()
      .min(6, "Password must be 6 characters or more")
      .required('Required'),
    mobile: Yup.string().required('Required'),
    gender: Yup.string().required('Required')
    // refree: Yup.string().required('Required')
  })

  const onSubmit = (values) => {
    
    setIsLoading(true);
    setError(null);

    fetch(`${baseURL}/auth/${createUserLink}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(values)

    }).then((response) => {
      if (response.status === 422) {
        setIsLoading(false)
        throw Error("Email or Phone number already exist")
      }

      if (response.status === 201) {
        navigate('/verify-account')
      }

    }).then((data) => {
      setIsLoading(false)
    }).catch((error) => {
      setError(error.message)
    })
  }

  return (
    <AnimateRoute>

      <Helmet>
        <title>Hostel.ng | Sign Up</title>
        <meta name="description" content="Sign up for a free hostel.ng account." />
        <link rel="canonical" href="/signup" />
      </Helmet>

      <Onboarding
        heading="Let's get to know you."
        image="authbg-3"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {
            ({ dirty, isValid, setFieldValue }) => (
              <Form>

                <div className="row">
                  <div className="col-md-6">
                    <FormControl
                      control="input"
                      name="fname"
                      type="text"
                      label="First name"
                    />
                  </div>

                  <div className="col-md-6">
                    <FormControl
                      control="input"
                      name="lname"
                      type="text"
                      label="Last name"
                    />
                  </div>
                </div>

                <FormControl
                  control="input"
                  name="email"
                  type="email"
                  label="Email Address"
                />

                <FormControl
                  control="input"
                  name="password"
                  type="password"
                  label="Password"
                />

                <div className="row">
                  <div className="col-md-6">
                    <FormControl
                      control="input"
                      name="mobile"
                      type="tel"
                      label="Phone number"
                    />
                  </div>
                  <div className="col-md-6">
                    <FormControl
                      control="select"
                      name="gender"
                      type="select"
                      label="Gender"
                      options={optionsValues}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </div>

                <p className='terms'>
                  By signing up, you agree to our
                  <Link to="/terms" target="_blank" className='text-primary-300'> Terms of Service </Link> and
                  <Link to="/privacy-policy" target="_blank" className="text-primary-300"> Privacy Policy</Link>
                </p>

                <div className="action-container pad-t3">

                  <p className='text'>Got an account? <Link to="/login" className='text-primary-300'>Sign In</Link> </p>

                  {!isLoading && <button type='submit' className='zbtn zbtn-primary-def' disabled={!(dirty && isValid)}>Create Free Account</button>}
                  {isLoading && <button type='submit' className='zbtn zbtn-primary-def' disabled={isLoading}>Creating your account ...</button>}

                </div>

                {error && (<AlertBox type='danger' message={error} />)}

              </Form>
            )

          }
        </Formik>

      </Onboarding>
    </AnimateRoute>
  )
}
