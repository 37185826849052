import React from 'react';
import { Link } from 'react-router-dom';
import { BiCopyright } from "react-icons/bi";


import FacebookLogo from '../../../assets/images/svg/facebook-icon.svg';
import TwitterLogo from '../../../assets/images/svg/twitter-icon.svg';
import LinkedInLogo from '../../../assets/images/svg/linkedin-icon.svg';
import InstagramLogo from '../../../assets/images/svg/instagram-icon.svg';

export default function Footer({nmargin}) {
  return (
    <div className={`footer ${nmargin}`}>
        <div className="footer-container">

            <div className="footer__box">
                <h4 className='footer__box--heading'>Company</h4>
                <ul className='footer__box--nav'>
                    {/* <li><Link to="" className='nav_link'>About Us</Link></li> */}
                    {/* <li><Link to="" className='nav_link'>Careers</Link></li> */}
                    <li><Link to="/terms" className='nav_link'>Terms</Link></li>
                    <li><Link to="/privacy-policy" className='nav_link'>Privacy</Link></li>
                </ul>
            </div>

            <div className="footer__box">
                <h4 className='footer__box--heading'>Quick Links</h4>
                <ul className='footer__box--nav'>
                    <li><Link to="/rooms" className='nav_link'>Lease a Room</Link></li>
                    <li><Link to="/host" className='nav_link'>Become a Host</Link></li>
                    {/* <li><Link to="/" className='nav_link'>How it Works</Link></li> */}
                </ul>
            </div>

            <div className="footer__box">
                <h4 className='footer__box--heading'>Resources</h4>
                <ul className='footer__box--nav'>
                    <li><Link to="/faqs" className='nav_link'>FAQs</Link></li>
                    <li><Link to="/help-center" className='nav_link'>Help Center</Link></li>
                    {/* <li><Link to="/" className='nav_link'>Stories</Link></li> */}
                </ul>
            </div>

            <div className="footer__box">
                <h4 className='footer__box--heading'>Contact</h4>
                <ul className='footer__box--nav'>
                    <li><a href="mailto:hello@hostel.ng" className='nav_link'>hello@hostel.ng</a></li>
                    <li><a href="tel:+2348087838534" className='nav_link'>+2348087838534</a></li>
                    <div className="social">
                        <a href="https://web.facebook.com/Hostel1.ng/" target="_blank" rel="noreferrer" className=''><img src={FacebookLogo} alt="Facebook Logo" /></a>
                        <a href="https://twitter.com/HostelNg" target="_blank" rel="noreferrer" className=''><img src={TwitterLogo} alt="Twitter Logo" /></a>
                        <a href="https://www.linkedin.com/company/hostelng/" target="_blank" rel="noreferrer" className=''><img src={LinkedInLogo} alt="LinkedIn logo" /></a>
                        <a href="https://www.instagram.com/hostel.ng/" target="_blank" rel="noreferrer" className=''><img src={InstagramLogo} alt="Instagram Logo" /></a>
                    </div>
                </ul>
            </div>
        </div>

        {/* copyright */}
        <div className="copyright">
            <p> <BiCopyright size="1.4rem" className=''/> Copyright 2022 (Zimmer Technologies). All Rights Reserved. </p>
        </div>

    </div>
  )
}
