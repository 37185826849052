import React, { useEffect, useRef, useState } from 'react'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import FormControl from '../../../components/forms/FormControl'
import { useLocation, useNavigate } from 'react-router-dom'
import dateFormat from 'dateformat';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { usePaystackPayment } from 'react-paystack';
import { NumericFormat } from 'react-number-format';
import Naira from '../../../assets/images/svg/naira-icon.svg';
import RoomInUse from '../../../assets/images/svg/form-empty-state.svg';
import { AlertBox } from '../../../components/molecules/alertbox/AlertBox';
import axios from 'axios'
import ContentLoader from 'react-content-loader';
import { addDays, addMonths } from 'date-fns';
import CircularPreloader from '../../../shared/components/preloader/CircularPreloader'

function MonthlyReservationForm({ price, roomId, status, roomUserId, disableThisDates }) {
    const navigate = useNavigate();
    const location = useLocation();

    const { user } = useAuthContext();
    const baseURL = process.env.REACT_APP_BASEURL;
    const PSPUBKEY = process.env.REACT_APP_PSPUBKEY;

    // Get details from storage
    const userEmail = JSON.parse(localStorage.getItem('email'));
    const userID = JSON.parse(localStorage.getItem('userID'));
    const hasB = JSON.parse(localStorage.getItem('hasB'));
    // const userID = hasB ? hasB[0].user_id : 0;

    const [isLoading, setIsLoading] = useState(false);

    const checkInTimeOptions = [
        { label: "6 am", value: "06am" },
        { label: "7 am", value: "07am" },
        { label: "8 am", value: "08am" },
        { label: "9 am", value: "09am" },
        { label: "10 am", value: "10am" },
        { label: "11 am", value: "11am" },
        { label: "12 noon", value: "12noon" },
        { label: "1 pm", value: "01pm" },
        { label: "2 pm", value: "02pm" },
        { label: "3 pm", value: "03pm" },
        { label: "4 pm", value: "04pm" },
        { label: "5 pm", value: "05pm" },
        { label: "6 pm", value: "06pm" },
        { label: "7 pm", value: "07pm" },
        { label: "8 pm", value: "08pm" },
        { label: "9 pm", value: "09pm" },
        { label: "10 pm", value: "10pm" },
    ];

    const stayDurationOptions = [
        { label: "1 month", value: "1" },
        { label: "2 months", value: "2" },
        { label: "3 months", value: "3" },
        { label: "4 months", value: "4" },
    ];

    // Formik values is here
    const [formikValues, setFormikValues] = useState({});

    useEffect(() => {

    }, [formikValues]);

    // function to add months so as to get the checkout date.
    const getCheckoutDate = addMonths(new Date(formikValues?.checkInDate), formikValues?.stayDuration);

    // calculate totalserviceCharge, payable amount.
    const serviceCharge = Math.ceil(price * formikValues?.stayDuration * 0.2);  // Hostel.ng service charge.
    const totalAmount = serviceCharge + (price * formikValues?.stayDuration);  // Total amount.
    const amountPayable = totalAmount * 100;                 // Total Payable amount through paystack as converted to kobo.

    const booking_reference = "hostel-ng-" + (new Date()).getTime().toString() + Math.floor(Math.random() * 1000); // Booking reference number.

    // Paystack configuration
    const config = {
        reference: booking_reference,
        email: userEmail,
        amount: amountPayable,
        publicKey: PSPUBKEY,
    };

    // Function called if payment & booking of room are successful.
    const onSuccess = (reference) => {

        // Booking details to post
        const bookingDetails = {
            "amount": totalAmount,
            "tranzID": booking_reference,
            "duration": formikValues?.stayDuration > 1 ? `${formikValues?.stayDuration} months` : `${formikValues?.stayDuration} month`,
            "checkInTime": formikValues?.checkInTime,
            "checkInDate": dateFormat(formikValues?.checkInDate, "mmm dd, yyyy"),
            "checkOutDate": dateFormat(getCheckoutDate, "mmm dd, yyyy")
        }

        // Reserve room api call 
        setIsLoading(true);
        axios.post(`${baseURL}/room/book/${roomId}`, bookingDetails, {
            headers: {
                'Authorization': `Bearer ${user}`
            }
        }).then((response) => {
            if (response) {
                // Goto success page
                navigate('/payment-successful');
            }
        }).catch((error) => {
            setIsLoading(false);
        })

    };

    // This is called on close of paystack payment modal.
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.


    }

    // Initialize paystack payment.
    const intializePayment = usePaystackPayment(config);

    const initialValues = {
        stayDuration: '',
        checkInDate: '',
        checkInTime: ''
    }

    const validationSchema = Yup.object({
        stayDuration: Yup.string().required('Required'),
        checkInDate: Yup.string().required('Required'),
        checkInTime: Yup.string().required('Required')
    })

    const onSubmit = values => {

        // Initiate Paystack payment modal.
        intializePayment(onSuccess, onClose);

    }

    const disabledDates = [];

    // Combine 2 dimensional array into 1 array.
    const flatten = arr => arr.reduce(
        (a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []
    );

    const flattenDates = flatten(disableThisDates);
    flattenDates?.map(date => disabledDates.push(new Date(date)));


    return (
        <>
            {status === 'available' ? <div className='dates-form'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    innerRef={(formikActions) => (formikActions ? setFormikValues(formikActions.values) : setFormikValues({}))}
                >
                    {formik => (
                        <Form>

                            <FormControl
                                control="select"
                                name="stayDuration"
                                type="select"
                                label="Stay duration"
                                options={stayDurationOptions}
                                disableFloatingLabel={true}
                                setFieldValue={formik.setFieldValue}
                            />

                            <FormControl
                                control="date"
                                name="checkInDate"
                                type="date"
                                label="Check-In date"
                                disableFloatingLabel={true}
                                setFieldValue={formik.setFieldValue}
                                minDate={addDays(new Date() , 1)}
                                disabledDates={disabledDates}
                                readOnly
                            />

                            <FormControl
                                control="select"
                                name="checkInTime"
                                type="select"
                                label="Check-In Time"
                                options={checkInTimeOptions}
                                disableFloatingLabel={true}
                                setFieldValue={formik.setFieldValue}
                            />

                            {userID === roomUserId && (<AlertBox type='warning' message="You are not allowed to reserve your room as a host." />)}

                            {user && userID !== roomUserId && <button type='submit' className='zbtn zbtn-primary-md zbtn-block'>Reserve Room</button>}
                            {!user && <button type='button' onClick={() => navigate('/login', { state: { previousUrl: location.pathname } })} className='zbtn zbtn-primary-md zbtn-block'>Login In</button>}

                            {/* {formik.values.checkInDate ? setCheckInValue(dateFormat(formik.values.checkInDate, "mm/dd/yyyy")) : ''}
                            {formik.values.checkInDate ? setCheckOutSetter(formik.values.checkInDate) : ''}
                            {formik.values.stayDuration ? setCheckOutValue(dateFormat(formik.values.stayDuration, "mm/dd/yyyy")) : ''}
                            {formik.values.checkInTime ? setCheckInTimeValue(formik.values.checkInTime) : ''} */}

                            <div className="payment-reviewbox">
                                <div className="payment-review">
                                    <span>Room reservation </span>
                                    <span>
                                        <img src={Naira} alt="naira" className='naira' />&nbsp;
                                        <NumericFormat value={price} displayType={'text'} thousandSeparator={true} />
                                        {formik.values.stayDuration > 1 ? ` x ${formik.values.stayDuration} months` : ""}
                                    </span>
                                </div>

                                <div className="payment-review">
                                    <span>Service fee</span>
                                    <span>
                                        <img src={Naira} alt="naira symbol" className='naira' />&nbsp;
                                        {formik.values.stayDuration ?
                                            <NumericFormat value={Math.ceil(price * formik.values.stayDuration * 0.2)} displayType={'text'} thousandSeparator={true} /> :
                                            <NumericFormat value={Math.ceil(price * 0.2)} displayType={'text'} thousandSeparator={true} />
                                        }
                                    </span>
                                </div>

                                <div className="payment-review">
                                    <span>Total</span>
                                    <span>
                                        <img src={Naira} alt="naira symbol" />&nbsp;
                                        {formik.values.stayDuration ?
                                            <NumericFormat value={(price * formik.values.stayDuration) + Math.ceil(price * formik.values.stayDuration * 0.2)} displayType={'text'} thousandSeparator={true} /> :
                                            <NumericFormat value={price + Math.ceil(price * 0.2)} displayType={'text'} thousandSeparator={true} />
                                        }
                                    </span>
                                </div>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div> : <div className='emptybox'>

                {status ? <>
                    <img src={RoomInUse} />
                    <h5 className="title">Room currently in use</h5>
                    <p className="text">
                        We've got a fast finger here, click on the button below, let's find you another room. 😉
                    </p>
                    <button onClick={() => navigate('/rooms')} className="zbtn zbtn-primary-outline-def">Explore more rooms</button>
                </> : <ContentLoader
                    speed={2}
                    width={176}
                    height={35}
                    viewBox="0 0 176 35"
                    backgroundColor="#F1F2F3"
                    foregroundColor="#F8F8F9"
                >
                    <rect x="0" y="0" rx="2" ry="2" width="176" height="35" />
                </ContentLoader>}

            </div>

            }

            {isLoading && <CircularPreloader />}

        </>

    )
}

export default MonthlyReservationForm