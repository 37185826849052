import React from 'react';
import MerchantLayout from '../../../components/layouts/merchant/MerchantLayout';
import { FiMail, FiPhoneCall } from "react-icons/fi";
import EmailAccent from "../../../assets/images/svg/email-accent.svg";
import PhoneAccent from "../../../assets/images/svg/phone-accent.svg";
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';

function MerchantHelpCenter() {
    return (

        <AnimateRoute>

            <Helmet>
                <title>Merchant Help center -  Hostel.ng.</title>
                <meta name="description" content="Find the best affordable rooms on Hostel.ng." />
                <link rel="canonical" href="/merchant/help" />
            </Helmet>

            <MerchantLayout>
                <div className='main__cover'>
                    <div className="merchant__profile">
                        <div className="header">
                            <h1 className='hey'>Hey,</h1>
                            <h3 className='title-heading'>Let's win together.</h3>
                            <p className='subtitle-heading'>Our team would love to hear from you.</p>
                        </div>

                        <div className="contact-container">

                            <div className="box">
                                <FiMail size="4.3rem" className="box__icon" />
                                <h2 className='box__title'>Email</h2>
                                <p className="box__subtitle">If you have a general inquiries, please email us.</p>
                                <a className='zbtn zbtn-primary-def action' href="mailto:hello@hostel.ng">hello@hostel.ng</a>
                                <img src={EmailAccent} alt="accent" className='email-accent' />
                            </div>

                            <div className="box">
                                <FiPhoneCall size="4.3rem" className="box__icon" />
                                <h2 className='box__title'>Phone</h2>
                                <p className="box__subtitle">Call us for any kind of requirements like booking and partnership</p>
                                <a className='zbtn zbtn-primary-def action' href='tel:+2348087838534'>+2348087838534</a>
                                <img src={PhoneAccent} alt="accent" className='phone-accent' />
                            </div>

                        </div>
                    </div>
                </div>
            </MerchantLayout>

        </AnimateRoute>
    )
}

export default MerchantHelpCenter
