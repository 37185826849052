import { useReducer } from "react";
import { createContext } from "react";

export const FilterRoomsContext = createContext();

// Initiate stae declaration
const initialState = {
    city: "",
    priceRange: "",
    facilities: [],
    payDuration: "",
};

// Filter Rooms Reducer
export const filterRooomsReducer = (state, action) => {
    switch (action.type) {
        case 'NEW_FILTER':
            return action.payload;
        case 'RESET_FILTER':
            return initialState;
        default:
            return state;
    }
}

export const FilterRoomsContextProvider = ({children}) => {
    
    const [state, dispatch] = useReducer(filterRooomsReducer, initialState);

    return(
        <FilterRoomsContext.Provider value={{city: state.city, priceRange: state.priceRange, facilities: state.facilities, payDuration: state.payDuration, dispatch}}>
            {children}
        </FilterRoomsContext.Provider>
    )
}