import axios from 'axios'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import FormControl from '../../../../components/forms/FormControl'
import MerchantOnboarding from '../../../../components/layouts/merchant/MerchantOnboarding'
import { AlertBox } from '../../../../components/molecules/alertbox/AlertBox'
import { useMerchantAuthContext } from '../../../../hooks/useMerchantAuthContext'
import AnimateRoute from '../../../../shared/components/animated-route/AnimateRoute'

function LoginMerchant() {

    const baseURL = process.env.REACT_APP_BASEURL;

    const [isLoading, setIsloading] = useState(false);
    const [error, setError] = useState(null);

    const { dispatch } = useMerchantAuthContext();

    const navigate = useNavigate();

    // Set form initial value
    const initialValues = {
        email: "",
        password: ""
    }

    // Validation for user inputs
    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email format").required("Required"),
        password: Yup.string()
            .min(6, "Password must be 6 characters or more")
            .required('Required')
    })

    // Handle merchant login
    const loginMerchant = async (formDetails) => {
        setIsloading(true);
        setError(null);
        await axios.post(`${baseURL}/merchant/auth/signin`, formDetails, {
            headers: {
                'Accept': 'application/json',
            }
        }).then((response) => {
            setIsloading(false);
            localStorage.setItem('user_merchant', JSON.stringify(response.data));

            dispatch({
                type: 'LOGIN',
                payload: response.data
            })

            // redirect user to dashboard
            navigate('/merchant/dashboard');

            // return response.data
        }).catch((error) => {
            setIsloading(false)
            if (error.response.status === 400) {
                setError("Incorrect login details. Please try again");
            }
        })
    }

    // Submit Merchant login details
    const onSubmit = values => {

        const formData = new FormData();
        formData.append("email", values.email);
        formData.append("password", values.password);

        loginMerchant(formData);
    }

    return (
        <AnimateRoute>
            <MerchantOnboarding
                heading="Welcome back!"
                subHeading="Continue to sign in"
                image="authbg-4"
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ dirty, isValid }) => (
                        <Form>

                            <FormControl
                                control="input"
                                name="email"
                                type="email"
                                label="Email Address"
                            />

                            <FormControl
                                control="input"
                                name="password"
                                type="password"
                                label="Password"
                            />

                            <Link to="/merchant/forgot-password" className='forgotlink'>Forgot password?</Link>

                            <div className="action-container pad-t3">

                                <p className='text'>New user? <Link to="/merchant/signup" className='text-primary-300'>Create account</Link> </p>

                                {!isLoading && <button type='submit' className='zbtn zbtn-primary-def' disabled={!(dirty && isValid)}>Sign In</button>}
                                {isLoading && <button type='submit' className='zbtn zbtn-primary-def' disabled={isLoading}>Loading...</button>}

                            </div>

                            {error && (<AlertBox type='danger' message={error} />)}
                        </Form>
                    )}
                </Formik>
            </MerchantOnboarding>

        </AnimateRoute>
    )
}

export default LoginMerchant