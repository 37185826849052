import { useState } from "react";
// import { useAuthContext } from "./useAuthContext";

export const useForgetPassword = () => {

    const baseURL = process.env.REACT_APP_BASEURL;

    const [isLoading, setIsLoading]= useState(null)
    const [error, setError]= useState(null)
    const [resetPasswordSent, setresetPasswordSent]= useState(false)

    const forgetPassword = ( email ) => {

        setIsLoading(true);
        setError(null);

        fetch(`${baseURL}/auth/forgot`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        }).then((response) => {

            if (!response.ok) {
                setIsLoading(false)
                throw Error("Incorrect email address")
            }

            if (response.status === 200) {
                setresetPasswordSent(true)
                return response.json()

            }
            
        }).then((data) => {
            setIsLoading(null)
            // navigate('/verify-account') 

        }).catch((error) => {
            setError(error.message)
        })  

    }

    return { forgetPassword, isLoading, error, resetPasswordSent }
    
}