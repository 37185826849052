import { Formik, Form, replace } from 'formik';
import React, { useEffect, useRef } from 'react'
import * as Yup from 'yup'
import Onboarding from '../../../components/layouts/Onboarding';
import FormControl from '../../../components/forms/FormControl'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSignIn } from '../../../hooks/useSignIn';
import { AlertBox } from '../../../components/molecules/alertbox/AlertBox';
import useAnalytics from '../../../hooks/useAnalytics';
import { useState } from 'react';
import axios from 'axios';
import Toast from '../../../components/molecules/toast/Toast';
import { useAuthContext } from '../../../hooks/useAuthContext';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';
// import loadingIcon from '../../../assets/images/gif/btnloading.gif';

export default function Login() {

  // Use Google Analytics
  useAnalytics()

  const { user } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    if (user) {
      // navigate('/');
      navigate(location?.state?.previousUrl ? location.state.previousUrl : '/');
    }
  }, [user]);


  // destructure from useSignIn object
  const { signIn, error, isLoading, info } = useSignIn();
  const baseURL = process.env.REACT_APP_BASEURL;

  const [userEmail, setUserEmail] = useState("");

  const toastRef = useRef(null);

  const resendVerifyLink = () => {

    axios.post(`${baseURL}/user/reverify`, {
      "email": userEmail
    }).then((response) => {

      // Initiate a toast here.
      if (response) {
        toastRef.current.show();
      }
      
    }).catch((error) => {
      // console.log(error.message);
    })
  }

  const initialValues = {
    email: '',
    password: ''

  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required'),
    password: Yup.string().min(6, "Minimum of 6 characters is required").required('Required')
  })

  const onSubmit = values => {
    signIn(values.email, values.password);
    setUserEmail(values.email);
  }

  return (
    <AnimateRoute>

      <Helmet>
        <title>Hostel.ng | Sign In</title>
        <meta name="description" content="Sign in to your hostel.ng account." />
        <link rel="canonical" href="/login" />
      </Helmet>

      <Onboarding
        heading="Welcome back!"
        subHeading="Sign in to continue"
        image="authbg-3"
      >

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {
            formik => (
              <Form>

                <FormControl
                  control="input"
                  name="email"
                  type="email"
                  label="Email Address"
                />

                <FormControl
                  control="input"
                  name="password"
                  type="password"
                  label="Password"
                />

                <Link to="/forgot-password" className='forgotlink'>Forgot password?</Link>

                <div className="action-container pad-t3">

                  <p className='text'>New user? <Link to="/signup" className='text-primary-300'>Create account</Link> </p>

                  {!isLoading && <button type='submit' className='zbtn zbtn-primary-def' disabled={!(formik.dirty && formik.isValid)}>Sign In</button>}
                  {isLoading && <button type='submit' className='zbtn zbtn-primary-def' disabled={isLoading}>Loading...</button>}

                </div>

                {error && (<AlertBox type='danger' message={error} />)}
                {info && (<AlertBox type='warning' message={info} />)}
                {info && <p className='resend-text pt-2'>Click <span className='text-primary-300' onClick={resendVerifyLink}>here</span> to resend your verification link</p>}

              </Form>
            )

          }
        </Formik>

        <Toast ref={toastRef} type="success" message="Verification email sent." />
      </Onboarding>
    </AnimateRoute>
  )
}
