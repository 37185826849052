import React, { useState } from 'react'
import MerchantOnboarding from '../../../../components/layouts/merchant/MerchantOnboarding'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import FormControl from '../../../../components/forms/FormControl'
import { Link, useNavigate } from 'react-router-dom'
import { FiImage } from 'react-icons/fi'
import { isError, useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { AlertBox } from '../../../../components/molecules/alertbox/AlertBox'
import AnimateRoute from '../../../../shared/components/animated-route/AnimateRoute'


function SignUpMerchant() {

    const resourceURL = process.env.REACT_APP_RESOURCE_URL;
    const baseURL = process.env.REACT_APP_BASEURL;
    const [logoPreview, setLogoPreview] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    const initialValues = {
        companyName: "",
        companyEmail: "",
        password: "",
        companyPhone: "",
        companyLogo: ""
    }

    const validationSchema = Yup.object({
        companyName: Yup.string().required("Required"),
        companyEmail: Yup.string().email("Invalid email format").required("Required"),
        password: Yup.string()
            .min(6, "Password must be 6 characters or more")
            .required('Required'),
        companyPhone: Yup.string().required('Required'),
        companyLogo: Yup.string().required("Company logo required")
    })

    // SignUp Merchant
    const { isLoading, isSuccess, isError, mutate: signUpMerchant } = useMutation({
        mutationFn: (details) => {
            return axios.post(`${baseURL}/merchant/auth/signup`, details, {
                headers: {
                    'Accept': 'application/json',
                }
            })
        },
        onSuccess: () => {
            navigate("/merchant/verify-account");
        },
        onError: (error) => {
            setErrorMessage("Email of phone number already exist.")
        }
    })

    const onSubmit = (values) => {
        const formData = new FormData();

        formData.append("company", values.companyName);
        formData.append("email", values.companyEmail);
        formData.append("password", values.password);
        formData.append("mobile", values.companyPhone);
        formData.append("logo", values.companyLogo);

        signUpMerchant(formData);
    }


    return (
        <AnimateRoute>

            <MerchantOnboarding
                heading="Let's get to know you"
                image="authbg-4"
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ dirty, isValid, setFieldValue }) => (

                        <Form>

                            <FormControl
                                control="input"
                                name="companyName"
                                type="text"
                                label="Company Name"
                            />

                            <FormControl
                                control="input"
                                name="companyEmail"
                                type="email"
                                label="Company email address"
                            />

                            <FormControl
                                control="input"
                                name="password"
                                type="password"
                                label="Password"
                            />

                            <FormControl
                                control="input"
                                name="companyPhone"
                                type="tel"
                                label="Company phone number"
                            />

                            <div className="company__logobox">
                                <div className="logo">
                                    {logoPreview ? <img src={logoPreview} alt="preview" /> : <FiImage />}
                                </div>
                                <div className="uploadbox">
                                    <input type="file"
                                        name="companyLogo"
                                        className='uploadinput'
                                        id='companyLogo'
                                        accept='image/png, image/jpeg, image/jpg'
                                        onChange={(e) => {
                                            let image = e.target.files[0];
                                            let imagePreview = URL.createObjectURL(image);
                                            setLogoPreview(imagePreview);
                                            setFieldValue('companyLogo', image);
                                        }}
                                    />
                                    <label htmlFor="companyLogo" className='uploadlabel'>Upload company logo</label>
                                    <span className='instruction'>Maximum size allowed is 2MB</span>
                                </div>
                            </div>

                            <p className='terms'>
                                By signing up, you agree to our
                                <Link to="/terms" target="_blank" className='text-primary-300'> Terms of Service </Link> and
                                <Link to="/privacy-policy" target="_blank" className="text-primary-300"> Privacy Policy</Link>
                            </p>

                            <div className="action-container pad-t3">

                                <p className='text'>Got an account? <Link to="/merchant/" className='text-primary-300'>Sign In</Link> </p>

                                {!isLoading && <button type='submit' className='zbtn zbtn-primary-def' disabled={!(dirty && isValid)}>Create Free Account</button>}
                                {isLoading && <button type='submit' className='zbtn zbtn-primary-def' disabled={isLoading}>Creating your account ...</button>}

                            </div>

                            {isError && (<AlertBox type='danger' message={errorMessage} />)}

                        </Form>
                    )}
                </Formik>
            </MerchantOnboarding>
            
        </AnimateRoute>
    )
}

export default SignUpMerchant