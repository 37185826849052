import React from 'react'
import CheckBoxField from './CheckBoxField'
import DatePickerField from './DatePickerField'
import DateRangeField from './DateRangeField'
import InputField from './InputField'
import RadioButtonField from './RadioButtonField'
import SelectWithCheckbox from './SelectWithCheckbox'
// import RadioButtonFielder from './RadioButtonFielder'
import SelectField from './SelectField'
// import SelectFielder from './SelectFielder'
import TextAreaField from './TextAreaField'
import InputFieldOnlyNumbers from './InputFieldOnlyNumbers'

export default function FormControl(props) {

    const {control, ...rest} = props

    switch (control) {
        case 'input':
            return <InputField { ...rest } />
        case 'inputNumbers':
            return <InputFieldOnlyNumbers { ...rest } />
        case 'textarea':
            return <TextAreaField {...rest} />
        case 'select':
            return <SelectField {...rest}/>
        case 'radio':
            return <RadioButtonField {...rest} />
        case 'checkbox':
            return <CheckBoxField {...rest} />
        case 'selectCheckbox':
            return <SelectWithCheckbox {...rest} />
        case 'date':
            return <DatePickerField {...rest} />
        case 'daterange':
            return <DateRangeField {...rest} />
        default: return null
    }
}
