import React, { useState } from 'react'
import MerchantHeader from './MerchantHeader'
import MerchantSidebar from './MerchantSidebar'

function MerchantLayout({ children }) {
    const [toggleMenu, setToggleMenu] = useState(false);

    return (
        <div className='merchant__layout'>
            <MerchantSidebar toggleMenu={toggleMenu} setToggleMenu={setToggleMenu}></MerchantSidebar>
            <MerchantHeader toggleMenu={toggleMenu} setToggleMenu={setToggleMenu}></MerchantHeader>
            <div className="main">
                {children}
            </div>
        </div>
    )
}

export default MerchantLayout