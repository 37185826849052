
export const formatToNaira = (price) => {
    let naira = Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
    })

    return naira.format(price);
} 

export const formatToDollar = (price) => {
    let dollar = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    return dollar.format(price)
} 