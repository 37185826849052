import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "./useAuthContext"

export const useSignIn = () => {

    const baseURL = process.env.REACT_APP_BASEURL;
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || "";

    const [isLoading, setIsLoading]= useState(null)
    const [error, setError]= useState(null)
    const [info, setInfo]= useState(null)
    const { dispatch } = useAuthContext()

    const signIn =  ( email, password ) => {

        setIsLoading(true);
        setError(null);
        setInfo(null);

        fetch(`${baseURL}/auth/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        }).then((response) => {

            if (response.status === 400) {
                setIsLoading(false)
                throw Error("Incorrect login credentials")
            }

            if (response.status === 401) {
                setIsLoading(false)
                setInfo("Kindly verify your account to proceed.");
                throw Error()
            }

            if (response.status === 202) {
                return response.json()
            }

            
        }).then((data) => {
            // Save user to the local storage
            localStorage.setItem('user', JSON.stringify(data.token)) 
            

            // Update the Auth Context
            dispatch({
                type: 'LOGIN',
                payload: data.token
            })

            setIsLoading(null)
            // navigate(from, { replace: true });
            navigate(location?.state?.previousUrl ? location.state.previousUrl : '/');

        }).catch((error) => {
            setIsLoading(false);
            setError(error.message)
        })  

    }

    return { signIn, isLoading, error, info }
}