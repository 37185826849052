import React, { useEffect, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import clsx from 'clsx';

export default function TheCustomSort({ options, label, setSelectedValue }) {

    // Control dropdown visibility
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    // Get index of user seleted item
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);


    return (
        <div className='custom-sort__box'>
            <div className="custom-sort__field" onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
                <span>{selectedItemIndex !== null ? options[selectedItemIndex].label : label}</span>
            </div>

            <FiChevronDown
                className={clsx('custom-sort__field__dropdown', isDropdownVisible === true && 'opened')}
                size="2rem"
                onClick={() => setIsDropdownVisible(!isDropdownVisible)}
            />


            {isDropdownVisible &&

                <div className="custom-sort__optionbox">
                    <div className="custom-sort__optionbox-roller">

                        {
                            options.map((option, index) => {
                                return (
                                    <span
                                        key={option.value}
                                        className={clsx('custom-sort__optionbox--value', index && 'active')}
                                        onClick={
                                            () => {
                                                setSelectedItemIndex(index);
                                                setSelectedValue(option.value)
                                                setIsDropdownVisible(false);
                                            }
                                        }
                                    >
                                        {option.label}
                                    </span>
                                )
                            })
                        }
                    </div>
                </div>

            }
        </div>
    )
}
