import React, { useState } from 'react'
import EarningsImage from ".././../.././../assets/images/svg/bonus-earned.svg"
import { NumericFormat } from 'react-number-format'
import Naira from '../../../../assets/images/svg/naira-icon.svg';
import Giftbox from '../../../../assets/images/svg/gift-box.svg';
import UserAvarter from '../../../../assets/images/svg/user-avarter.svg';
import BookedIcon from '../../../../assets/images/svg/booked-icon.svg';
import Modal from '../../../../components/molecules/modal/Modal';
import BlueGiftBox from '../../../../assets/images/svg/giftbox.svg'
import NoReferrals from '../../../../assets/images/svg/no-referrals.svg'
import EarnStep from '../../../../assets/images/img/steps.png';
import { useUserDetailsContext } from '../../../../hooks/useUserDetailsContext';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import CircularPreloader from '../../../../shared/components/preloader/CircularPreloader';
import dateFormat from 'dateformat';

function Referrals() {

    const baseURL = process.env.REACT_APP_BASEURL;
    const resourceURL = process.env.REACT_APP_RESOURCE_URL;
    const homeURL = process.env.REACT_APP_HOMEURL;

    const [referralModal, setReferralModal] = useState(false);
    const [copied, setCopied] = useState(false);

    const { user_details } = useUserDetailsContext();
    const { user } = useAuthContext();


    // handle Referral link
    const referralLink = `${homeURL}signup?hosref=${user_details?.referral_code}`;
    // const referralLink = `http://localhost:3020/?hosref=${user_details?.referral_code}`;
    const linkCopied = () => {

        setCopied(true);
        navigator.clipboard.writeText(referralLink);
        setInterval(() => {
            setCopied(false);
        }, 2000);
    }


    // Get live user data and extract earnings data directly 
    const { data: referralData, isLoading } = useQuery(['GetReferralDetails'], async () => {
        return await axios.get(`${baseURL}/user/referralHistory`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${user}`
            }
        }).then((res) => res.data)
    })

    const showMe = false;

    return (
        <div className='referrals'>
            <h1 className='heading'>Earn more by Refer</h1>

            <div className="referrals__boxcon">

                {/* Total Bonus earnings */}

                {showMe && <div className="referrals__box">
                    <div className="titlebox">
                        <img src={EarningsImage} alt="EarningsImage" />
                        <p className="text">Bonus Earned</p>
                    </div>
                    <p className="value">
                        <img src={Naira} alt="naira" className='naira' />
                        <NumericFormat value={referralData?.referralBalance} displayType={'text'} thousandSeparator={true} />
                    </p>
                </div>}

                {/* GiftBox */}
                <div className="referrals__giftbox">
                    <div className="actionbox">
                        <div className="words">
                            <p className='title'>Earn by Refer</p>
                            <span className='subtitle'>Earn <NumericFormat value={500} displayType={'text'} prefix='₦' className='amount' /> for each Refer</span>
                        </div>
                        <button className='zbtn zbtn-primary-def' type='button' onClick={() => setReferralModal(true)}>Refer friends</button>
                    </div>
                    <img src={Giftbox} alt="giftbox" className='image' />
                </div>
            </div>

            {/* Info box for user earnings */}
            <div className="zalert zalert-info referrals__infobox">
                <span>
                    <span className='color'>Please note:</span> Your referral bonus would be available for
                    withdrawal on your <span className='color'>earnings</span> tab once the person
                    you referred checks into their reserved room.
                </span>
            </div>


            {/* Referrals listing section */}

            <div className="referrals__listcon">
                <p className='header-text'>Successful Referrals <span className='counter'>
                    {referralData?.referredUsers?.length}</span>
                </p>

                <div className="listbox">

                    {referralData?.referredUsers?.length > 0 && !isLoading ? (
                        <>
                            {referralData?.referredUsers.map((refree) => (
                                <div className="listrow" key={refree?.id}>
                                    <div className="namebox">
                                        {refree?.avatar === "default.png" ? <img src={UserAvarter} alt='default' className='profile-image' /> :
                                            <img src={`${resourceURL}${refree?.avatar}`} alt="profile" className='profile-image' />}
                                        <span className='names'>{`${refree?.fName} ${refree?.lName}`}</span>
                                    </div>
                                    {refree?.bookings_count > 0 ? (<div className="status green">
                                        <img src={BookedIcon} alt="" />
                                        <span>Booked a room</span>
                                    </div>) : (<div className="status blue">
                                        <span>Yet to book a room</span>
                                    </div>)}

                                    <div className="price">₦ 500</div>
                                    <div className="date">{`Joined ${dateFormat(refree?.created_at, "mmm dS, yyyy")}`}</div>
                                </div>
                            ))}

                        </>) : (<div className="emptystate">
                            <img src={NoReferrals} alt="no-referrals icon" />
                            <div className="wordbox">
                                <h4 className='title'>No referrals yet</h4>
                                <p className='subtitle'>Your referral list will show up here</p>
                            </div>
                        </div>)}


                    {isLoading && <div className="emptystate">
                        <CircularPreloader nocover />
                    </div>}
                </div>
            </div>


            {/* Referral modal */}
            {referralModal && <Modal showModal={referralModal} setShowModal={setReferralModal} wrapClose>
                <div className="referrals__modal">
                    <div className="header">
                        <img src={BlueGiftBox} alt="giftbox" />
                        <p className='title'>Invite your Friends</p>
                    </div>

                    <img src={EarnStep} alt="" className='earnstep' />

                    <div className="info">For every friend you refer you will <span>receive N500!</span></div>

                    <div className="linkbox">
                        <div className="code">{`hostel.ng/${user_details?.referral_code}`}</div>
                        <div className="copy" onClick={() => linkCopied()}>
                            <span>{copied ? "Copied" : "Copy link"}</span>
                        </div>
                    </div>
                </div>

            </Modal>}
        </div>
    )
}

export default Referrals
