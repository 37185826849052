import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import clsx from 'clsx';

function InputFieldOnlyNumbers({ disabled, label, name, type, disableFloatingLabel, value, ...rest }) {

    // Accept only numbers
    const handleKeyDown = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const isValid = /^\d+$/.test(keyValue);
        if (!isValid) {
            event.preventDefault();
        }
    };

    return (
        <div className="input__box">
            <Field name={name} {...rest}>
                {
                    ({ field, meta }) => {
                        return (
                            <div>
                                <input
                                    type={type}
                                    id={name}
                                    placeholder={label}
                                    className={clsx('input__field', meta.touched && meta.error && 'error')}
                                    autoComplete="off"
                                    onKeyPress={(e) => handleKeyDown(e)}
                                    {...field}
                                    {...rest}
                                    disabled={disabled}
                                />
                                {!disableFloatingLabel && <label htmlFor={name} className={clsx('input__label', meta.touched && meta.error && 'error')}>{label}</label>}
                            </div>
                        )
                    }
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default InputFieldOnlyNumbers