import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useMerchantAuthContext } from '../../../../hooks/useMerchantAuthContext';
import * as Yup from 'yup'
import { useMerchantLogOut } from '../../../../hooks/useMerchantLogOut';
import axios from 'axios';
import { Form, Formik } from 'formik';
import FormControl from '../../../../components/forms/FormControl'
import { AlertBox } from '../../../../components/molecules/alertbox/AlertBox';
import Toast from '../../../../components/molecules/toast/Toast';

function MerchantSecurityForm() {
    const baseURL = process.env.REACT_APP_BASEURL;
    const { user_merchant } = useMerchantAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const toastRef = useRef(null);
    const navigate = useNavigate();
    const { logOut } = useMerchantLogOut();

    const initialValues = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    }

    const validationSchema = Yup.object({
        oldPassword: Yup.string().min(6, "Password must be at least 6 characters.").required('Required'),
        newPassword: Yup.string().min(6, "Password must be at least 6 characters.").required('Required'),
        confirmPassword: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            "New password and confirm password mismatch"
        ).required('Required'),
    })

    const onSubmit = (values, onSubmitProps) => {

        // User data to post
        const changePassword = {
            "oldPassword": values.oldPassword,
            "password": values.newPassword,
            "password_confirmation": values.confirmPassword
        }

        // Update user profile details
        setIsLoading(true);
        setError(null);
        axios.post(`${baseURL}/merchant/password`, changePassword, {
            headers: {
                'Authorization': `Bearer ${user_merchant}`
            }
        }).then((response) => {
            setIsLoading(false)
            onSubmitProps.resetForm();

            // Initiate a toast here. 
            if (response) {
                toastRef.current.show();
                logOut();
                navigate('/merchant/');
            }

        }).catch((error) => {
            if (error.response.status === 400) {
                setError("Incorrect password. Please try again.");
            }

            setIsLoading(false)
        })
    }

    return (
        <div className='profile__form'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {formik => (
                    <Form>
                        {/* Old password row */}
                        <div className="row profile__space">
                            <div className="col-md-4 mobile__space">
                                <p className='heading'>Old password</p>
                                <span className='subheading'>Confirm your old password</span>
                            </div>
                            <div className="col-md-4">
                                <FormControl
                                    control="input"
                                    name="oldPassword"
                                    type="password"
                                    label="Enter old password"
                                />
                            </div>
                        </div>


                        {/* New password row */}

                        <div className="row profile__space">
                            <div className="col-md-4 col-sm-12 mobile__space">
                                <p className='heading'>New password</p>
                                <span className='subheading'>Set a new password</span>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <FormControl
                                    control="input"
                                    name="newPassword"
                                    type="password"
                                    label="Enter new password"
                                />
                            </div>
                        </div>

                        {/* Confirm New password */}

                        <div className="row profile__space">
                            <div className="col-md-4 col-sm-12 mobile__space">
                                <p className='heading'>Confirm password</p>
                                <span className='subheading'>Confirm your new password</span>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <FormControl
                                    control="input"
                                    name="confirmPassword"
                                    type="password"
                                    label="Confirm new password"
                                />
                            </div>
                        </div>

                        <div className="row profile__space">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                {!isLoading && <button type='submit'
                                    className='zbtn zbtn-primary-def'
                                    disabled={!(formik.dirty && formik.isValid)}
                                >
                                    Save changes
                                </button>}

                                {isLoading && <button type='submit'
                                    className='zbtn zbtn-primary-def'
                                    disabled={isLoading}
                                >
                                    Save changes
                                </button>}
                            </div>
                        </div>

                        <div className="row profile__space">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                {error && (<AlertBox type='danger' message={error} />)}
                            </div>
                        </div>


                    </Form>
                )}

            </Formik>

            <Toast ref={toastRef} type="success" message="Password changed successfully." />
        </div>
    )
}

export default MerchantSecurityForm
