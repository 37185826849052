import React from 'react'
import { useNavigate } from 'react-router-dom';
import Naira from '../../../assets/images/svg/naira-icon.svg';
import { FiMapPin, FiCalendar } from 'react-icons/fi';
import { NumericFormat } from 'react-number-format';

function ReservedRoom({roomId, roomImage, roomTitle, status, roomLocation, roomPrice, setIsModalActive, dateIn, dateOut, payDuration}) {

    const navigate = useNavigate();
    const resourceURL = process.env.REACT_APP_RESOURCE_URL;
    
    return (

        <div className="listing-box" onClick={() => navigate(`/room-details/${roomId}`)}>
            <div className="listing-image">
                <img src={`${resourceURL}${roomImage}`} alt="" />
                <div className="overlay"></div>
                {status && <div className={`status ${status === 'available' ? 'available': 'inuse'}`}>
                    {status === 'unavailable' ? 'In use' : status }
                </div>}
            </div>

            <div className="listing-content">
                <h1 className="listing-content__title">{roomTitle}</h1>

                <p className="listing-content__location">
                    <FiCalendar className="icon" size="1.5rem"/> 
                    <span>{`${dateIn} - ${dateOut}`}</span>
                </p>

                <div className="listing-content__priceaction">

                    <p className="price"> 
                        <img src={Naira} alt="Naira icon" /> 
                        <span className="figure"> <NumericFormat value={roomPrice} displayType={'text'} thousandSeparator={true} /> </span> 
                        <span className="duration">/{payDuration === "Daily" ? "day" : "month"}</span>
                    </p>

                    

                    <div onClick={(e) => e.stopPropagation()}>
                        {status === "In Use" ? 
                            <button className="zbtn zbtn-extend" onClick={() => setIsModalActive(true)}>Extend your stay</button> :
                            <button className="zbtn zbtn-primary-sm action" onClick={() => navigate(`/room-details/${roomId}`)}>View</button> 
                        }
                    </div>

                </div>
            </div>
        </div>

    )
}

export default ReservedRoom