import React from 'react'
import MainLayout from '../../../components/layouts/users/MainLayout';
import successImage from '../../../assets/images/gif/happy-swinging-boy.gif';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../../hooks/useAnalytics';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';

export default function RoomUploadSuccess() {

    // Use Google Analytics
    useAnalytics()

    const navigate = useNavigate();
    return (
        <AnimateRoute>
            <div className='room-upload'>
                <MainLayout>
                    <div className="container">
                        <div className="room-upload__content">
                            <div className="successbox">
                                <img src={successImage} alt="room upload successful" className='image' />
                                <h4 className='heading'>You are doing well!</h4>
                                <p className='subheading'>
                                    Your host room application has been uploaded successfully,
                                    our team will review and get back to you shortly.
                                </p>

                                {/* <button type='button' className='zbtn zbtn-primary-def' onClick={() => navigate('/rented-rooms')}>View Listings</button> */}
                                <button type='button' className='zbtn zbtn-primary-def' onClick={() => navigate('/my-rooms')}>View my room</button>
                            </div>
                        </div>
                    </div>
                </MainLayout>
            </div>
        </AnimateRoute>
    )
}
