import React, { useEffect, useReducer, useState } from 'react'
import PersonalDetailsForm from './PersonalDetailsForm';
import { FiUser } from 'react-icons/fi';
import axios from 'axios';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useRef } from 'react';
import Toast from '../../../../components/molecules/toast/Toast';
import ContentLoader from 'react-content-loader';
import { useUserDetailsContext } from '../../../../hooks/useUserDetailsContext';

export default function PersonalDetails({updateMe, setUpdateMe}) {

  const baseURL = process.env.REACT_APP_BASEURL;
  const resourceURL = process.env.REACT_APP_RESOURCE_URL;
  const { user } = useAuthContext();
  const {user_details} = useUserDetailsContext();

  const toastRef = useRef(null);
  // const dispatch = useDispatch();

  const [userAvatar, setUserAvatar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState('');
  const [ignore, forceUpdate] = useReducer(x => x + 1, 0);


  // useEffect(() => {
  //   getUserInfo();
  // }, [baseURL, refresh])

  // // Get user details
  // const getUserInfo = () => {
  //   setIsLoading(true);

  //   axios.get(`${baseURL}/user/me`, {
  //     headers: {
  //       'Authorization': `Bearer ${user}`
  //     }
  //   }).then((response) => {
  //     setUserAvatar(response.data.avatar)
  //     setIsLoading(false);
  //     setUpdateMe(updateMe + 1);

  //   }).catch((error) => {
  //     setIsLoading(false);
  //   })
  // }

  const handleChangeAvatar = (e) => {
    const image = e.target.files[0];

    const formData = new FormData();

    formData.append("avatar", image);

    // updateUserAvater(user, baseURL, formData, toastRef, dispatch);

    const uploadImage = async () => {
      await axios.post(`${baseURL}/user/avatar`, formData, {
        headers: {
          'Authorization': `Bearer ${user}`
        }
      }).then((res) => {
        // Initiate a toast 
        if (res) {
          toastRef.current.show();
          forceUpdate();
          setUpdateMe(updateMe + 1);
        }
        setRefresh({});


      }).catch((error) => {

      })
    }

    uploadImage();
  }

  return (
    <div className='personal-details'>

      {/* Profile image section */}
      <div className="avatarbox">
        {user_details ? <div className="profile-picture">
          {user_details?.avatar === "default.png" ?
            (<FiUser className="icon" size="4rem" />) :
            (<img src={`${resourceURL}/${user_details?.avatar}`} className='image' alt='' />)
          }
        </div> :
          <div className="profile-picture">
            <ContentLoader
              speed={2}
              width={100}
              height={100}
              viewBox="0 0 100 100"
              backgroundColor="#F1F2F3"
              foregroundColor="#F8F8F9"
              style={{ width: "100%" }}
            >
              <circle cx="5" cy="6" r="100" />
            </ContentLoader>
          </div>

        }

        <div className="profile-content">
          <input
            type="file"
            className='profile-input'
            name='profile-image'
            id='profile-image'
            accept='image/png, image/jpeg, image/jpg'
            onChange={handleChangeAvatar}
          />
          {user_details?.avatar === "default.png" ?
            (<label htmlFor="profile-image" className='upload-badge'>Add profile picture</label>) :
            (<label htmlFor="profile-image" className='upload-badge'>Change display</label>)
          }
          <span className='text'>Maximum size allowed is 2MB of PNG, JPEG ,JPG.</span>
        </div>
      </div>

      {/* Personal details section */}
      <PersonalDetailsForm />

      <Toast ref={toastRef} type="success" message="Profile image updated." />
    </div>
  )
}
