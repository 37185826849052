import { FiInfo, FiAlertOctagon } from 'react-icons/fi'


export const AlertBox = ({type, message}) => {

    switch (type) {
        case 'success':
            return (
                <div className="alert alert-success">
                    <div className='iconbox'>
                        <FiInfo className='icon' size="1.5rem" />
                    </div>
                    {message}
                </div>
            )

        case 'warning':
            return (
                <div className="alert alert-warning">
                    <div className='iconbox'>
                        <FiInfo className='icon' size="1.5rem" />
                    </div>
                    {message}
                </div>
            )
    
        case 'info':
            return (
                <div className="alert alert-info">
                    <div className='iconbox'>
                        <FiInfo className='icon' size="1.5rem" />
                    </div>
                    {message}
                </div>
            )
    
        case 'danger':
            return (
                <div className="alert alert-danger">
                    <div className='iconbox'>
                        <FiAlertOctagon className='icon' size="1.5rem" />
                    </div>
                    {message}
                </div>
            )
    
        default:
            return null;
    }
}