import React, { useEffect, useRef, useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import clsx from 'clsx'

import dropIcon from '../../assets/images/svg/dropdown-icon.svg'
import { FiSearch } from 'react-icons/fi'

export default function SelectField({ label, name, options, type, disableFloatingLabel, updateValue, setFieldValue, search, passValue, ...rest }) {


  // Controls dropdown visibility
  const [isActive, setIsActive] = useState(false);
  const [mySearch, setMySearch] = useState('');

  const dropDownRef = useRef(null);

  // Save the list items in a state

  const [value, setValue] = useState("");
  const [theLabel, setTheLabel] = useState("");

  // Get index of user seleted item
  // const [selectedItemIndex, setSelectedItemIndex] = useState(null);


  const sortSelectOption = (event) => {
    setFieldValue(name, event.target.dataset.value)
    setValue(event.target.dataset.value);
    setTheLabel(event.target.innerText);
    setIsActive(false);

    setMySearch('');
  }

  // Close dropdown menu on click outside
  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [])


  const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className='select__box'>
      <Field name={name} {...rest}>
        {
          ({ field, meta }) => {
            return (
              <div ref={dropDownRef}>
                <input
                  className={clsx('select__field', meta.touched && meta.error && 'error')}
                  id={name}
                  tabIndex="0"
                  placeholder={label}
                  value={passValue !== "" ? theLabel : label}
                  data-value={value}
                  onClick={() => setIsActive(!isActive)}
                  readOnly
                />
                {!disableFloatingLabel && <label htmlFor={name} className={clsx('select__label', meta.touched && meta.error && 'error')}>{label}</label>}

                {type === 'select' &&
                  (<span className={clsx('select__field__dropdown', isActive === true && 'opened')}>
                    <img src={dropIcon} alt="Dropdown Icon" onClick={() => setIsActive(!isActive)} />
                  </span>)
                }

                {isActive &&
                  <div className="select__optionbox">
                    {search &&
                      <div className="custom-select__search-optioncon">
                        <input
                          name='searhOptions'
                          placeholder='Search'
                          className='custom-select__search-options'
                          onChange={(e) => setMySearch(e.target.value)}
                        />
                        <FiSearch className="icon" size="1.4rem" />
                      </div>
                    }
                    <div className="select__optionbox-roller">
                      {
                        options.filter((item) => {
                          return mySearch.toLowerCase() === ''
                            ? item :
                            item.label.toLowerCase().includes(mySearch.toLowerCase());
                        }).map((option, index) => {
                          return (
                            <span
                              key={index}
                              className='select__optionbox--value'
                              tabIndex="-1"
                              data-value={option.value}
                              onClick={sortSelectOption}
                            >
                              {option.label}
                            </span>
                          )
                        })
                      }
                    </div>
                  </div>
                }
              </div>
            )
          }
        }
      </Field>

      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}
