import React from 'react'
import { Helmet } from 'react-helmet-async';
import EmailIcon from '../../../../assets/images/svg/email-success.svg';
import MerchantOnboarding from '../../../../components/layouts/merchant/MerchantOnboarding'
import AnimateRoute from '../../../../shared/components/animated-route/AnimateRoute'

function MerchantVerifyAccount() {
  return (
    <AnimateRoute>

      <Helmet>
        <title>Hostel.ng | Verify Merchant Account</title>
        <meta name="description" content="Verify your hostel.ng account." />
        <link rel="canonical" href="/merchant/verify-account" />
      </Helmet>

      <MerchantOnboarding
        heading="Verify your account"
        subHeading=""
        image="authbg-4"
      >
        <div className='statusbox'>
          <img src={EmailIcon} alt="" className='email-sent' />

          {/* Verification link sent */}
          <div>
            <p className='statusbox__userinfo'>
              {/* Hi <span className='username'>Folaranmi</span>,  */}
              A verification link has just been sent to your email address.
              {/* <span className='email'> (jamessoon@yahoo.com)</span>.  */}
              &nbsp; <br /> Click the link to verify your account.
            </p>

            {/* <p className='resend'>Didn't get the mail? <span className="action">Click to resend</span></p> */}
          </div>

        </div>
      </MerchantOnboarding>

    </AnimateRoute>

  )
}

export default MerchantVerifyAccount