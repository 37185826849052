import { createContext, useEffect, useReducer } from "react";


export const MerchantDetailsContext = createContext();

const initialState = {
    merchant_details: {},
}

// Merchant Details reducer that determines the action to be taken
export const merchantDetailsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_MERCHANT_DETAILS':
            return { merchant_details: action.payload };

        case 'UNSET_MERCHANT_DETAILS':
            return { merchant_details: {} }

        default:
            return state;
    }
}


// MerchantDetailsContext provider
export const MerchantDetailsContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(merchantDetailsReducer, initialState);

    // Check if merchant_details data exist in local storage then update global state
    useEffect(() => {
        const merchant_details = JSON.parse(localStorage.getItem('merchant_details'));

        if (merchant_details) {
            dispatch({
                type: 'SET_MERCHANT_DETAILS',
                payload: merchant_details
            })
        }

    }, []);

    return (
        <MerchantDetailsContext.Provider value={{ ...state, dispatch }}>
            {children}
        </MerchantDetailsContext.Provider>
    )
}