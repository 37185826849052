import React, { useEffect } from 'react'
import { FiCheckCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import Onboarding from '../../../components/layouts/Onboarding';
import useAnalytics from '../../../hooks/useAnalytics';
import { useAuthContext } from '../../../hooks/useAuthContext';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';

export default function ResetPasswordSuccess() {
    // Use Google Analytics
    useAnalytics()

    const navigate = useNavigate();
    const { user } = useAuthContext();

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [user]);

    return (
        <AnimateRoute>
            <Onboarding
                heading=""
                image="authbg-2"
            >
                <div className='statusbox'>

                    <div className='iconbox'>
                        <FiCheckCircle size="4rem" className="email-sent" />
                    </div>
                    <div>
                        <p className='statusbox__userinfo'>
                            Your password reset was successful. We got your back always.
                        </p>

                    </div>

                    <button type='button' className='zbtn zbtn-primary-def mt-3' onClick={() => navigate('/login')}>Sign In to continue</button>

                </div>
            </Onboarding>
        </AnimateRoute>
    )
}
