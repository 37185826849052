import React from 'react'
import MerchantSecurityForm from './MerchantSecurityForm'

function MerchantSecurity() {
    return (
        <div className='security'>

            <div className='security__title'>
                <h2 className=''>Update your password</h2>
            </div>

            {/* Security tab */}
            <MerchantSecurityForm />
        </div>
    )
}

export default MerchantSecurity
