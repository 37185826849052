import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import axios from 'axios';
import CurrencyInput from 'react-currency-input-field';
import clsx from 'clsx';
import { useQueryClient } from '@tanstack/react-query';
import { useMerchantAuthContext } from '../../../../hooks/useMerchantAuthContext';

function MerchantWithdrawForm({ balance, setWithdrawModal, paymentRequestRef, setChangeAmount, refetchEarningsData }) {

    const baseURL = process.env.REACT_APP_BASEURL;
    const { user_merchant } = useMerchantAuthContext();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const queryClient = useQueryClient();

    // Formik values is here
    const [formikValues, setFormikValues] = useState({});

    useEffect(() => {
       setChangeAmount(formikValues.amount);
    }, [formikValues]);

    const initialValues = {
        amount: ''
    }

    const validationSchema = Yup.object({
        amount: Yup.number("Only numbers accepted").
            min(1).
            max(balance, "You can't withdraw more than your available balance").
            positive().
            required('withdrawal amount is required')
    })

    const onSubmit = (values) => {

        const theAmount = {
            "amount": values.amount
        }

        setLoading(true);
        setError(null);
        axios.post(`${baseURL}/merchant/withdraw`, theAmount, {
            headers: {
                'Authorization': `Bearer ${user_merchant}`
            }
        }).then((response) => {

            // Initiate a toast here. 
            if (response) {
                paymentRequestRef.current.show();
                setWithdrawModal(false);
                queryClient.invalidateQueries({ queryKey: ["GetMerchantDetails"] });
                refetchEarningsData();
                setLoading(false);
            }
        }).catch((error) => {
            setError("Something went wrong");
            setLoading(false);
        })

    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                innerRef={(formikActions) => (formikActions ? setFormikValues(formikActions.values) : setFormikValues({}))}
            >
                {({ dirty, isValid, values, setFieldValue, touched, errors }) => (
                    <Form>

                        <div className="input__box">

                            <CurrencyInput
                                id="amount"
                                name="amount"
                                placeholder="Enter amount to withdraw"
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                step={500}
                                value={values.roomPrice}
                                onValueChange={(value) => {
                                    setFieldValue('amount', value)
                                }}
                                intlConfig={{ locale: 'en-NG', currency: 'NGN' }}
                                className={clsx('input__field', errors.amount && 'error')}
                            />
                            <label htmlFor="amount" className={clsx('input__label', errors.amount && 'error')}>Enter amount to withdraw</label>

                            {/* <ErrorMessage name="amount" component={TextError} /> */}
                            {errors.amount && (<div className={clsx(errors.amount && 'input__error')}>{errors.amount}</div>)}
                        </div>

                        {!loading && <button type='submit'
                            className='zbtn zbtn-primary-def'
                            disabled={!(dirty && isValid)}
                        >
                            Continue
                        </button>}

                        {loading && <button type='submit'
                            className='zbtn zbtn-primary-def'
                            disabled
                        >
                            requesting...
                        </button>}

                        {/* {error && (<AlertBox type='danger' message={error} />)} */}
                    </Form>
                )}

            </Formik>


        </>
    )
}

export default MerchantWithdrawForm;