import React from 'react'
import { useLocation } from 'react-router-dom'
import * as analytics from '../shared/services/reactGA4'

function useAnalytics() {
    const location = useLocation();

    React.useEffect(() => {
        analytics.init()
    }, [])

    React.useEffect(() => {
        const path = location.pathname + location.search
        analytics.sendPageView(path)
    }, [location])

}

export default useAnalytics