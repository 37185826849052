
// Components import
import React, { useEffect, useRef, useState } from 'react'
import MainLayout from "../../../components/layouts/users/MainLayout";
import RoomListings from "./RoomListings";
import UniversityList from "./UniversityList";
import SearchRooms from "./SearchRooms";

import Feature1 from '../../../assets/images/svg/feature1-icon.svg';
import Feature2 from '../../../assets/images/svg/feature2-icon.svg';
import Feature3 from '../../../assets/images/svg/feature3-icon.svg';
import Feature4 from '../../../assets/images/svg/feature4-icon.svg';
import ReferBox from "./ReferBox";
import useAnalytics from '../../../hooks/useAnalytics';
import AnimateRoute from '../../../shared/components/animated-route/AnimateRoute';
import { Helmet } from 'react-helmet-async';
import { BsInfoLg } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';
import { FiCheckCircle, FiX } from 'react-icons/fi';
import autoAnimate from '@formkit/auto-animate';
import GetRide from "../../../assets/images/img/smooth-ride.png"
import { useUserDetailsContext } from '../../../hooks/useUserDetailsContext';
import { useAuthContext } from '../../../hooks/useAuthContext';
import axios from 'axios';


export default function Homepage() {

  const baseURL = process.env.REACT_APP_BASEURL;
  const resourceURL = process.env.REACT_APP_RESOURCE_URL;

  const { user } = useAuthContext();

  const { user_details, dispatch } = useUserDetailsContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [userInfo, setUserInfo] = useState(null);

  // Use Google Analytics
  useAnalytics()

  const showMe = false;

  const [notifyMe, setNotifyMe] = useState(false);

  const notifyRef = useRef(null);

  useEffect(() => {
    if (notifyRef.current) {
      autoAnimate(notifyRef.current);
    }
  }, [notifyRef])

  const navigate = useNavigate();

  useEffect(() => {
    getUserInfo();
  }, [])

  // Get user details
  const getUserInfo = async () => {
    setIsLoading(true);
    setError(null);

    if (user) {
      await axios.get(`${baseURL}/user/me`, {
        headers: {
          'Authorization': `Bearer ${user}`
        }
      }).then((response) => {
        setUserInfo(response.data);

        // Save user to the local storage
        localStorage.setItem('user_details', JSON.stringify(response.data));

        // localStorage.setItem('firstname', JSON.stringify(response.data?.fName));
        // localStorage.setItem('lastname', JSON.stringify(response.data?.lName));
        localStorage.setItem('hasB', JSON.stringify(response.data?.banks));
        // localStorage.setItem('avatar', JSON.stringify(response.data?.avatar));
        // localStorage.setItem('email', JSON.stringify(response.data?.email));
        localStorage.setItem('hCount', JSON.stringify(response.data?.hostel_count));
        localStorage.setItem('userID', JSON.stringify(response.data?.id));
        // localStorage.setItem('balance', JSON.stringify(response.data?.balance));

        // dispatch user details
        dispatch({
          type: "SET_DETAILS",
          payload: response.data
        })

        setIsLoading(false);
      }).catch((error) => {
        setIsLoading(false);
        // setError(error.response.data);
      })
    }
  }

  return (
    <AnimateRoute>
      <Helmet>
        <title>Affordable Hostel rooms for rent - Hostel.ng.</title>
        <meta name="description" content="Find the best hostel rooms for your travel needs." />
        <link rel="canonical" href="/" />
      </Helmet>

      <div className="homepage">
        <MainLayout>

          <div className="container">

            {/* Hero banner */}
            <div className="hero">
              <div className="hero__content">
                <h1 className="hero__title">
                  <p>Let's find you the</p>
                  <p className="hero__title--big">Perfect <span className="hero__title--underline">Room.</span></p>
                </h1>

                <p className="hero__subtitle">Affordable hostel accommodation for your short stay needs.</p>
              </div>
            </div>

            {/* Search Bar panel */}

            {!showMe && <SearchRooms homeUseage={true} />}

            {/* Explore our rooms */}

            {/* {showMe && } */}

            {/* Room lisitng */}

            {!showMe && <RoomListings />}

            {/* Popular Universities with Rooms */}

            {showMe && <div className="header__titlebox mar-t83">

              <div className="header__titlebox--left">
                <h1 className="title">Popular Universities with Rooms</h1>
                <p className="subtitle">We've got comfortable rooms within these institutions.</p>
              </div>
              <div className="header__titlebox--right"></div>
            </div>}

            {showMe && <UniversityList />}

            {/* Features section */}
            <div className="features-header">
              <h4 className="title">Why Choose Hostel.ng?</h4>
              <span className="subtitle">
                It's simple, we are obsessed about providing you with the best possible experience.
              </span>
            </div>

            <div className="features-box">

              <div className="imagebox"></div>

              <div className="contentbox green">
                <img src={Feature1} alt="Affordable and sustainable rooms." />
                <span className='role'>Host</span>
                <h5 className="title">Monetize your room.</h5>
                <p className="subtitle">
                  Get paid every time you lease out your room to
                  guests, optimizing your room space, especially when not in use.
                </p>
              </div>

              <div className="contentbox blue">
                <img src={Feature2} alt="Vibrant community." />
                <span className='role'>Host</span>
                <h5 className="title">Damage insurance coverage.</h5>
                <p className="subtitle">
                  All rooms leased on Hostel.ng are insured
                  against damage from 3rd parties, so you have nothing to worry about.
                </p>
              </div>

              <div className="contentbox grey">
                <img src={Feature3} alt="Verified room listings" />
                <span className='role'>Guest</span>
                <h5 className="title">Verified room listings</h5>
                <p className="subtitle">
                  All rooms are verified before listings,
                  so be guaranteed to get what you are paying for.
                </p>
              </div>

              <div className="contentbox white">
                <img src={Feature4} alt="Safe and secured payment." />
                <span className='role'>Guest</span>
                <h5 className="title">Safe and secured payment.</h5>
                <p className="subtitle">
                  With our integrations with paystack, be sure your
                  transactions are secured and your financial details protected.
                </p>
              </div>
            </div>
            {/* Get A Bus section */}
            {!showMe && <div className="getride">
              <div className="getride__textcon">
                <h3 className="heading">
                  <span className='green'>Smooth rides</span> ahead with Hostel.ng!
                </h3>
                <p className='subtitle'>
                  You can now effortlessly get a ride to your next destination in comfort and style.
                </p>

                <div className="reasons">
                  <div className="reasons__box">
                    <FiCheckCircle className='icon' size="2.2rem" />
                    <span>Safe and convenient rides</span>
                  </div>
                  <div className="reasons__box">
                    <FiCheckCircle className='icon' size="2.2rem" />
                    <span>Budget friendly rides</span>
                  </div>
                </div>

                <div className='actions'>
                  <button onClick={() => navigate('/book-travel-bus')} className='zbtn zbtn-primary-md'>Learn more</button>
                </div>
              </div>

              <div className="getride__imagecon">
                <img src={GetRide} alt="get ride" className='image' />
              </div>
            </div>}

            {/* Refer a friend section */}
            <ReferBox />


          </div>

        </MainLayout>

        {/* Terms and condition s updated notification */}
        {notifyMe && <div className="notifier" ref={notifyRef}>

          <div className="notifier__iconbox">
            <div className="iconcon">
              <BsInfoLg className='icon' />
            </div>
          </div>

          <div className="notifier__textbox">
            <div className="content">
              We have just updated our terms of use kindly check it out. <Link to="/terms" className='link'>Terms of Use</Link>
            </div>

            <div className="close" onClick={() => setNotifyMe(false)}>
              <FiX size="1.5rem" />
            </div>
          </div>

        </div>}


      </div>
    </AnimateRoute>
  )
}
